

.introductory {
    padding: vw-calc(83) vw-calc(73) vw-calc(100) vw-calc(67);
    height: 100%;

    @at-root #{&}__headline {
        color: $color-shade-darker;
        font-family: $font-primary;
        font-size: rem-calc(40);
        font-weight: 700;
        line-height: rem-calc(50);

        h1 {
            font-weight: inherit;
        }

        &:after {
            content: "";
            display: block;
            width: rem-calc(100);
            height: rem-calc(2);
            background-color: $color-secondary;
            margin: rem-calc(10) 0;
        }
    }

    @at-root #{&}__paragraph {
        font-size: rem-calc(20);
    }
}