.link-collection {

    padding-left: vw-calc(40);
    border-left: rem-calc(1) solid $color-border;

@at-root #{&}__title {
    color: rgba($color-shade-darker, 0.75);
    font-family: $font-primary;
    font-size: rem-calc(20);
    font-weight: 700;
    line-height: rem-calc(30);
    padding-bottom: rem-calc(15);
}

    .strip-list {
        list-style-type: none;
        margin-left: 0;
    }
}