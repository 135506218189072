.quad-block {


    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "small1 small2"
      "small3 small4";


    @at-root #{&}__item {

    }

    @at-root #{&}--image {

    }

    @include breakpoint (small only) {
      // grid-template-columns: 100vw;
      // grid-template-rows: 25vw 25vw 25vw 25vw;
      // grid-template-areas:
      //   "small1"
      //   "small2"
      //   "small3"
      //   "small4";
    }
}

.block--overlay {
  background: $color-primary;

  & > * {
    opacity: 0.3;
  }
}


