$four-column-qualifiers: 'left','center-left','center-right','right';

.four-columns {
	@include grid-row;

	@each $i in $four-column-qualifiers {
		@at-root #{&}__#{$i} {
			@include grid-column;

			@if $i != 'right' {
				border-bottom: 1px $color-shade-darker solid;
				margin-bottom: 1rem;
			}
		}
	}

	@include breakpoint(medium) {
		@each $i in $four-column-qualifiers {
			@at-root #{&}__#{$i} {
				@include grid-column(6);

				@if ($i == 'left' or $i == 'center-right') {
					clear: left;
				}

				@if ($i != 'left' and $i != 'center-left') {
					border-top: 1px $color-shade-darker solid;
					margin-top: 1rem;
					padding-top: 1rem;
				}

				border-bottom-width: 0;
				margin-bottom: 0;
			}
		}
	}

	@include breakpoint(large) {
		@each $i in $four-column-qualifiers {
			@at-root #{&}__#{$i} {
				@include grid-column(3);

				@if ($i == 'left' or $i == 'center-right') {
					clear: none;
				}

				@if ($i != 'left' and $i != 'center-left') {
					border-top-width: 0;
					margin-top: 0;
					padding-top: 0;
				}
			}
		}
	}
}
