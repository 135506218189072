.link-list {
    margin: 1rem 0;

    @at-root #{&}__title {
        color: $color-shade-darker;
        font-family: $font-primary;
        font-size: rem-calc(30);
        font-weight: 700;
        line-height: rem-calc(40);
        text-transform: uppercase;
        padding-bottom: rem-calc(20);
    }

    @at-root #{&}__description {
        padding-bottom: rem-calc(20);
    }
}