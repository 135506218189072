.gallery-header {
    height: auto;
    min-height: rem-calc(127);
    width: 100%;
    background: rgba(0,0,0,.7);
    display: flex;
    justify-content: space-between;

    @at-root #{&}__description {
        flex: 3;
        font-family: $font-primary;
        color: $white;
        padding: rem-calc(26) rem-calc(26) rem-calc(37) rem-calc(64);
    }

    @at-root #{&}__gallery-title {
        display: block;
        font-size: rem-calc(20);
        font-weight: 700;
        padding-top: rem-calc(10);
        padding-left: rem-calc(5);

        svg {
            width: 1rem;
            height: 1rem;
            margin-right: rem-calc(8);
        }
    }

    @at-root #{&}__gallery-description {
        font-size: rem-calc(14);
        font-style: italic;
        line-height: rem-calc(24);
    }

    @at-root #{&}__controls {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: rem-calc(62);
        font-family: $font-primary;
        font-size: rem-calc(14);
        font-weight: 700;
        color: $color-secondary;

        a {
            // padding: 1rem;   Add some padding around the button so it's easier to click/press
            text-decoration: none;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: -1rem;left:0;
                width: 100%;
                height: 1rem;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -1rem;left:0;
                width: 100%;
                height: 1rem;
            }
        }
        
        svg {
            width: 1rem;
            height: 1rem;
            margin-right: rem-calc(8);
        }
    }

    @at-root #{&}__close {

    }

    @at-root #{&}__gallery-view {
            margin-right: rem-calc(41);
            @include flex-center();
    }

    @at-root #{&}__gallery-open {
            text-transform: uppercase;
            @include flex-center();
    }
}

@include breakpoint(medium down) {
    .gallery-header {
        flex-direction: column;
        justify-content: space-around;

        @at-root #{&}__description {
            padding: 1rem;
            padding-bottom: 0;
        }
        
        @at-root #{&}__controls {
            padding: 1rem;
        }
    }
}