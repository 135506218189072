.sitenav-utility {
    width: 100%;
    // align-self: flex-end;
    // margin-top: auto;
    margin-bottom: rem-calc(30);

    position: absolute;
    bottom: 0;
    left: 0;
    @include breakpoint (medium down) {
        position: static;
        margin-bottom: 0;
    }

    .link-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            text-transform: none;
            font-family: $font-primary;
            font-size: rem-calc(14);
            svg {
                fill: $color-primary;
            }
            &:hover,
            &:active {
                svg {
                    fill: #fff;
                }
            }
        }
    }
}