.alert {
    @include flex-center();
    justify-content: flex-start;
    background: $color-tertiary;
    min-height: rem-calc(74);
    padding-left: vw-calc(30);

    font-family: $font-primary;
    font-weight: 800;
    line-height: rem-calc(20);
    color: $white;

    @at-root #{&}__icon {
        margin-top: rem-calc(1);

        svg {
            display: inline-flex;
            height: 1rem;
            width: 1rem;
        }
    }

    @at-root #{&}__info {
        padding-left: rem-calc(15);
    }

    @at-root #{&}__link {
        text-decoration: underline;
        padding-left: 0.25rem;
    }


}

.alert--secondary {
    background: $color-secondary;
    color: $color-shade-darker;
}

.alert--tertiary {
    background: $color-quaternary;
    color: $white;
}