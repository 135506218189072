.article-teaser {
    // padding: rem-calc(27) 0; &:first-child { padding-top: 0;}
    padding: 0;
    color: $color-shade-darker;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // margin-top: 1rem;

    &:first-child {
        padding-top: rem-calc(30);
    }

    @at-root #{&}__container {
        flex: 1;
        // min-width: rem-calc(370);
        // padding-right: 1rem;
        // padding-left: rem-calc(20);
        padding-top: rem-calc(50);
        min-width: 20rem;
        position: relative;

        &:before {
            visibility: hidden;
        }

        // &:before {
        //     @include line_pointer($top: rem-calc(-4), $left: rem-calc(-22));
        // }
    }

    @at-root #{&}__image {
        flex: 1;
        // margin-bottom: rem-calc(30);
        // margin-right: vw-calc(40);
        // margin-left: -4rem;
        height: rem-calc(332);
        // min-width: rem-calc(500);
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        z-index: initial; 

        @at-root #{&}--triangle {
             &:after {
                content: "";
                display: block;
                border-left: rem-calc(590) solid transparent;
                border-top: rem-calc(332) solid $color-shade-light;
                position: absolute;
                bottom: rem-calc(-102);
                right: rem-calc(-31);
                @include breakpoint (small only) {
                    right: rem-calc(-25); //prevent overflow issue on mobile 
                }
                z-index: -1;
            }
        }
    }

    @at-root #{&}__secondary-image {
        position: relative;
        width: vw-calc(235);
        height: 100%;
        min-width: rem-calc(200);
        margin-top: 1rem;
        overflow: hidden;

        @at-root #{&}--no-image {
            display: none;
        }

        @at-root #{&}--standard {

        }

        @at-root #{&}--circle {
            border-radius: 50%;
            &:after {
                // content: "";
                // display: block;
                // position: absolute;
                // top: -3rem;
                // bottom: -3rem;
                // left: -3rem;
                // right: -3rem;
                // border: 3rem solid $white;
                // border-radius: 20rem;
            }
        }
    }

    $padding: vw-calc(60);

    @at-root #{&}__tag {
        color: $color-shade-darker;
        font-family: $font-secondary;
        font-size: rem-calc(18);
        font-weight: 700;
        line-height: rem-calc(20);
        text-transform: uppercase;
        padding: rem-calc(10) 0;
        // padding: 0 $padding;
    }

    @at-root #{&}__title {
        font-family: $font-secondary;
        font-size: rem-calc(25);
        font-weight: 700;
        line-height: rem-calc(35);
        position: relative;
        // padding: 0 $padding;
        padding-bottom: rem-calc(10);

        &:before {
            @include line_pointer(
                $top: 45%, 
                $left: -3vw,
                $width: 5vw
            );
        }
    }

    @at-root #{&}__description {
        font-family: $font-primary;
        font-size: rem-calc(16);
        line-height: rem-calc(26);
        // padding-top: rem-calc(20);
        // padding: 0 $padding;
    }

    @at-root #{&}__date {
        font-weight: 700;
        color: $color-shade-dark;

        &:after {
            content: " | ";
        }
    }

    @at-root #{&}__button {
        margin-top: rem-calc(20);
    }

    @include breakpoint (small only) {

    flex-direction: column;

    @at-root #{&}__container {
        &:before {
            @include line_pointer(
                $top: rem-calc(-4), 
                $left: 1vw, 
                $transform: rotate(90deg)
            );
        }
    }

    @at-root #{&}__image {
        &:before {
            @include line_pointer(
                $left: rem-calc(-22), 
                $bottom: 0,
                $transform: rotate(90deg)
            );
        }
    }

    @at-root #{&}__title {
        &:before {
            display: none;
        }
    }

    }
}

.article-teaser--secondary {
    flex-direction: row-reverse;
    overflow: hidden;

    .article-teaser__image {
        margin-left: 0;
        margin-right: 0;
    }

    .article-teaser__container {

    }

    .article-teaser__title {
        &:before {
            @include line_pointer(
                $top: 45%, 
                $right: -2rem,
                $width: 5vw
            );
        }
    }


    @include breakpoint (small only) {

    flex-direction: column;

    .article-teaser__title {
        &:before {
            visibility: hidden;
            display: none;
        }
    }

    }
}

.article-teaser--featured {

    .article-teaser__image {
        height: rem-calc(590);
    }

    .article-teaser__title {
        font-size: rem-calc(40);
        font-weight: 700;
        line-height: rem-calc(45);
    }
}

.article-teaser--has-image {

    .article-teaser__container {
        padding: rem-calc(50) 0 1rem 0;
    }

    .article-teaser {
        $left-right-padding: vw-calc(55);

        @at-root #{&}__tag {
            padding: 0 $left-right-padding;
        }

        @at-root #{&}__title {
            padding: 0 $left-right-padding;
        }
        
        @at-root #{&}__description {
            padding: 0 $left-right-padding;
        }
        @at-root #{&}__button {
            padding: 0 $left-right-padding;
        }
    }
}

.article-teaser--invert-cols {
    .article-teaser__container {
        order: 1;
        .article-teaser__title {
            &:before {
                right: -3vw;
                left: initial;
            }
        }
    }
    .article-teaser__image {
        order: 2;
    }
}

.article-teaser--airy {
    padding-top: 0;
    &:first-child {
        padding-top: 0;
    }
    .article-teaser__container {
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        & > * {
            width: 100%;
        }
        @include breakpoint(medium down) {
            padding: rem-calc(30 0);
        }
    }
    .article-teaser__image {
        @include breakpoint(large up) {
            min-height: rem-calc(400);
        }
        @include breakpoint(medium down) {
            &:before {
                display: none;
            }
        }
    }
}


.article-teaser--stack {
    flex-flow: row wrap;
    .article-teaser__container,
    .article-teaser__image {
        width: 100%;
        flex: 0 1 100%;
    }

    .article-teaser__image {
        @include breakpoint (large up) {
            margin-left: -3.125vw;
        }
    }

    .article-teaser__container {
        .article-teaser__title,
        .article-teaser__description,
        .article-teaser__button,
        .article-teaser__tag {
            padding-left: 0;
        }

        .article-teaser__title {
            &:before {
                width: rem-calc(2);
                height: 5vw;
                top: -8vw;
                left: 0;
                right: auto;
                bottom: auto;
            }
        }
    }

    &.article-teaser--triangle {
        @include breakpoint (large down) {
            .article-teaser__image {
                &:after {
                    display: none;
                }
            }
        }
    }
}