.ambient-video {
    position: relative;
    background: $color-shade-dark;
    overflow: hidden;
    video,
    .ambient-video__cover-image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        // no object-fit support...
        @media (min-aspect-ratio: 16/9) {
            height: 300%;
            top: -100%;
        }
        @media (max-aspect-ratio: 16/9) {
            width: 300%;
            left: -100%;
        }
        // with object-fit
        @supports (object-fit: cover) {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .ambient-video__cover-image {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    &__content-overlay {
        position: relative;
        z-index: 2;
        background: rgba(#000, 0.6);
        padding: rem-calc(100 100 50 100);
        @include breakpoint(large down) {
            padding: rem-calc(20 40);
        }
        @include breakpoint(350px down) {
            padding: rem-calc(20);
        }
        &:after {
            content: "";
            display: block;
            border-bottom: rem-calc(500) solid transparent;
            border-left: rem-calc(500) solid rgba($color-primary, 0.5);
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
        }
    }


    &__headline {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: rem-calc(50);
        @include breakpoint(large down) {
            flex-flow: row wrap;
        }
        .ambient-video__launch {
            order: 2;
            width: 30%;
            @include breakpoint(large down) {
                width: 100%;
                order: 1;
                text-align: center;
            }
            button {
                border: 0;
                font-family: $font-primary;
                font-weight: normal;
                padding: 0;
                cursor: pointer;
                display: block;
                text-decoration: none;
                text-align: center;
                color: #fff;
                font-size: rem-calc(30);
                line-height: rem-calc(34);
                &:hover,
                &:active,
                &:focus {
                    background: transparent;
                    outline: 0;
                }
                @include breakpoint(large down) {
                    display: inline-flex;
                    flex-flow: row wrap;
                    font-size: rem-calc(20);
                    justify-content: center;
                    align-items: center;
                    padding: rem-calc(20 0 40 0);
                }
                em,
                .watch {
                    @include breakpoint(large down) {
                        margin-left: rem-calc(4);
                    }
                }
                em {
                    font-style: normal;
                    font-weight: bold;
                    color: $color-secondary;
                }
                .watch {
                    color: $color-secondary;
                    font-family: $font-secondary;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: rem-calc(18);
                    display: block;
                    margin-top: rem-calc(10);
                    @include breakpoint(large down) {
                        display: inline-flex;
                        margin-top: 0;
                        align-items: center;
                    }
                    svg {
                        display: block;
                        fill: $color-secondary;
                        margin: rem-calc(5) auto;
                        width: rem-calc(50);
                        height: rem-calc(50);
                        @include breakpoint(large down) {
                            width: rem-calc(20);
                            height: rem-calc(20);
                            display: inline-block;
                            margin: 0 rem-calc(4) 0 0;
                        }
                    }
                }
            }
        }
        h1 {
            order: 1;
            width: 70%;
            font-size: rem-calc(40);
            line-height: rem-calc(44);
            color: #fff;
            font-weight: bold;
            padding-right: rem-calc(40);
            @include breakpoint(large down) {
                width: 100%;
                order: 2;
            }
            @include breakpoint(medium down) {
                text-align: center;
                padding-right: 0;
            }
            @include breakpoint(small only) {
                font-size: rem-calc(30);
                line-height: rem-calc(34);
            }
            em {
                font-style: italic;
                color: $color-secondary;
            }
        }
    }

    .rich-link-list {
        // margin-right: rem-calc(100);
        ul {
            li {
                a {
                    color: $color-secondary;
                    .superhead {
                        color: #fff;
                    }
                    span.heading {
                        @include fauxunderline($color-secondary);
                    }
                }
            }
        }
    }

    .grid--feature {
        margin-right: rem-calc(-100);
        @include breakpoint(large down) {
            margin-right: 0;
        }
    }
}