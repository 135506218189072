.nav-secondary {
    height: 100%;
    // padding-top: rem-calc(40);
    width: rem-calc(240);
    display: flex;
    flex-direction: column;
    @include background_map();

    @at-root #{&}__menu-toggle {
        display: none;
    }

    @at-root #{&}__back-level {
        // padding: 0 2rem;
        margin-bottom: rem-calc(26);
        a {
            position: relative;
            // padding-left: rem-calc(28);
            display: block;
            padding: rem-calc(20);
            background: rgba(#000, 0.3);
            &:link,
            &:visited {
                color: #fff;
            }
            &:hover,
            &:active {
                background: rgba(#000, 0.4);
                // color: $color-secondary;
            }
            svg {
                // margin-top: 3px;
                // position: absolute;
                // top: 3px;
                // left: 0;
                transform: none;
            }
            span {
                // @include fauxunderline(#fff);
            }
        }
    }

    @at-root #{&}__title {
        padding: 0 0 rem-calc(20) 0;
        border-bottom: #DBF0AD 2px solid;
        margin: 0 2rem rem-calc(20) 2rem;
        font-size: rem-calc(25);
        line-height: rem-calc(29);
        font-family: $font-secondary;
        font-weight: bold;
        color: #DBF0AD;
    }
    
    .link-group {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.link-group a {
		display: block;
		padding: rem-calc(14) 2rem;
		width: 100%;
    	text-align: left;
        text-decoration: none;
        font-family: $font-secondary;
        font-size: rem-calc(17);
        line-height: rem-calc(20);
        font-weight: 700;
        color: $white;

		&:hover {
			color: $color-primary;
			background: $white;
            @include fade(background, color);
		}
	}

    @include breakpoint(medium down) {

        display: none;

        height: auto;
        padding-top: 0;

        // max-height: 0;
        // overflow: hidden;
        // cursor: pointer;

        &:after {
            display: none;
        }

        &--active {
            // max-height: none;
        }

        @at-root #{&}__back-level {
            display: none;
        }

        @at-root #{&}__title {
            display: none;
        }

        @at-root #{&}__menu-toggle {
            display: block;
            color: #fff;
            cursor: pointer;
        }
    }
}

