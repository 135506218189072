.sidebar {

    .generic__title {
        color: rgba($color-shade-darker, 0.75);
        font-family: $font-primary;
        font-size: rem-calc(20);
        font-weight: 700;
        line-height: rem-calc(30);
    }

    .teaser-aside {
        padding-top: rem-calc(70);
    }

}