@import 'media/figure';
@import 'media/embedded-video';


.video-reveal {
    background: #000;
    border: 0;
    @include breakpoint(small down) {
        padding-top: rem-calc(40);
    }
    .close-button {
        background: #000;
        border-bottom-left-radius: 50%;
        right: 0.5rem;
        border: 0;
        svg {
            transform: none;
            height: 2rem;
            width: 2rem;
            // fill: #000;
        }
    }
}