// font families
.font-primary {
	font-family: $font-primary
}

.font-secondary {
	font-family: $font-secondary;
}

.font-tertiary {
	font-family: $font-tertiary;
}

.font-quaternary {
	font-family: $font-quaternary;
}
