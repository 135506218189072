.grid--profiles {
    position: relative;
    padding: rem-calc(100 0 0 100);
    @include breakpoint(xlarge down) {
        // padding: rem-calc(50 0 50 50);
    }
    @include breakpoint(1400px down) {
        padding: rem-calc(50);
    }
    @include breakpoint(medium down) {
        padding: rem-calc(20);
    }
    &:before {
        content: "";
        display: block;
        border-bottom: rem-calc(500) solid transparent;
        border-left: rem-calc(500) solid $color-shade-light;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    .square {
        // $offset-amount: 10%;
        $offset-amount: rem-calc(110);
        width: 20%;
        &.offset {
            margin-top: $offset-amount;
        }
        &:nth-child(n+6) {
            margin-top: -$offset-amount;
        }
        &:nth-child(n+5) {
            @include breakpoint(xlarge down) {
                // margin-top: -($offset-amount + ($offset-amount / 2));
                // margin-top: 0;
                // margin-top: -($offset-amount * 2);
            }
        }
        &:first-child,
        &:nth-child(5) {
            @extend .offset;
        }

        &:nth-child(5) {
            @include breakpoint(xlarge down) {
                order: 99;
                // margin-top: -10%;
                margin-top: -$offset-amount;
            }
        }

        &:nth-child(6) {
            margin-left: 20%;
            @include breakpoint(xlarge down) {
                // margin-left: 30%;
            }
        }
        &:first-child {
            @include breakpoint(xlarge down) {
                // margin-top: $offset-amount/2;
                // width: 30%;
            }
            @include breakpoint(1400px down) {
                @include unsquare;
            }
            .headline-group {
                width: 100%;
                @include breakpoint(1000px down) {
                    width: 100%;
                    // margin-bottom: rem-calc(20);
                }
                @include breakpoint(1400px down) {
                    margin-bottom: 0;
                    .subhead {
                        margin-top: 0;
                    }
                }
            }
            a {
                display: block;
                width: 100%;
                @include breakpoint(1400px down) {
                    margin-right: rem-calc(20);
                    margin-top: rem-calc(20);
                    display: inline-block;
                    width: auto;
                }
                @include breakpoint(640px down) {
                    // display: block;
                    margin-right: 0;
                    width: 100%;
                }
            }
        }
        &:not(:first-child) {
            @include breakpoint(1400px down) {
                margin: 0;
                width: 33.333%;
            }
            @include breakpoint(640px down) {
                // @include unsquare;
                width: 100%;
            }
        }
    }
}