html, body, .main {
    height: 100%;
}

.main {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    @at-root #{&}__nav {
        // width: 26.25rem;
        width: rem-calc(420);
        min-width: rem-calc(420);
        z-index: 1;
        &.top-nav-only {
            width: rem-calc(180);
            min-width: rem-calc(180);
            header.main-header {
                box-shadow: 0 0 20px rgba(#000, 0.1);
            }
        }
        &.has-title-navbar {
            width: rem-calc(610);
            min-width: rem-calc(610);
            @include breakpoint (large down) {
                width: rem-calc(420);
                min-width: rem-calc(420);
            }
            @include breakpoint (medium down) {
                width: 100%;
                min-width: 0;
            }
        }

        .main-header {
            position: fixed;
            min-height: rem-calc(480);
            z-index: 10;
        }
    }

    @at-root #{&}__page {
        width: 100%;
        // flex: 4;
        height: 100%; // for safari
        @include breakpoint (large down) {
            flex: 0 1 auto;
        }
    }
}


@include breakpoint (medium down) {
    .main {
        flex-flow: row wrap;
        height: auto;
        .main__nav {
            width: 100%;
            min-width: 0;
            .main-header {
                position: static;
                min-height: 0;
                height: auto;
            }
        }
        .main__page {
            width: 100%;
        }
    }
}

@include breakpoint (large down) {
    .main {
        .main__nav {
            // width: setme
        }
        .main__page {
            // width: setmetoo
        }
    }
}