.pullquote {
	background: $color-brand-alt;
	padding: rem-calc(51) rem-calc(48) rem-calc(62) rem-calc(90);
	text-align: center;
	color: $color-shade-darker;
	position: relative;
	min-width: 20rem;
	margin-left: rem-calc(25);

	@at-root #{&}--secondary {

	}

	@at-root #{&}__icon {
		height: rem-calc(60);
		width: rem-calc(60);
		background: $white;
		display: block;
		border-radius: 5rem;
		position: absolute;
		left: rem-calc(-30);
		top: rem-calc(21);

		svg {
			height: 100%;
    		width: rem-calc(26);
		}
	}

	@at-root #{&}__quote {
		font-family: $font-secondary;
		font-size: rem-calc(25);
		font-weight: 700;
		text-align: left;
		line-height: rem-calc(29);
	}

	@at-root #{&}__attribution {
		font-family: $font-primary;
		font-size: rem-calc(14);
		font-weight: 700;
		font-style: normal;
		text-align: left;

		&:before {
			content: "";
			width: rem-calc(100);
			height: rem-calc(4);
			background: $color-shade-darker;
			display: block;
			margin-bottom: 1rem;
		}
	}

	@at-root #{&}__first-line {
		display: block;
	}

	@at-root #{&}__second-line {
		display: block;
	}
}
