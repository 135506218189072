figure {
	img {
		width: 100%;
		height: auto;
	}

	figcaption {
		margin-top: 1em;
		color: rgba($color-shade-darkest, 0.75);
		font-family: $font-primary;
		font-style: italic;
		font-size: rem-calc(14);
		line-height: rem-calc(24);
	}
}
