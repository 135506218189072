.described-links {

	@at-root #{&}__item {
		padding: rem-calc(5) 0;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	@at-root #{&}__description {
		color: $color-shade-darker;
	}

	@at-root #{&}__link {
		svg {
			width: rem-calc(26);
			height: rem-calc(26);
		}
	}
}
