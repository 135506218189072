.cta-bar {
	background: $color-shade-darker;
	color: #fff;
	padding: rem-calc(50);
	display: flex;
	justify-content: space-between;
	@include breakpoint(large down) {
		flex-flow: row wrap;
	}
	@include breakpoint(medium down) {
		padding: rem-calc(30);
	}

	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
	a {
		&:link,
		&:visited {
			color: $color-secondary;
			font-weight: bold;
			text-decoration: none;
			display: flex;
			align-items: flex-start;
			svg {
				fill: $color-secondary;
			}
		}
		&:hover,
		&:active,
		&:focus {
			color: $color-octonary;
			svg {
				fill: $color-octonary;
			}
		}
	}

	h3 {
		font-size: rem-calc(20);
		margin-bottom: rem-calc(20);
		@include breakpoint(large down) {
			margin-bottom: rem-calc(20);
		}
	}

	h4 {
		color: $color-shade-lightest;
		font-size: rem-calc(16);
		line-height: rem-calc(26);
		display: block;
		font-style: normal;

		span {
			display: block;
		}
	}

	ul {
		list-style: none;
		margin: 0 0 rem-calc(20) 0;
		padding: 0;
		display: flex;
		// justify-content: space-between;
		li {
			margin: 0 rem-calc(40) 0 0;
			padding: 0;
		}
	}

	.col {
		width: 48%;
		@include breakpoint(large down) {
			width: 100%;
		}
	}

	.social {
		ul {
			@include breakpoint(medium down) {
				flex-flow: row wrap;
			}
			li {
				@include breakpoint(medium down) {
					margin: rem-calc(0 10 10 0);
				}
				a {
					svg {
						width: rem-calc(40);
						height: rem-calc(40);
					}
				}
			}
		}
	}

	.learn-more, .locate {
		@include breakpoint(large down) {
			margin-bottom: rem-calc(40);
		}
		ul {
			flex-direction: column;
			@include breakpoint(medium down) {
				flex-flow: column wrap;
			}
			li {
				@include breakpoint(medium down) {
					margin: rem-calc(0 0 20 0);
				}
				a {
					text-decoration: underline;
					display: inline;

					svg {
						margin-top: rem-calc(3);
						margin-right: rem-calc(10);
						width: rem-calc(20);
						height: rem-calc(20);
					}
				}
			}
		}
	}

	.locate {
		h3 {
			margin-bottom: 0;
		}
	}
}