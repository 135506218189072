

.grid-block {
	padding: rem-calc(40);
	position: relative;
	background: #fff;
	align-items: flex-end;
	align-content: flex-end;

	a {
		color: $color-quaternary;
		&.link--arrow {
			// align-items: flex-start;
			display: inline-block;
			margin-left: rem-calc(25);
			text-indent: rem-calc(-25);
			svg {
				display: inline-block;
				margin-right: 3px;
				position: relative;
				top: 3px;
			}
			span {
				@include fauxunderline($color-quaternary);
			}
		}
	}

	@at-root #{&}--dark {
		background: $color-shade-darker;
		color: #fff;
		a {
			color: $color-secondary;
			&.link--arrow {
				span {
					@include fauxunderline($color-secondary);
				}
			}
		}
		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}
	}

	@at-root #{&}--highlight {
		background: $color-secondary;
		&.grid-block--dark {
			color: $color-text;
			background: $color-octonary;
		}
		a {
			color: $color-text;
			&.link--arrow {
				span {
					@include fauxunderline($color-text);
				}
			}
		}
	}

	@at-root #{&}--overlay {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba($color-primary, 0.7);
			z-index: 1;
		}
		&.grid-block--dark {
			&:after {
				background: rgba($color-shade-darker, 0.7);
			}
		}
		&:not(.grid-block--dark) {
			.grid-block__image,
			.grid-block__video-cover {
				filter: gray;
				filter: grayscale(100%);
				filter: url(/images/desaturate.svg#greyscale);
			}
		}
	}

	@at-root #{&}--image {
		// 
	}
	.grid-block__image,
	.grid-block__video-cover {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	@at-root #{&}--vertical-center {
		align-content: center;
	}


	.profile {
		margin-bottom: 0;
		font-size: rem-calc(18);
		.tag {
			display: block;
			text-transform: uppercase;
			font-size: rem-calc(16);
			font-family: $font-secondary;
			font-weight: bold;
		}
	}


}


.grid-block--video,
.grid-block--image,
.grid-block--overlay {
& > * {
		position: relative;
		z-index: 2;
	}
}


// clickable blocks

a.grid-block {
	display: block;
	color: $color-text;
	text-decoration: none;
	transition: background-color 0.3s;
	&:hover {
		background: rgba(#000, 0.1);
		@at-root #{&}--highlight {
			background: darken($color-secondary, 10%);
		}
	}

	@at-root #{&}--dark {
		color: #fff;
		&:hover {
			background: #000;
		}
	}
	@at-root #{&}--highlight {
		&:hover {
			background: darken($color-secondary, 10%);
		}
		&.grid-block--dark {
			&:hover {
				background: darken($color-octonary, 10%);
			}
		}
	}
}

// headline group adjustments
.grid-block {
	.headline-group {
		.superhead,
		.subhead {
			font-size: rem-calc(16);
			line-height: rem-calc(24);
			&:after {
				display: none;
			}
		}
		.head {
			font-size: rem-calc(28);
			line-height: rem-calc(34);
		}
		.superhead {
			margin-bottom: rem-calc(5);
		}
		.subhead {
			margin-top: rem-calc(5);
		}
	}
}


// video specific
.grid-block--video {
	padding: 0;
	align-items: stretch;
	justify-content: stretch;
	.grid-block__play-button {
		width: 100%;
		height: 100%;
		button.button {
			// this bg should be the same as the overlay
			// forgive the repetition
			background: rgba($color-primary, 0.7);

			margin: 0;
			width: 100%;
			height: 100%;
			svg {
				max-width: rem-calc(50);
				max-height: rem-calc(50);
			}

			&:hover,
			&:active,
			&:focus {
				background: rgba(darken($color-primary, 10%), 0.7);
				svg {
					color: #fff;
				}
			}
		}
	}
}