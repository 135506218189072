
.social-icon-links {
	margin-bottom: rem-calc(30);
	ul {
		display: flex;
		list-style: none;
		margin: 0;
		@include breakpoint(medium down) {
			flex-flow: row wrap;
		}
		li {
			margin-right: rem-calc(10);
			@include breakpoint(medium down) {
				margin: rem-calc(0 10 10 0);
			}
			a {
				svg {
					width: rem-calc(40);
					height: rem-calc(40);
					fill: $color-quaternary;
					color: $color-quaternary;
				}
				&:hover {
					svg {
						fill: $color-secondary;
						color: $color-secondary;
					}
				}
			}
		}
	}
}