@charset "UTF-8";
/* Colors
/* remember to turn off any unused swatches in atoms>global>colors.twig
/* _______________________________________________________________ */
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:400,600,700");
/* This is an example of setting color themes for text
   with a mixin. You can/should make several of these. */
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:400,600,700");
.ondark {
  color: #fff; }
  .ondark abbr {
    color: #fff; }
  .ondark a:link, .ondark a:visited {
    color: #fff; }
  .ondark a:hover, .ondark a:active {
    color: #fdb813; }

/*------------------------------------*    $MIXINS
\*------------------------------------*/
.fauxunderline {
  background: transparent linear-gradient(to bottom, transparent 50%, #fdb813 50%);
  text-decoration: none;
  background-size: 100% 200%;
  background-position: 0 4%;
  transition: all 0.1s ease-in-out; }
  .fauxunderline:hover {
    background-position: 0 12%; }

/*
* Foundation
*/
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=28.125em&large=53.125em&xlarge=75em&xxlarge=93.75em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 28.125em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 53.125em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 28.125em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 28.125em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 53.125em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 28.125em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #aaaaaa; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 28.125em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #fdb813;
  text-decoration: underline;
  cursor: pointer; }
  a:hover, a:focus {
    color: #e8a402; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #aaaaaa;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #000; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #000; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #000; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #000;
  color: #000;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #aaaaaa;
  background-color: #f7f4ef;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #f7f4ef;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #333333; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 28.125em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 53.125em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #333333;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #aaaaaa;
  border-radius: 0;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #333333;
    background-color: #fff;
    box-shadow: 0 0 5px #aaaaaa;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #aaaaaa; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #f7f4ef;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #000; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #aaaaaa;
  background: #f7f4ef;
  color: #000;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #aaaaaa; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #fff; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #aaaaaa;
  border-radius: 0;
  background-color: #fff;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2851, 51, 51%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #333333;
    background-color: #fff;
    box-shadow: 0 0 5px #aaaaaa;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #f7f4ef;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #5b7d1c;
  background-color: #eff2e8; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #5b7d1c; }

.is-invalid-label {
  color: #5b7d1c; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #5b7d1c; }
  .form-error.is-visible {
    display: block; }

.button, .pagination span a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #6d8d24;
  color: #fff; }
  [data-whatinput='mouse'] .button, [data-whatinput='mouse'] .pagination span a, .pagination span [data-whatinput='mouse'] a {
    outline: 0; }
  .button:hover, .pagination span a:hover, .button:focus, .pagination span a:focus {
    background-color: #5d781f;
    color: #fff; }
  .button.tiny, .pagination span a.tiny {
    font-size: 0.6rem; }
  .button.small, .pagination span a.small {
    font-size: 0.75rem; }
  .button.large, .pagination span a.large {
    font-size: 1.25rem; }
  .button.expanded, .pagination span a.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary, .pagination span a.primary {
    background-color: #6d8d24;
    color: #000; }
    .button.primary:hover, .pagination span a.primary:hover, .button.primary:focus, .pagination span a.primary:focus {
      background-color: #57711d;
      color: #000; }
  .button.secondary, .pagination span a.secondary {
    background-color: #a6d047;
    color: #000; }
    .button.secondary:hover, .pagination span a.secondary:hover, .button.secondary:focus, .pagination span a.secondary:focus {
      background-color: #89b22d;
      color: #000; }
  .button.success, .pagination span a.success {
    background-color: #303f0c;
    color: #fff; }
    .button.success:hover, .pagination span a.success:hover, .button.success:focus, .pagination span a.success:focus {
      background-color: #263309;
      color: #fff; }
  .button.warning, .pagination span a.warning {
    background-color: #ff6a00;
    color: #000; }
    .button.warning:hover, .pagination span a.warning:hover, .button.warning:focus, .pagination span a.warning:focus {
      background-color: #cc5500;
      color: #000; }
  .button.alert, .pagination span a.alert {
    background-color: #5b7d1c;
    color: #fff; }
    .button.alert:hover, .pagination span a.alert:hover, .button.alert:focus, .pagination span a.alert:focus {
      background-color: #496416;
      color: #fff; }
  .button.hollow, .pagination span a.hollow {
    border: 1px solid #6d8d24;
    color: #6d8d24; }
    .button.hollow, .pagination span a.hollow, .button.hollow:hover, .pagination span a.hollow:hover, .button.hollow:focus, .pagination span a.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .pagination span a.hollow:hover, .button.hollow:focus, .pagination span a.hollow:focus {
      border-color: #374712;
      color: #374712; }
    .button.hollow.primary, .pagination span a.hollow.primary {
      border: 1px solid #6d8d24;
      color: #6d8d24; }
      .button.hollow.primary:hover, .pagination span a.hollow.primary:hover, .button.hollow.primary:focus, .pagination span a.hollow.primary:focus {
        border-color: #374712;
        color: #374712; }
    .button.hollow.secondary, .pagination span a.hollow.secondary {
      border: 1px solid #a6d047;
      color: #a6d047; }
      .button.hollow.secondary:hover, .pagination span a.hollow.secondary:hover, .button.hollow.secondary:focus, .pagination span a.hollow.secondary:focus {
        border-color: #566f1c;
        color: #566f1c; }
    .button.hollow.success, .pagination span a.hollow.success {
      border: 1px solid #303f0c;
      color: #303f0c; }
      .button.hollow.success:hover, .pagination span a.hollow.success:hover, .button.hollow.success:focus, .pagination span a.hollow.success:focus {
        border-color: #182006;
        color: #182006; }
    .button.hollow.warning, .pagination span a.hollow.warning {
      border: 1px solid #ff6a00;
      color: #ff6a00; }
      .button.hollow.warning:hover, .pagination span a.hollow.warning:hover, .button.hollow.warning:focus, .pagination span a.hollow.warning:focus {
        border-color: #803500;
        color: #803500; }
    .button.hollow.alert, .pagination span a.hollow.alert {
      border: 1px solid #5b7d1c;
      color: #5b7d1c; }
      .button.hollow.alert:hover, .pagination span a.hollow.alert:hover, .button.hollow.alert:focus, .pagination span a.hollow.alert:focus {
        border-color: #2e3f0e;
        color: #2e3f0e; }
  .button.disabled, .pagination span a.disabled, .button[disabled], .pagination span a[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .pagination span a.disabled, .button.disabled:hover, .pagination span a.disabled:hover, .button.disabled:focus, .pagination span a.disabled:focus, .button[disabled], .pagination span a[disabled], .button[disabled]:hover, .pagination span a[disabled]:hover, .button[disabled]:focus, .pagination span a[disabled]:focus {
      background-color: #6d8d24;
      color: #fff; }
    .button.disabled.primary, .pagination span a.disabled.primary, .button[disabled].primary, .pagination span a[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .pagination span a.disabled.primary, .button.disabled.primary:hover, .pagination span a.disabled.primary:hover, .button.disabled.primary:focus, .pagination span a.disabled.primary:focus, .button[disabled].primary, .pagination span a[disabled].primary, .button[disabled].primary:hover, .pagination span a[disabled].primary:hover, .button[disabled].primary:focus, .pagination span a[disabled].primary:focus {
        background-color: #6d8d24;
        color: #000; }
    .button.disabled.secondary, .pagination span a.disabled.secondary, .button[disabled].secondary, .pagination span a[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .pagination span a.disabled.secondary, .button.disabled.secondary:hover, .pagination span a.disabled.secondary:hover, .button.disabled.secondary:focus, .pagination span a.disabled.secondary:focus, .button[disabled].secondary, .pagination span a[disabled].secondary, .button[disabled].secondary:hover, .pagination span a[disabled].secondary:hover, .button[disabled].secondary:focus, .pagination span a[disabled].secondary:focus {
        background-color: #a6d047;
        color: #000; }
    .button.disabled.success, .pagination span a.disabled.success, .button[disabled].success, .pagination span a[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .pagination span a.disabled.success, .button.disabled.success:hover, .pagination span a.disabled.success:hover, .button.disabled.success:focus, .pagination span a.disabled.success:focus, .button[disabled].success, .pagination span a[disabled].success, .button[disabled].success:hover, .pagination span a[disabled].success:hover, .button[disabled].success:focus, .pagination span a[disabled].success:focus {
        background-color: #303f0c;
        color: #fff; }
    .button.disabled.warning, .pagination span a.disabled.warning, .button[disabled].warning, .pagination span a[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .pagination span a.disabled.warning, .button.disabled.warning:hover, .pagination span a.disabled.warning:hover, .button.disabled.warning:focus, .pagination span a.disabled.warning:focus, .button[disabled].warning, .pagination span a[disabled].warning, .button[disabled].warning:hover, .pagination span a[disabled].warning:hover, .button[disabled].warning:focus, .pagination span a[disabled].warning:focus {
        background-color: #ff6a00;
        color: #000; }
    .button.disabled.alert, .pagination span a.disabled.alert, .button[disabled].alert, .pagination span a[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .pagination span a.disabled.alert, .button.disabled.alert:hover, .pagination span a.disabled.alert:hover, .button.disabled.alert:focus, .pagination span a.disabled.alert:focus, .button[disabled].alert, .pagination span a[disabled].alert, .button[disabled].alert:hover, .pagination span a[disabled].alert:hover, .button[disabled].alert:focus, .pagination span a[disabled].alert:focus {
        background-color: #5b7d1c;
        color: #fff; }
  .button.dropdown::after, .pagination span a.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after, .pagination span a.dropdown.hollow::after {
    border-top-color: #6d8d24; }
  .button.dropdown.hollow.primary::after, .pagination span a.dropdown.hollow.primary::after {
    border-top-color: #6d8d24; }
  .button.dropdown.hollow.secondary::after, .pagination span a.dropdown.hollow.secondary::after {
    border-top-color: #a6d047; }
  .button.dropdown.hollow.success::after, .pagination span a.dropdown.hollow.success::after {
    border-top-color: #303f0c; }
  .button.dropdown.hollow.warning::after, .pagination span a.dropdown.hollow.warning::after {
    border-top-color: #ff6a00; }
  .button.dropdown.hollow.alert::after, .pagination span a.dropdown.hollow.alert::after {
    border-top-color: #5b7d1c; }
  .button.arrow-only::after, .pagination span a.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

.accordion {
  margin-left: 0;
  background: #fdb813;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #000;
  border-bottom: 0;
  font-size: 1rem;
  line-height: 1;
  color: #6d8d24; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #000;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #c41230; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #000;
  border-bottom: 0;
  background-color: #fff;
  color: #000; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #000; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #6d8d24 transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #6d8d24;
  color: #fff; }
  .badge.primary {
    background: #6d8d24;
    color: #000; }
  .badge.secondary {
    background: #a6d047;
    color: #000; }
  .badge.success {
    background: #303f0c;
    color: #fff; }
  .badge.warning {
    background: #ff6a00;
    color: #000; }
  .badge.alert {
    background: #5b7d1c;
    color: #fff; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #000;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #aaaaaa; }
  .breadcrumbs a {
    color: #6d8d24; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #aaaaaa;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button, .button-group .pagination span a, .pagination span .button-group a {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child, .button-group .pagination span a:last-child, .pagination span .button-group a:last-child {
      margin-right: 0; }
  .button-group.tiny .button, .button-group.tiny .pagination span a, .pagination span .button-group.tiny a {
    font-size: 0.6rem; }
  .button-group.small .button, .button-group.small .pagination span a, .pagination span .button-group.small a {
    font-size: 0.75rem; }
  .button-group.large .button, .button-group.large .pagination span a, .pagination span .button-group.large a {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:last-child, .button-group.expanded .pagination span a:first-child:last-child, .pagination span .button-group.expanded a:first-child:last-child {
      width: 100%; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .pagination span a:first-child:nth-last-child(2), .pagination span .button-group.expanded a:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button, .button-group.expanded .pagination span a:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button, .pagination span .button-group.expanded a:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button, .button-group.expanded .pagination span .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ a, .pagination span .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ a, .button-group.expanded .pagination span a:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ a, .pagination span .button-group.expanded a:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ a {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(2):last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child, .button-group.expanded .pagination span .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ a:last-child, .pagination span .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ a:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ a:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ a:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .pagination span a:first-child:nth-last-child(3), .pagination span .button-group.expanded a:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button, .button-group.expanded .pagination span a:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button, .pagination span .button-group.expanded a:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button, .button-group.expanded .pagination span .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ a, .pagination span .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ a, .button-group.expanded .pagination span a:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ a, .pagination span .button-group.expanded a:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ a {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(3):last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child, .button-group.expanded .pagination span .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ a:last-child, .pagination span .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ a:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ a:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ a:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .pagination span a:first-child:nth-last-child(4), .pagination span .button-group.expanded a:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button, .button-group.expanded .pagination span a:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button, .pagination span .button-group.expanded a:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button, .button-group.expanded .pagination span .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ a, .pagination span .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ a, .button-group.expanded .pagination span a:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ a, .pagination span .button-group.expanded a:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ a {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(4):last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child, .button-group.expanded .pagination span .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ a:last-child, .pagination span .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ a:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ a:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ a:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .pagination span a:first-child:nth-last-child(5), .pagination span .button-group.expanded a:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button, .button-group.expanded .pagination span a:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button, .pagination span .button-group.expanded a:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button, .button-group.expanded .pagination span .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ a, .pagination span .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ a, .button-group.expanded .pagination span a:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ a, .pagination span .button-group.expanded a:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ a {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(5):last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child, .button-group.expanded .pagination span .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ a:last-child, .pagination span .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ a:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ a:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ a:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .pagination span a:first-child:nth-last-child(6), .pagination span .button-group.expanded a:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button, .button-group.expanded .pagination span a:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button, .pagination span .button-group.expanded a:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button, .button-group.expanded .pagination span .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ a, .pagination span .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ a, .button-group.expanded .pagination span a:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ a, .pagination span .button-group.expanded a:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ a {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(6):last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child, .button-group.expanded .pagination span .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ a:last-child, .pagination span .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ a:last-child, .button-group.expanded .pagination span a:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ a:last-child, .pagination span .button-group.expanded a:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ a:last-child {
        margin-right: -6px; }
  .button-group.primary .button, .button-group.primary .pagination span a, .pagination span .button-group.primary a {
    background-color: #6d8d24;
    color: #000; }
    .button-group.primary .button:hover, .button-group.primary .pagination span a:hover, .pagination span .button-group.primary a:hover, .button-group.primary .button:focus, .button-group.primary .pagination span a:focus, .pagination span .button-group.primary a:focus {
      background-color: #57711d;
      color: #000; }
  .button-group.secondary .button, .button-group.secondary .pagination span a, .pagination span .button-group.secondary a {
    background-color: #a6d047;
    color: #000; }
    .button-group.secondary .button:hover, .button-group.secondary .pagination span a:hover, .pagination span .button-group.secondary a:hover, .button-group.secondary .button:focus, .button-group.secondary .pagination span a:focus, .pagination span .button-group.secondary a:focus {
      background-color: #89b22d;
      color: #000; }
  .button-group.success .button, .button-group.success .pagination span a, .pagination span .button-group.success a {
    background-color: #303f0c;
    color: #fff; }
    .button-group.success .button:hover, .button-group.success .pagination span a:hover, .pagination span .button-group.success a:hover, .button-group.success .button:focus, .button-group.success .pagination span a:focus, .pagination span .button-group.success a:focus {
      background-color: #263309;
      color: #fff; }
  .button-group.warning .button, .button-group.warning .pagination span a, .pagination span .button-group.warning a {
    background-color: #ff6a00;
    color: #000; }
    .button-group.warning .button:hover, .button-group.warning .pagination span a:hover, .pagination span .button-group.warning a:hover, .button-group.warning .button:focus, .button-group.warning .pagination span a:focus, .pagination span .button-group.warning a:focus {
      background-color: #cc5500;
      color: #000; }
  .button-group.alert .button, .button-group.alert .pagination span a, .pagination span .button-group.alert a {
    background-color: #5b7d1c;
    color: #fff; }
    .button-group.alert .button:hover, .button-group.alert .pagination span a:hover, .pagination span .button-group.alert a:hover, .button-group.alert .button:focus, .button-group.alert .pagination span a:focus, .pagination span .button-group.alert a:focus {
      background-color: #496416;
      color: #fff; }
  .button-group.stacked .button, .button-group.stacked .pagination span a, .pagination span .button-group.stacked a, .button-group.stacked-for-small .button, .button-group.stacked-for-small .pagination span a, .pagination span .button-group.stacked-for-small a, .button-group.stacked-for-medium .button, .button-group.stacked-for-medium .pagination span a, .pagination span .button-group.stacked-for-medium a {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked .pagination span a:last-child, .pagination span .button-group.stacked a:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-small .pagination span a:last-child, .pagination span .button-group.stacked-for-small a:last-child, .button-group.stacked-for-medium .button:last-child, .button-group.stacked-for-medium .pagination span a:last-child, .pagination span .button-group.stacked-for-medium a:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 28.125em) {
    .button-group.stacked-for-small .button, .button-group.stacked-for-small .pagination span a, .pagination span .button-group.stacked-for-small a {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 53.125em) {
    .button-group.stacked-for-medium .button, .button-group.stacked-for-medium .pagination span a, .pagination span .button-group.stacked-for-medium a {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 28.0625em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button, .button-group.stacked-for-small.expanded .pagination span a, .pagination span .button-group.stacked-for-small.expanded a {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  background-color: white;
  color: #000; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #ecf5d7;
    color: #000; }
  .callout.secondary {
    background-color: #f2f8e3;
    color: #000; }
  .callout.success {
    background-color: #e7f5c8;
    color: #000; }
  .callout.warning {
    background-color: #ffe9d9;
    color: #000; }
  .callout.alert {
    background-color: #e9f5d3;
    color: #000; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  margin-bottom: 1rem;
  border: 1px solid #f7f4ef;
  border-radius: 0;
  background: #fff;
  box-shadow: none;
  overflow: hidden;
  color: #000; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  padding: 1rem;
  background: #f7f4ef; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.close-button {
  position: absolute;
  color: #333333;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #000; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 28.125em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 53.125em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #6d8d24;
    color: #fff; }
  .menu.menu-bordered li {
    border: 1px solid #f7f4ef; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #f7f4ef; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
    content: ''; }
  .menu-icon:hover::after {
    background: #aaaaaa;
    box-shadow: 0 7px 0 #aaaaaa, 0 14px 0 #aaaaaa; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    box-shadow: 0 7px 0 #000, 0 14px 0 #000;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #333333;
    box-shadow: 0 7px 0 #333333, 0 14px 0 #333333; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fff;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.drilldown-submenu-cover-previous {
  min-height: 100%; }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #6d8d24;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #6d8d24 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #aaaaaa;
  border-radius: 0;
  background-color: #fff;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fdb813 transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #fdb813 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #fdb813; }

@media print, screen and (min-width: 28.125em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fdb813 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #fdb813 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #fdb813; } }

@media print, screen and (min-width: 53.125em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fdb813 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #fdb813 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #fdb813; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #aaaaaa;
  background: #fff; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #fdb813 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #fdb813; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #6d8d24;
  color: #fff; }
  .label.primary {
    background: #6d8d24;
    color: #000; }
  .label.secondary {
    background: #a6d047;
    color: #000; }
  .label.success {
    background: #303f0c;
    color: #fff; }
  .label.warning {
    background: #ff6a00;
    color: #000; }
  .label.alert {
    background: #5b7d1c;
    color: #fff; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 28.0625em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #f7f4ef; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #f7f4ef; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(250px); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-250px); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

@media print, screen and (min-width: 28.125em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 53.125em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fff; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(0, 0, 0, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #aaaaaa; }
    .orbit-bullets button:hover {
      background-color: #333333; }
    .orbit-bullets button.is-active {
      background-color: #333333; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 28.125em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #000; }
    .pagination a:hover,
    .pagination button:hover {
      background: #f7f4ef; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #6d8d24;
    color: #fff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #aaaaaa;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #000; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #aaaaaa; }
  .progress.primary .progress-meter {
    background-color: #6d8d24; }
  .progress.secondary .progress-meter {
    background-color: #a6d047; }
  .progress.success .progress-meter {
    background-color: #303f0c; }
  .progress.warning .progress-meter {
    background-color: #ff6a00; }
  .progress.alert .progress-meter {
    background-color: #5b7d1c; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #6d8d24; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #f7f4ef;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #aaaaaa;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #6d8d24;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #5d781f; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #aaaaaa;
  border-radius: 0;
  background-color: #fff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 28.125em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 28.125em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  @media print, screen and (min-width: 28.125em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 28.125em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 28.125em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 28.125em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 28.0625em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #aaaaaa;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fff;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #6d8d24; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead, tbody, tfoot {
    border: 1px solid #f2f2f2;
    background-color: #fff; }
  caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  thead {
    background: #f9f9f9;
    color: #000; }
  tfoot {
    background: #f2f2f2;
    color: #000; }
  thead tr, tfoot tr {
    background: transparent; }
  thead th, thead td, tfoot th, tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  tbody th, tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #fff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #fff; }

@media screen and (max-width: 53.0625em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f4f4f4; }

table.hover tfoot tr:hover {
  background-color: #ededed; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #f7f4ef;
  background: #fdb813;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #6d8d24; }
  .tabs.primary > li > a {
    color: #000; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #779a27; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 1rem;
    line-height: 1;
    color: #6d8d24; }
    .tabs-title > a:hover {
      background: #5b7d1c;
      color: #5e791f; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #c41230;
      color: #6d8d24; }

.tabs-content {
  border: 1px solid #f7f4ef;
  border-top: 0;
  background: #fff;
  color: #000;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #f7f4ef;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel[aria-hidden="false"] {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fff;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(109, 141, 36, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #000;
  color: #fff; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #333333;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #000;
  font-size: 80%;
  color: #fff; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000 transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000 transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.top-bar {
  padding: 0.5rem; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #f7f4ef; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 28.125em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 53.0625em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 28.0625em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 28.125em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 28.125em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 28.0625em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 28.125em) and (max-width: 53.0625em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 28.0625em), screen and (min-width: 53.125em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 53.125em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 53.0625em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 53.125em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 53.0625em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

/*
* Icons
* don't directly reference bower-supplied iconsets in your objects, instead put
* includes and mapping in the below icon settings file
*/
/*
 * Any imports having to do with icons should go here, and then set relevant generic variables
 * to reference them
 */
/* Font awesome */
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url("//netdna.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.4.0");
  src: url("//netdna.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.4.0") format("embedded-opentype"), url("//netdna.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.4.0") format("woff2"), url("//netdna.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.4.0") format("woff"), url("//netdna.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.4.0") format("truetype"), url("//netdna.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.4.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: ""; }

.fa-music:before {
  content: ""; }

.fa-search:before {
  content: ""; }

.fa-envelope-o:before {
  content: ""; }

.fa-heart:before {
  content: ""; }

.fa-star:before {
  content: ""; }

.fa-star-o:before {
  content: ""; }

.fa-user:before {
  content: ""; }

.fa-film:before {
  content: ""; }

.fa-th-large:before {
  content: ""; }

.fa-th:before {
  content: ""; }

.fa-th-list:before {
  content: ""; }

.fa-check:before {
  content: ""; }

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: ""; }

.fa-search-plus:before {
  content: ""; }

.fa-search-minus:before {
  content: ""; }

.fa-power-off:before {
  content: ""; }

.fa-signal:before {
  content: ""; }

.fa-gear:before,
.fa-cog:before {
  content: ""; }

.fa-trash-o:before {
  content: ""; }

.fa-home:before {
  content: ""; }

.fa-file-o:before {
  content: ""; }

.fa-clock-o:before {
  content: ""; }

.fa-road:before {
  content: ""; }

.fa-download:before {
  content: ""; }

.fa-arrow-circle-o-down:before {
  content: ""; }

.fa-arrow-circle-o-up:before {
  content: ""; }

.fa-inbox:before {
  content: ""; }

.fa-play-circle-o:before {
  content: ""; }

.fa-rotate-right:before,
.fa-repeat:before {
  content: ""; }

.fa-refresh:before {
  content: ""; }

.fa-list-alt:before {
  content: ""; }

.fa-lock:before {
  content: ""; }

.fa-flag:before {
  content: ""; }

.fa-headphones:before {
  content: ""; }

.fa-volume-off:before {
  content: ""; }

.fa-volume-down:before {
  content: ""; }

.fa-volume-up:before {
  content: ""; }

.fa-qrcode:before {
  content: ""; }

.fa-barcode:before {
  content: ""; }

.fa-tag:before {
  content: ""; }

.fa-tags:before {
  content: ""; }

.fa-book:before {
  content: ""; }

.fa-bookmark:before {
  content: ""; }

.fa-print:before {
  content: ""; }

.fa-camera:before {
  content: ""; }

.fa-font:before {
  content: ""; }

.fa-bold:before {
  content: ""; }

.fa-italic:before {
  content: ""; }

.fa-text-height:before {
  content: ""; }

.fa-text-width:before {
  content: ""; }

.fa-align-left:before {
  content: ""; }

.fa-align-center:before {
  content: ""; }

.fa-align-right:before {
  content: ""; }

.fa-align-justify:before {
  content: ""; }

.fa-list:before {
  content: ""; }

.fa-dedent:before,
.fa-outdent:before {
  content: ""; }

.fa-indent:before {
  content: ""; }

.fa-video-camera:before {
  content: ""; }

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: ""; }

.fa-pencil:before {
  content: ""; }

.fa-map-marker:before {
  content: ""; }

.fa-adjust:before {
  content: ""; }

.fa-tint:before {
  content: ""; }

.fa-edit:before,
.fa-pencil-square-o:before {
  content: ""; }

.fa-share-square-o:before {
  content: ""; }

.fa-check-square-o:before {
  content: ""; }

.fa-arrows:before {
  content: ""; }

.fa-step-backward:before {
  content: ""; }

.fa-fast-backward:before {
  content: ""; }

.fa-backward:before {
  content: ""; }

.fa-play:before {
  content: ""; }

.fa-pause:before {
  content: ""; }

.fa-stop:before {
  content: ""; }

.fa-forward:before {
  content: ""; }

.fa-fast-forward:before {
  content: ""; }

.fa-step-forward:before {
  content: ""; }

.fa-eject:before {
  content: ""; }

.fa-chevron-left:before {
  content: ""; }

.fa-chevron-right:before {
  content: ""; }

.fa-plus-circle:before {
  content: ""; }

.fa-minus-circle:before {
  content: ""; }

.fa-times-circle:before {
  content: ""; }

.fa-check-circle:before {
  content: ""; }

.fa-question-circle:before {
  content: ""; }

.fa-info-circle:before {
  content: ""; }

.fa-crosshairs:before {
  content: ""; }

.fa-times-circle-o:before {
  content: ""; }

.fa-check-circle-o:before {
  content: ""; }

.fa-ban:before {
  content: ""; }

.fa-arrow-left:before {
  content: ""; }

.fa-arrow-right:before {
  content: ""; }

.fa-arrow-up:before {
  content: ""; }

.fa-arrow-down:before {
  content: ""; }

.fa-mail-forward:before,
.fa-share:before {
  content: ""; }

.fa-expand:before {
  content: ""; }

.fa-compress:before {
  content: ""; }

.fa-plus:before {
  content: ""; }

.fa-minus:before {
  content: ""; }

.fa-asterisk:before {
  content: ""; }

.fa-exclamation-circle:before {
  content: ""; }

.fa-gift:before {
  content: ""; }

.fa-leaf:before {
  content: ""; }

.fa-fire:before {
  content: ""; }

.fa-eye:before {
  content: ""; }

.fa-eye-slash:before {
  content: ""; }

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: ""; }

.fa-plane:before {
  content: ""; }

.fa-calendar:before {
  content: ""; }

.fa-random:before {
  content: ""; }

.fa-comment:before {
  content: ""; }

.fa-magnet:before {
  content: ""; }

.fa-chevron-up:before {
  content: ""; }

.fa-chevron-down:before {
  content: ""; }

.fa-retweet:before {
  content: ""; }

.fa-shopping-cart:before {
  content: ""; }

.fa-folder:before {
  content: ""; }

.fa-folder-open:before {
  content: ""; }

.fa-arrows-v:before {
  content: ""; }

.fa-arrows-h:before {
  content: ""; }

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: ""; }

.fa-twitter-square:before {
  content: ""; }

.fa-facebook-square:before {
  content: ""; }

.fa-camera-retro:before {
  content: ""; }

.fa-key:before {
  content: ""; }

.fa-gears:before,
.fa-cogs:before {
  content: ""; }

.fa-comments:before {
  content: ""; }

.fa-thumbs-o-up:before {
  content: ""; }

.fa-thumbs-o-down:before {
  content: ""; }

.fa-star-half:before {
  content: ""; }

.fa-heart-o:before {
  content: ""; }

.fa-sign-out:before {
  content: ""; }

.fa-linkedin-square:before {
  content: ""; }

.fa-thumb-tack:before {
  content: ""; }

.fa-external-link:before {
  content: ""; }

.fa-sign-in:before {
  content: ""; }

.fa-trophy:before {
  content: ""; }

.fa-github-square:before {
  content: ""; }

.fa-upload:before {
  content: ""; }

.fa-lemon-o:before {
  content: ""; }

.fa-phone:before {
  content: ""; }

.fa-square-o:before {
  content: ""; }

.fa-bookmark-o:before {
  content: ""; }

.fa-phone-square:before {
  content: ""; }

.fa-twitter:before {
  content: ""; }

.fa-facebook-f:before,
.fa-facebook:before {
  content: ""; }

.fa-github:before {
  content: ""; }

.fa-unlock:before {
  content: ""; }

.fa-credit-card:before {
  content: ""; }

.fa-feed:before,
.fa-rss:before {
  content: ""; }

.fa-hdd-o:before {
  content: ""; }

.fa-bullhorn:before {
  content: ""; }

.fa-bell:before {
  content: ""; }

.fa-certificate:before {
  content: ""; }

.fa-hand-o-right:before {
  content: ""; }

.fa-hand-o-left:before {
  content: ""; }

.fa-hand-o-up:before {
  content: ""; }

.fa-hand-o-down:before {
  content: ""; }

.fa-arrow-circle-left:before {
  content: ""; }

.fa-arrow-circle-right:before {
  content: ""; }

.fa-arrow-circle-up:before {
  content: ""; }

.fa-arrow-circle-down:before {
  content: ""; }

.fa-globe:before {
  content: ""; }

.fa-wrench:before {
  content: ""; }

.fa-tasks:before {
  content: ""; }

.fa-filter:before {
  content: ""; }

.fa-briefcase:before {
  content: ""; }

.fa-arrows-alt:before {
  content: ""; }

.fa-group:before,
.fa-users:before {
  content: ""; }

.fa-chain:before,
.fa-link:before {
  content: ""; }

.fa-cloud:before {
  content: ""; }

.fa-flask:before {
  content: ""; }

.fa-cut:before,
.fa-scissors:before {
  content: ""; }

.fa-copy:before,
.fa-files-o:before {
  content: ""; }

.fa-paperclip:before {
  content: ""; }

.fa-save:before,
.fa-floppy-o:before {
  content: ""; }

.fa-square:before {
  content: ""; }

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: ""; }

.fa-list-ul:before {
  content: ""; }

.fa-list-ol:before {
  content: ""; }

.fa-strikethrough:before {
  content: ""; }

.fa-underline:before {
  content: ""; }

.fa-table:before {
  content: ""; }

.fa-magic:before {
  content: ""; }

.fa-truck:before {
  content: ""; }

.fa-pinterest:before {
  content: ""; }

.fa-pinterest-square:before {
  content: ""; }

.fa-google-plus-square:before {
  content: ""; }

.fa-google-plus:before {
  content: ""; }

.fa-money:before {
  content: ""; }

.fa-caret-down:before {
  content: ""; }

.fa-caret-up:before {
  content: ""; }

.fa-caret-left:before {
  content: ""; }

.fa-caret-right:before {
  content: ""; }

.fa-columns:before {
  content: ""; }

.fa-unsorted:before,
.fa-sort:before {
  content: ""; }

.fa-sort-down:before,
.fa-sort-desc:before {
  content: ""; }

.fa-sort-up:before,
.fa-sort-asc:before {
  content: ""; }

.fa-envelope:before {
  content: ""; }

.fa-linkedin:before {
  content: ""; }

.fa-rotate-left:before,
.fa-undo:before {
  content: ""; }

.fa-legal:before,
.fa-gavel:before {
  content: ""; }

.fa-dashboard:before,
.fa-tachometer:before {
  content: ""; }

.fa-comment-o:before {
  content: ""; }

.fa-comments-o:before {
  content: ""; }

.fa-flash:before,
.fa-bolt:before {
  content: ""; }

.fa-sitemap:before {
  content: ""; }

.fa-umbrella:before {
  content: ""; }

.fa-paste:before,
.fa-clipboard:before {
  content: ""; }

.fa-lightbulb-o:before {
  content: ""; }

.fa-exchange:before {
  content: ""; }

.fa-cloud-download:before {
  content: ""; }

.fa-cloud-upload:before {
  content: ""; }

.fa-user-md:before {
  content: ""; }

.fa-stethoscope:before {
  content: ""; }

.fa-suitcase:before {
  content: ""; }

.fa-bell-o:before {
  content: ""; }

.fa-coffee:before {
  content: ""; }

.fa-cutlery:before {
  content: ""; }

.fa-file-text-o:before {
  content: ""; }

.fa-building-o:before {
  content: ""; }

.fa-hospital-o:before {
  content: ""; }

.fa-ambulance:before {
  content: ""; }

.fa-medkit:before {
  content: ""; }

.fa-fighter-jet:before {
  content: ""; }

.fa-beer:before {
  content: ""; }

.fa-h-square:before {
  content: ""; }

.fa-plus-square:before {
  content: ""; }

.fa-angle-double-left:before {
  content: ""; }

.fa-angle-double-right:before {
  content: ""; }

.fa-angle-double-up:before {
  content: ""; }

.fa-angle-double-down:before {
  content: ""; }

.fa-angle-left:before {
  content: ""; }

.fa-angle-right:before {
  content: ""; }

.fa-angle-up:before {
  content: ""; }

.fa-angle-down:before {
  content: ""; }

.fa-desktop:before {
  content: ""; }

.fa-laptop:before {
  content: ""; }

.fa-tablet:before {
  content: ""; }

.fa-mobile-phone:before,
.fa-mobile:before {
  content: ""; }

.fa-circle-o:before {
  content: ""; }

.fa-quote-left:before {
  content: ""; }

.fa-quote-right:before {
  content: ""; }

.fa-spinner:before {
  content: ""; }

.fa-circle:before {
  content: ""; }

.fa-mail-reply:before,
.fa-reply:before {
  content: ""; }

.fa-github-alt:before {
  content: ""; }

.fa-folder-o:before {
  content: ""; }

.fa-folder-open-o:before {
  content: ""; }

.fa-smile-o:before {
  content: ""; }

.fa-frown-o:before {
  content: ""; }

.fa-meh-o:before {
  content: ""; }

.fa-gamepad:before {
  content: ""; }

.fa-keyboard-o:before {
  content: ""; }

.fa-flag-o:before {
  content: ""; }

.fa-flag-checkered:before {
  content: ""; }

.fa-terminal:before {
  content: ""; }

.fa-code:before {
  content: ""; }

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: ""; }

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: ""; }

.fa-location-arrow:before {
  content: ""; }

.fa-crop:before {
  content: ""; }

.fa-code-fork:before {
  content: ""; }

.fa-unlink:before,
.fa-chain-broken:before {
  content: ""; }

.fa-question:before {
  content: ""; }

.fa-info:before {
  content: ""; }

.fa-exclamation:before {
  content: ""; }

.fa-superscript:before {
  content: ""; }

.fa-subscript:before {
  content: ""; }

.fa-eraser:before {
  content: ""; }

.fa-puzzle-piece:before {
  content: ""; }

.fa-microphone:before {
  content: ""; }

.fa-microphone-slash:before {
  content: ""; }

.fa-shield:before {
  content: ""; }

.fa-calendar-o:before {
  content: ""; }

.fa-fire-extinguisher:before {
  content: ""; }

.fa-rocket:before {
  content: ""; }

.fa-maxcdn:before {
  content: ""; }

.fa-chevron-circle-left:before {
  content: ""; }

.fa-chevron-circle-right:before {
  content: ""; }

.fa-chevron-circle-up:before {
  content: ""; }

.fa-chevron-circle-down:before {
  content: ""; }

.fa-html5:before {
  content: ""; }

.fa-css3:before {
  content: ""; }

.fa-anchor:before {
  content: ""; }

.fa-unlock-alt:before {
  content: ""; }

.fa-bullseye:before {
  content: ""; }

.fa-ellipsis-h:before {
  content: ""; }

.fa-ellipsis-v:before {
  content: ""; }

.fa-rss-square:before {
  content: ""; }

.fa-play-circle:before {
  content: ""; }

.fa-ticket:before {
  content: ""; }

.fa-minus-square:before {
  content: ""; }

.fa-minus-square-o:before {
  content: ""; }

.fa-level-up:before {
  content: ""; }

.fa-level-down:before {
  content: ""; }

.fa-check-square:before {
  content: ""; }

.fa-pencil-square:before {
  content: ""; }

.fa-external-link-square:before {
  content: ""; }

.fa-share-square:before {
  content: ""; }

.fa-compass:before {
  content: ""; }

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: ""; }

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: ""; }

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: ""; }

.fa-euro:before,
.fa-eur:before {
  content: ""; }

.fa-gbp:before {
  content: ""; }

.fa-dollar:before,
.fa-usd:before {
  content: ""; }

.fa-rupee:before,
.fa-inr:before {
  content: ""; }

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: ""; }

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: ""; }

.fa-won:before,
.fa-krw:before {
  content: ""; }

.fa-bitcoin:before,
.fa-btc:before {
  content: ""; }

.fa-file:before {
  content: ""; }

.fa-file-text:before {
  content: ""; }

.fa-sort-alpha-asc:before {
  content: ""; }

.fa-sort-alpha-desc:before {
  content: ""; }

.fa-sort-amount-asc:before {
  content: ""; }

.fa-sort-amount-desc:before {
  content: ""; }

.fa-sort-numeric-asc:before {
  content: ""; }

.fa-sort-numeric-desc:before {
  content: ""; }

.fa-thumbs-up:before {
  content: ""; }

.fa-thumbs-down:before {
  content: ""; }

.fa-youtube-square:before {
  content: ""; }

.fa-youtube:before {
  content: ""; }

.fa-xing:before {
  content: ""; }

.fa-xing-square:before {
  content: ""; }

.fa-youtube-play:before {
  content: ""; }

.fa-dropbox:before {
  content: ""; }

.fa-stack-overflow:before {
  content: ""; }

.fa-instagram:before {
  content: ""; }

.fa-flickr:before {
  content: ""; }

.fa-adn:before {
  content: ""; }

.fa-bitbucket:before {
  content: ""; }

.fa-bitbucket-square:before {
  content: ""; }

.fa-tumblr:before {
  content: ""; }

.fa-tumblr-square:before {
  content: ""; }

.fa-long-arrow-down:before {
  content: ""; }

.fa-long-arrow-up:before {
  content: ""; }

.fa-long-arrow-left:before {
  content: ""; }

.fa-long-arrow-right:before {
  content: ""; }

.fa-apple:before {
  content: ""; }

.fa-windows:before {
  content: ""; }

.fa-android:before {
  content: ""; }

.fa-linux:before {
  content: ""; }

.fa-dribbble:before {
  content: ""; }

.fa-skype:before {
  content: ""; }

.fa-foursquare:before {
  content: ""; }

.fa-trello:before {
  content: ""; }

.fa-female:before {
  content: ""; }

.fa-male:before {
  content: ""; }

.fa-gittip:before,
.fa-gratipay:before {
  content: ""; }

.fa-sun-o:before {
  content: ""; }

.fa-moon-o:before {
  content: ""; }

.fa-archive:before {
  content: ""; }

.fa-bug:before {
  content: ""; }

.fa-vk:before {
  content: ""; }

.fa-weibo:before {
  content: ""; }

.fa-renren:before {
  content: ""; }

.fa-pagelines:before {
  content: ""; }

.fa-stack-exchange:before {
  content: ""; }

.fa-arrow-circle-o-right:before {
  content: ""; }

.fa-arrow-circle-o-left:before {
  content: ""; }

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: ""; }

.fa-dot-circle-o:before {
  content: ""; }

.fa-wheelchair:before {
  content: ""; }

.fa-vimeo-square:before {
  content: ""; }

.fa-turkish-lira:before,
.fa-try:before {
  content: ""; }

.fa-plus-square-o:before {
  content: ""; }

.fa-space-shuttle:before {
  content: ""; }

.fa-slack:before {
  content: ""; }

.fa-envelope-square:before {
  content: ""; }

.fa-wordpress:before {
  content: ""; }

.fa-openid:before {
  content: ""; }

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: ""; }

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: ""; }

.fa-yahoo:before {
  content: ""; }

.fa-google:before {
  content: ""; }

.fa-reddit:before {
  content: ""; }

.fa-reddit-square:before {
  content: ""; }

.fa-stumbleupon-circle:before {
  content: ""; }

.fa-stumbleupon:before {
  content: ""; }

.fa-delicious:before {
  content: ""; }

.fa-digg:before {
  content: ""; }

.fa-pied-piper:before {
  content: ""; }

.fa-pied-piper-alt:before {
  content: ""; }

.fa-drupal:before {
  content: ""; }

.fa-joomla:before {
  content: ""; }

.fa-language:before {
  content: ""; }

.fa-fax:before {
  content: ""; }

.fa-building:before {
  content: ""; }

.fa-child:before {
  content: ""; }

.fa-paw:before {
  content: ""; }

.fa-spoon:before {
  content: ""; }

.fa-cube:before {
  content: ""; }

.fa-cubes:before {
  content: ""; }

.fa-behance:before {
  content: ""; }

.fa-behance-square:before {
  content: ""; }

.fa-steam:before {
  content: ""; }

.fa-steam-square:before {
  content: ""; }

.fa-recycle:before {
  content: ""; }

.fa-automobile:before,
.fa-car:before {
  content: ""; }

.fa-cab:before,
.fa-taxi:before {
  content: ""; }

.fa-tree:before {
  content: ""; }

.fa-spotify:before {
  content: ""; }

.fa-deviantart:before {
  content: ""; }

.fa-soundcloud:before {
  content: ""; }

.fa-database:before {
  content: ""; }

.fa-file-pdf-o:before {
  content: ""; }

.fa-file-word-o:before {
  content: ""; }

.fa-file-excel-o:before {
  content: ""; }

.fa-file-powerpoint-o:before {
  content: ""; }

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: ""; }

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: ""; }

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: ""; }

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: ""; }

.fa-file-code-o:before {
  content: ""; }

.fa-vine:before {
  content: ""; }

.fa-codepen:before {
  content: ""; }

.fa-jsfiddle:before {
  content: ""; }

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: ""; }

.fa-circle-o-notch:before {
  content: ""; }

.fa-ra:before,
.fa-rebel:before {
  content: ""; }

.fa-ge:before,
.fa-empire:before {
  content: ""; }

.fa-git-square:before {
  content: ""; }

.fa-git:before {
  content: ""; }

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: ""; }

.fa-tencent-weibo:before {
  content: ""; }

.fa-qq:before {
  content: ""; }

.fa-wechat:before,
.fa-weixin:before {
  content: ""; }

.fa-send:before,
.fa-paper-plane:before {
  content: ""; }

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: ""; }

.fa-history:before {
  content: ""; }

.fa-circle-thin:before {
  content: ""; }

.fa-header:before {
  content: ""; }

.fa-paragraph:before {
  content: ""; }

.fa-sliders:before {
  content: ""; }

.fa-share-alt:before {
  content: ""; }

.fa-share-alt-square:before {
  content: ""; }

.fa-bomb:before {
  content: ""; }

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: ""; }

.fa-tty:before {
  content: ""; }

.fa-binoculars:before {
  content: ""; }

.fa-plug:before {
  content: ""; }

.fa-slideshare:before {
  content: ""; }

.fa-twitch:before {
  content: ""; }

.fa-yelp:before {
  content: ""; }

.fa-newspaper-o:before {
  content: ""; }

.fa-wifi:before {
  content: ""; }

.fa-calculator:before {
  content: ""; }

.fa-paypal:before {
  content: ""; }

.fa-google-wallet:before {
  content: ""; }

.fa-cc-visa:before {
  content: ""; }

.fa-cc-mastercard:before {
  content: ""; }

.fa-cc-discover:before {
  content: ""; }

.fa-cc-amex:before {
  content: ""; }

.fa-cc-paypal:before {
  content: ""; }

.fa-cc-stripe:before {
  content: ""; }

.fa-bell-slash:before {
  content: ""; }

.fa-bell-slash-o:before {
  content: ""; }

.fa-trash:before {
  content: ""; }

.fa-copyright:before {
  content: ""; }

.fa-at:before {
  content: ""; }

.fa-eyedropper:before {
  content: ""; }

.fa-paint-brush:before {
  content: ""; }

.fa-birthday-cake:before {
  content: ""; }

.fa-area-chart:before {
  content: ""; }

.fa-pie-chart:before {
  content: ""; }

.fa-line-chart:before {
  content: ""; }

.fa-lastfm:before {
  content: ""; }

.fa-lastfm-square:before {
  content: ""; }

.fa-toggle-off:before {
  content: ""; }

.fa-toggle-on:before {
  content: ""; }

.fa-bicycle:before {
  content: ""; }

.fa-bus:before {
  content: ""; }

.fa-ioxhost:before {
  content: ""; }

.fa-angellist:before {
  content: ""; }

.fa-cc:before {
  content: ""; }

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: ""; }

.fa-meanpath:before {
  content: ""; }

.fa-buysellads:before {
  content: ""; }

.fa-connectdevelop:before {
  content: ""; }

.fa-dashcube:before {
  content: ""; }

.fa-forumbee:before {
  content: ""; }

.fa-leanpub:before {
  content: ""; }

.fa-sellsy:before {
  content: ""; }

.fa-shirtsinbulk:before {
  content: ""; }

.fa-simplybuilt:before {
  content: ""; }

.fa-skyatlas:before {
  content: ""; }

.fa-cart-plus:before {
  content: ""; }

.fa-cart-arrow-down:before {
  content: ""; }

.fa-diamond:before {
  content: ""; }

.fa-ship:before {
  content: ""; }

.fa-user-secret:before {
  content: ""; }

.fa-motorcycle:before {
  content: ""; }

.fa-street-view:before {
  content: ""; }

.fa-heartbeat:before {
  content: ""; }

.fa-venus:before {
  content: ""; }

.fa-mars:before {
  content: ""; }

.fa-mercury:before {
  content: ""; }

.fa-intersex:before,
.fa-transgender:before {
  content: ""; }

.fa-transgender-alt:before {
  content: ""; }

.fa-venus-double:before {
  content: ""; }

.fa-mars-double:before {
  content: ""; }

.fa-venus-mars:before {
  content: ""; }

.fa-mars-stroke:before {
  content: ""; }

.fa-mars-stroke-v:before {
  content: ""; }

.fa-mars-stroke-h:before {
  content: ""; }

.fa-neuter:before {
  content: ""; }

.fa-genderless:before {
  content: ""; }

.fa-facebook-official:before {
  content: ""; }

.fa-pinterest-p:before {
  content: ""; }

.fa-whatsapp:before {
  content: ""; }

.fa-server:before {
  content: ""; }

.fa-user-plus:before {
  content: ""; }

.fa-user-times:before {
  content: ""; }

.fa-hotel:before,
.fa-bed:before {
  content: ""; }

.fa-viacoin:before {
  content: ""; }

.fa-train:before {
  content: ""; }

.fa-subway:before {
  content: ""; }

.fa-medium:before {
  content: ""; }

.fa-yc:before,
.fa-y-combinator:before {
  content: ""; }

.fa-optin-monster:before {
  content: ""; }

.fa-opencart:before {
  content: ""; }

.fa-expeditedssl:before {
  content: ""; }

.fa-battery-4:before,
.fa-battery-full:before {
  content: ""; }

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: ""; }

.fa-battery-2:before,
.fa-battery-half:before {
  content: ""; }

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: ""; }

.fa-battery-0:before,
.fa-battery-empty:before {
  content: ""; }

.fa-mouse-pointer:before {
  content: ""; }

.fa-i-cursor:before {
  content: ""; }

.fa-object-group:before {
  content: ""; }

.fa-object-ungroup:before {
  content: ""; }

.fa-sticky-note:before {
  content: ""; }

.fa-sticky-note-o:before {
  content: ""; }

.fa-cc-jcb:before {
  content: ""; }

.fa-cc-diners-club:before {
  content: ""; }

.fa-clone:before {
  content: ""; }

.fa-balance-scale:before {
  content: ""; }

.fa-hourglass-o:before {
  content: ""; }

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: ""; }

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: ""; }

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: ""; }

.fa-hourglass:before {
  content: ""; }

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: ""; }

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: ""; }

.fa-hand-scissors-o:before {
  content: ""; }

.fa-hand-lizard-o:before {
  content: ""; }

.fa-hand-spock-o:before {
  content: ""; }

.fa-hand-pointer-o:before {
  content: ""; }

.fa-hand-peace-o:before {
  content: ""; }

.fa-trademark:before {
  content: ""; }

.fa-registered:before {
  content: ""; }

.fa-creative-commons:before {
  content: ""; }

.fa-gg:before {
  content: ""; }

.fa-gg-circle:before {
  content: ""; }

.fa-tripadvisor:before {
  content: ""; }

.fa-odnoklassniki:before {
  content: ""; }

.fa-odnoklassniki-square:before {
  content: ""; }

.fa-get-pocket:before {
  content: ""; }

.fa-wikipedia-w:before {
  content: ""; }

.fa-safari:before {
  content: ""; }

.fa-chrome:before {
  content: ""; }

.fa-firefox:before {
  content: ""; }

.fa-opera:before {
  content: ""; }

.fa-internet-explorer:before {
  content: ""; }

.fa-tv:before,
.fa-television:before {
  content: ""; }

.fa-contao:before {
  content: ""; }

.fa-500px:before {
  content: ""; }

.fa-amazon:before {
  content: ""; }

.fa-calendar-plus-o:before {
  content: ""; }

.fa-calendar-minus-o:before {
  content: ""; }

.fa-calendar-times-o:before {
  content: ""; }

.fa-calendar-check-o:before {
  content: ""; }

.fa-industry:before {
  content: ""; }

.fa-map-pin:before {
  content: ""; }

.fa-map-signs:before {
  content: ""; }

.fa-map-o:before {
  content: ""; }

.fa-map:before {
  content: ""; }

.fa-commenting:before {
  content: ""; }

.fa-commenting-o:before {
  content: ""; }

.fa-houzz:before {
  content: ""; }

.fa-vimeo:before {
  content: ""; }

.fa-black-tie:before {
  content: ""; }

.fa-fonticons:before {
  content: ""; }

/* Helper variables */
/* Define commonly used icons */
/* Slick */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px\9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: ""; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: ""; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.slick-prev {
  left: 0;
  z-index: 9; }
  .slick-prev svg {
    transform: rotateY(180deg); }

.slick-next {
  right: 0; }

.slick-prev, .slick-next {
  width: 7.5rem;
  height: 3.75rem;
  background: #fdb813; }
  .slick-prev ::before, .slick-next ::before {
    content: "" !important; }
  .slick-prev svg, .slick-next svg {
    height: 2rem;
    width: 2rem; }

.slick-disabled {
  visibility: hidden; }

.slick-slider {
  margin-bottom: 0; }

/*
 * Patterlab styles
 */
.bg-primary {
  background-color: #6d8d24; }
  .bg-primary::before {
    content: "#6d8d24";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-secondary {
  background-color: #fdb813; }
  .bg-secondary::before {
    content: "#fdb813";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-tertiary {
  background-color: #c41230; }
  .bg-tertiary::before {
    content: "#c41230";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-quaternary {
  background-color: #007eae; }
  .bg-quaternary::before {
    content: "#007eae";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-quinary {
  background-color: #5b7d1c; }
  .bg-quinary::before {
    content: "#5b7d1c";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-senary {
  background-color: #ff6a00; }
  .bg-senary::before {
    content: "#ff6a00";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-septenary {
  background-color: #e6bc3a; }
  .bg-septenary::before {
    content: "#e6bc3a";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-octonary {
  background-color: #F3901D; }
  .bg-octonary::before {
    content: "#F3901D";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-nonary {
  background-color: #303f0c; }
  .bg-nonary::before {
    content: "#303f0c";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-denary {
  background-color: #5f6a47; }
  .bg-denary::before {
    content: "#5f6a47";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-primary {
  background-color: #a6d047; }
  .bg-accent-primary::before {
    content: "#a6d047";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-secondary {
  background-color: #fed678; }
  .bg-accent-secondary::before {
    content: "#fed678";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-tertiary {
  background-color: #ef4d69; }
  .bg-accent-tertiary::before {
    content: "#ef4d69";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-quaternary {
  background-color: #15beff; }
  .bg-accent-quaternary::before {
    content: "#15beff";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-quinary {
  background-color: #98d02f; }
  .bg-accent-quinary::before {
    content: "#98d02f";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-senary {
  background-color: #ffa666; }
  .bg-accent-senary::before {
    content: "#ffa666";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-septenary {
  background-color: #f1db95; }
  .bg-accent-septenary::before {
    content: "#f1db95";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-octonary {
  background-color: #ff9111; }
  .bg-accent-octonary::before {
    content: "#ff9111";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-nonary {
  background-color: #70961b; }
  .bg-accent-nonary::before {
    content: "#70961b";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-denary {
  background-color: #94a275; }
  .bg-accent-denary::before {
    content: "#94a275";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade-lightest {
  background-color: #f7f7f7; }
  .bg-shade-lightest::before {
    content: "#f7f7f7";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade-lighter {
  background-color: #f7f4ef; }
  .bg-shade-lighter::before {
    content: "#f7f4ef";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade-light {
  background-color: #ededed; }
  .bg-shade-light::before {
    content: "#ededed";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade {
  background-color: #e3e3e3; }
  .bg-shade::before {
    content: "#e3e3e3";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade-dark {
  background-color: #757575; }
  .bg-shade-dark::before {
    content: "#757575";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-shade-darker {
  background-color: #333333; }
  .bg-shade-darker::before {
    content: "#333333";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-shade-darkest {
  background-color: #262626; }
  .bg-shade-darkest::before {
    content: "#262626";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.font-primary {
  font-family: "Open Sans", sans-serif; }

.font-secondary {
  font-family: "Open Sans Condensed", sans-serif; }

.font-tertiary {
  font-family: Georgia, sans-serif; }

.font-quaternary {
  font-family: "Open Sans"; }

html, body, .main {
  height: 100%; }

.main {
  display: flex;
  flex-direction: row;
  align-items: stretch; }
  .main__nav {
    width: 26.25rem;
    min-width: 26.25rem;
    z-index: 1; }
    .main__nav.top-nav-only {
      width: 11.25rem;
      min-width: 11.25rem; }
      .main__nav.top-nav-only header.main-header {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
    .main__nav.has-title-navbar {
      width: 38.125rem;
      min-width: 38.125rem; }
      @media screen and (max-width: 74.9375em) {
        .main__nav.has-title-navbar {
          width: 26.25rem;
          min-width: 26.25rem; } }
      @media screen and (max-width: 53.0625em) {
        .main__nav.has-title-navbar {
          width: 100%;
          min-width: 0; } }
    .main__nav .main-header {
      position: fixed;
      min-height: 30rem;
      z-index: 10; }
  .main__page {
    width: 100%;
    height: 100%; }
    @media screen and (max-width: 74.9375em) {
      .main__page {
        flex: 0 1 auto; } }

@media screen and (max-width: 53.0625em) {
  .main {
    flex-flow: row wrap;
    height: auto; }
    .main .main__nav {
      width: 100%;
      min-width: 0; }
      .main .main__nav .main-header {
        position: static;
        min-height: 0;
        height: auto; }
    .main .main__page {
      width: 100%; } }

img {
  max-width: 100%;
  height: auto;
  display: inline-block; }

.icon-wrapper {
  display: inline-block;
  padding-right: 0.5rem;
  display: none !important; }

.icon {
  height: 1em;
  width: 1em;
  display: inline-block; }

.decoration {
  display: inline-block; }
  .decoration::before {
    content: "✪";
    margin-right: 0.5em;
    font-family: sans-serif; }

.decoration-on-right {
  display: inline-block; }
  .decoration-on-right::after {
    content: "✪";
    margin-left: 0.5em;
    font-family: sans-serif; }

.decoration-both {
  display: inline-block; }
  .decoration-both::before {
    content: "✪";
    margin-right: 0.5em;
    font-family: sans-serif; }
  .decoration-both::after {
    content: "✪";
    margin-left: 0.5em;
    font-family: sans-serif; }

.arrow-right {
  display: inline-block; }
  .arrow-right::before {
    content: "→";
    margin-right: 0.5em;
    font-family: sans-serif; }

.arrow-down {
  display: inline-block; }
  .arrow-down::before {
    content: "↓";
    margin-right: 0.5em;
    font-family: sans-serif; }

.arrow-left {
  display: inline-block; }
  .arrow-left::before {
    content: "←";
    margin-right: 0.5em;
    font-family: sans-serif; }

.arrow-up {
  display: inline-block; }
  .arrow-up::before {
    content: "↑";
    margin-right: 0.5em;
    font-family: sans-serif; }

.triangle-right {
  display: inline-block; }
  .triangle-right::after {
    content: "▶";
    margin-left: 0.5em;
    font-family: sans-serif; }

.triangle-down {
  display: inline-block; }
  .triangle-down::after {
    content: "▶";
    margin-left: 0.5em;
    font-family: sans-serif;
    transform: rotate(90deg);
    display: inline-block; }

.triangle-left {
  display: inline-block; }
  .triangle-left::after {
    content: "◀";
    margin-left: 0.5em;
    font-family: sans-serif; }

.triangle-up {
  display: inline-block; }
  .triangle-up::after {
    content: "◀";
    margin-left: 0.5em;
    font-family: sans-serif;
    transform: rotate(90deg);
    display: inline-block; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right !important; }

.align-center {
  text-align: center; }

.align-justify {
  text-align: justify; }

.align-justified-and-ancient {
  text-align: justify;
  font-family: fantasy; }

a {
  color: #007eae; }

.link {
  font-weight: 700;
  color: #007eae;
  text-decoration: none;
  display: inline-flex;
  align-items: baseline; }
  .link svg {
    display: inline-flex;
    height: 1rem;
    width: 1rem;
    margin-right: 0.4375rem; }

.link--arrow {
  vertical-align: top; }
  .link--arrow svg {
    height: 1rem;
    width: 1rem;
    transform: rotate(180deg); }

.link--basic {
  color: inherit;
  text-decoration: underline; }

.link--inverted {
  color: #fff; }

p .link {
  background: transparent linear-gradient(to bottom, transparent 50%, #007eae 50%);
  text-decoration: none;
  background-size: 100% 200%;
  background-position: 0 4%;
  transition: all 0.1s ease-in-out; }
  p .link:hover {
    background-position: 0 12%; }
  p .link:hover {
    color: #007eae; }

.icon-label {
  white-space: nowrap; }
  .icon-label svg {
    height: 1rem;
    width: 1rem;
    margin-right: 3px;
    position: relative;
    top: 0.125rem; }
  .icon-label .arrow,
  .icon-label .icon-wrapper,
  .icon-label .label {
    display: inline-block;
    vertical-align: top;
    max-height: 1em; }
  .icon-label .icon-wrapper:first-child {
    margin-right: 0.5em; }
  .icon-label icon-wrapper:last-child {
    margin-left: 0.5em; }
  .icon-label .arrow {
    font-family: arial, helvetica, sans-serif; }
  .icon-label .label {
    white-space: normal; }

h1 {
  font: normal 700 2.5rem "Open Sans", sans-serif;
  line-height: 3.125rem;
  text-transform: none;
  font-family: inherit;
  color: #333333; }
  h1.heading--caps {
    text-transform: uppercase; }

h2 {
  font: normal 700 1.875rem "Open Sans", sans-serif;
  line-height: 2.5rem;
  text-transform: none;
  font-family: inherit;
  color: #333333; }
  h2.heading--caps {
    text-transform: uppercase; }

h3 {
  font: normal 700 1.5625rem "Open Sans", sans-serif;
  line-height: 1.875rem;
  text-transform: none;
  font-family: inherit;
  color: #333333; }
  h3.heading--caps {
    text-transform: uppercase; }

h4 {
  font: normal 700 1.16875rem Georgia, sans-serif;
  line-height: 1.5;
  text-transform: none;
  font-family: inherit;
  color: #333333; }
  h4.heading--caps {
    text-transform: uppercase; }

h5 {
  font: normal bold 1rem "Open Sans", sans-serif;
  line-height: 1.5;
  text-transform: none;
  font-family: inherit;
  color: #333333; }
  h5.heading--caps {
    text-transform: uppercase; }

h6 {
  font: italic normal 1rem "Open Sans", sans-serif;
  line-height: 1.5;
  text-transform: none;
  font-family: inherit;
  color: #333333; }
  h6.heading--caps {
    text-transform: uppercase; }

.heading--condensed {
  font-family: "Open Sans Condensed", sans-serif; }

.formatted-text--dark {
  color: #fff;
  background-color: #000; }
  .formatted-text--dark abbr {
    color: #fff; }
  .formatted-text--dark a:link, .formatted-text--dark a:visited {
    color: #fff; }
  .formatted-text--dark a:hover, .formatted-text--dark a:active {
    color: #fdb813; }

.pullquote {
  background: #fdb813;
  padding: 3.1875rem 3rem 3.875rem 5.625rem;
  text-align: center;
  color: #333333;
  position: relative;
  min-width: 20rem;
  margin-left: 1.5625rem; }
  .pullquote__icon {
    height: 3.75rem;
    width: 3.75rem;
    background: #fff;
    display: block;
    border-radius: 5rem;
    position: absolute;
    left: -1.875rem;
    top: 1.3125rem; }
    .pullquote__icon svg {
      height: 100%;
      width: 1.625rem; }
  .pullquote__quote {
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.5625rem;
    font-weight: 700;
    text-align: left;
    line-height: 1.8125rem; }
  .pullquote__attribution {
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    font-style: normal;
    text-align: left; }
    .pullquote__attribution:before {
      content: "";
      width: 6.25rem;
      height: 0.25rem;
      background: #333333;
      display: block;
      margin-bottom: 1rem; }
  .pullquote__first-line {
    display: block; }
  .pullquote__second-line {
    display: block; }

.pullquote-secondary {
  font-family: "Open Sans", sans-serif;
  padding: 0 0 5.1875rem 4.1875rem;
  margin: 2rem 0 2rem -4.1875rem;
  position: relative; }
  .pullquote-secondary:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 28.3125rem solid transparent;
    border-bottom: 28.3125rem solid #ededed;
    border-right: 28.3125rem solid transparent;
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 0; }
  .pullquote-secondary__quote {
    color: #333333;
    font-size: 1.875rem;
    line-height: 2.5rem; }
  .pullquote-secondary__attribution {
    color: #6d8d24;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.625rem;
    font-style: normal; }
  @media screen and (max-width: 28.0625em) {
    .pullquote-secondary {
      overflow: hidden; } }

.with-sidebar__sidebar .pullquote-secondary {
  padding: 0;
  margin: 0.625rem 0 1.875rem 0; }
  .with-sidebar__sidebar .pullquote-secondary .pullquote-secondary__quote {
    font-size: 1.125rem;
    line-height: 1.75rem; }
  .with-sidebar__sidebar .pullquote-secondary:before {
    display: none; }

.pullquote-double {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 93.6875em) {
    .pullquote-double {
      flex-flow: row wrap; } }
  .pullquote-double .pullquote,
  .pullquote-double .pullquote-secondary {
    width: 48%; }
    @media screen and (max-width: 93.6875em) {
      .pullquote-double .pullquote,
      .pullquote-double .pullquote-secondary {
        width: 100%;
        padding-bottom: 0; }
        .pullquote-double .pullquote:before,
        .pullquote-double .pullquote-secondary:before {
          display: none; } }
    .pullquote-double .pullquote .pullquote-secondary__quote,
    .pullquote-double .pullquote-secondary .pullquote-secondary__quote {
      font-size: 1.25rem;
      line-height: 1.875rem; }
    .pullquote-double .pullquote:nth-child(2):before,
    .pullquote-double .pullquote-secondary:nth-child(2):before {
      display: none; }

.back-to-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.75rem 0;
  text-decoration: none;
  width: 10rem;
  margin: 0 auto; }
  .back-to-top__icon svg {
    height: 2.5rem;
    width: 2.5rem;
    transform: rotate(90deg); }
  .back-to-top__text {
    color: #007eae;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-transform: uppercase;
    text-decoration: none; }

.radio-buttons {
  padding-bottom: 1rem;
  margin: 0; }
  .radio-buttons ul {
    display: flex;
    flex-wrap: wrap; }
  .radio-buttons li {
    list-style: none;
    padding-right: 2rem;
    min-width: 7rem; }
  .radio-buttons label {
    color: #f7f7f7;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.625rem; }

button,
.button,
.pagination span a {
  background: #6d8d24;
  color: #fff;
  font: normal bold 1rem "Open Sans", sans-serif;
  line-height: 1;
  text-transform: none;
  font-family: inherit;
  border-radius: 0;
  border-width: 0;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  min-height: 3.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: inline-flex; }
  button:hover, button:active, button:focus,
  .button:hover,
  .pagination span a:hover,
  .button:active,
  .pagination span a:active,
  .button:focus,
  .pagination span a:focus {
    background: #4e641a; }
  button .arrow,
  .button .arrow, .pagination span a .arrow {
    display: inline-block;
    margin-right: 0.3em;
    font-family: arial, helvetica, sans-serif; }
  button, .button--secondary, .callout-dark .button, .callout .with-sidebar .button, .callout-dark .pagination span a, .pagination span .callout-dark a, .callout .with-sidebar .pagination span a, .pagination span .callout .with-sidebar a,
  .callout-dark button, .callout .with-sidebar button {
    background: #fdb813;
    color: #333333; }
    button:hover, button:active, button:focus, .button--secondary:hover, .callout-dark .button:hover, .callout .with-sidebar .button:hover, .callout-dark .pagination span a:hover, .pagination span .callout-dark a:hover, .callout .with-sidebar .pagination span a:hover, .pagination span .callout .with-sidebar a:hover,
    .callout-dark button:hover, .callout .with-sidebar button:hover, .button--secondary:active, .callout-dark .button:active, .callout .with-sidebar .button:active, .callout-dark .pagination span a:active, .pagination span .callout-dark a:active, .callout .with-sidebar .pagination span a:active, .pagination span .callout .with-sidebar a:active,
    .callout-dark button:active, .callout .with-sidebar button:active, .button--secondary:focus, .callout-dark .button:focus, .callout .with-sidebar .button:focus, .callout-dark .pagination span a:focus, .pagination span .callout-dark a:focus, .callout .with-sidebar .pagination span a:focus, .pagination span .callout .with-sidebar a:focus,
    .callout-dark button:focus, .callout .with-sidebar button:focus {
      background: #F3901D;
      color: #333333; }
  button, .button--tertiary {
    background: #007eae; }
    button:hover, button:active, button:focus, .button--tertiary:hover, .button--tertiary:active, .button--tertiary:focus {
      background: #00597b; }
  button, .button--quaternary {
    background: #333333; }
    button:hover, button:active, button:focus, .button--quaternary:hover, .button--quaternary:active, .button--quaternary:focus {
      background: #000; }
  button, .button--ghost {
    background: transparent;
    border: 0.125rem solid #fdb813;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.875rem; }
    button:hover, button:active, button:focus, .button--ghost:hover, .button--ghost:active, .button--ghost:focus {
      color: #333333;
      background: #fdb813; }
      button:hover svg, button:active svg, button:focus svg, .button--ghost:hover svg, .button--ghost:active svg, .button--ghost:focus svg {
        color: #333333;
        fill: #333333; }
        button:hover svg path, button:active svg path, button:focus svg path, .button--ghost:hover svg path, .button--ghost:active svg path, .button--ghost:focus svg path {
          fill: #333333; }

ul li, ol li {
  line-height: 2.1875rem; }

ul.no-decoration {
  margin: 0 0 0 0;
  list-style: none;
  padding: 0 0 0 0; }
  ul.no-decoration > li {
    text-indent: 0;
    margin-left: 0; }
    ul.no-decoration > li:before {
      display: none; }

ol.no-decoration {
  margin: 0 0 0 0;
  list-style: none;
  padding: 0 0 0 0; }
  ol.no-decoration > li {
    text-indent: 0;
    margin-left: 0; }
    ol.no-decoration > li:before {
      display: none; }

.list--unordered {
  list-style-image: url("../images/list-plus.svg");
  margin-top: 2rem;
  margin-bottom: 2rem; }

.list--ordered {
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

dl {
  margin-bottom: 1.875rem; }
  dl dt {
    margin-bottom: 0; }
  dl dd {
    margin-bottom: 0.9375rem; }

figure img {
  width: 100%;
  height: auto; }

figure figcaption {
  margin-top: 1em;
  color: rgba(38, 38, 38, 0.75);
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-size: 0.875rem;
  line-height: 1.5rem; }

.embedded-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .embedded-video iframe,
  .embedded-video object,
  .embedded-video embed,
  .embedded-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.embedded-widescreen-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 56.25%;
  overflow: hidden; }
  .embedded-widescreen-video iframe,
  .embedded-widescreen-video object,
  .embedded-widescreen-video embed,
  .embedded-widescreen-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.video-reveal {
  background: #000;
  border: 0; }
  @media screen and (max-width: 28.0625em) {
    .video-reveal {
      padding-top: 2.5rem; } }
  .video-reveal .close-button {
    background: #000;
    border-bottom-left-radius: 50%;
    right: 0.5rem;
    border: 0; }
    .video-reveal .close-button svg {
      transform: none;
      height: 2rem;
      width: 2rem; }

img[align="right"],
.image-align-right {
  margin-left: 1.25rem;
  margin-bottom: 1.25rem; }

img[align="left"],
.image-align-left {
  margin-right: 1.25rem;
  margin-bottom: 1.25rem; }

.headline-group {
  display: flex;
  flex-direction: column; }
  .headline-group .superhead,
  .headline-group .subhead {
    color: inherit;
    display: block;
    font-family: "Open Sans", sans-serif; }
  .headline-group .superhead {
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 3.125rem; }
    .headline-group .superhead:after {
      content: "";
      width: 6.25rem;
      height: 0.125rem;
      background: #fdb813;
      display: block;
      margin: 1rem 0; }
  .headline-group .head {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem; }
  .headline-group h1 .superhead {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.125rem; }
  .headline-group h1 .subhead {
    font-size: 1.25rem; }

.described-links__item {
  padding: 0.3125rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.described-links__description {
  color: #333333; }

.described-links__link svg {
  width: 1.625rem;
  height: 1.625rem; }

.introductory {
  padding: 5.1875vw 4.5625vw 6.25vw 4.1875vw;
  height: 100%; }
  .introductory__headline {
    color: #333333;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.125rem; }
    .introductory__headline h1 {
      font-weight: inherit; }
    .introductory__headline:after {
      content: "";
      display: block;
      width: 6.25rem;
      height: 0.125rem;
      background-color: #fdb813;
      margin: 0.625rem 0; }
  .introductory__paragraph {
    font-size: 1.25rem; }

.generic {
  font-family: "Open Sans", sans-serif;
  color: #333333; }
  .generic--inverted {
    color: #f7f4ef; }
  .generic__title {
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.5rem;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem; }

.rich-link-list ul {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0; }
  @media screen and (max-width: 74.9375em) {
    .rich-link-list ul {
      flex-flow: row wrap; } }
  .rich-link-list ul li {
    margin: 0; }
    @media screen and (max-width: 74.9375em) {
      .rich-link-list ul li {
        width: 48%;
        margin-bottom: 1.25rem; } }
    @media screen and (max-width: 28.0625em) {
      .rich-link-list ul li {
        width: 100%;
        margin-bottom: 1.875rem; } }
    .rich-link-list ul li a {
      display: block;
      text-decoration: none;
      font-weight: bold;
      font-size: 1.875rem;
      line-height: 1.875rem; }
      @media screen and (max-width: 28.0625em) {
        .rich-link-list ul li a {
          font-size: 1.5rem;
          line-height: 1.75rem; } }
      .rich-link-list ul li a .superhead {
        display: block;
        font-size: 1.25rem;
        color: #262626; }
        @media screen and (max-width: 28.0625em) {
          .rich-link-list ul li a .superhead {
            font-size: 1rem; } }

.rich-link-list--stack ul {
  flex-flow: row wrap; }
  .rich-link-list--stack ul li {
    width: 100%;
    padding-bottom: 1.875rem;
    border-bottom: #e3e3e3 2px solid;
    margin-bottom: 1.875rem; }
    .rich-link-list--stack ul li a {
      font-family: "Open Sans Condensed", sans-serif;
      font-size: 1.625rem; }

.one-column {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .one-column::before, .one-column::after {
    display: table;
    content: ' '; }
  .one-column::after {
    clear: both; }
  .one-column__col {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
    @media print, screen and (min-width: 28.125em) {
      .one-column__col {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .one-column__col:last-child:not(:first-child) {
      float: right; }
  .one-column.simple {
    padding: 0 1.75vw; }
    .one-column.simple .one-column__col {
      padding: 0 0.9375rem; }
  .one-column.standalone {
    padding: 0 3.125vw;
    margin: 0;
    max-width: none; }
    .one-column.standalone .one-column__col {
      padding: 0;
      float: none; }
    .one-column.standalone.constrain .one-column__col {
      max-width: 50rem; }
  .one-column.shift-down {
    margin-top: 3.125rem; }

.two-columns {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 3.125vw; }
  .two-columns__left {
    flex: 1;
    min-width: 20rem; }
  .two-columns__right {
    flex: 1;
    min-width: 20rem; }
  @media screen and (max-width: 28.0625em) {
    .two-columns .two-columns__left, .two-columns .two-columns__right {
      min-width: 15rem; } }

.three-columns {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .three-columns::before, .three-columns::after {
    display: table;
    content: ' '; }
  .three-columns::after {
    clear: both; }
  .three-columns__left {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    border-bottom: 1px #333333 solid;
    margin-bottom: 1rem; }
    @media print, screen and (min-width: 28.125em) {
      .three-columns__left {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .three-columns__left:last-child:not(:first-child) {
      float: right; }
  .three-columns__center {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    border-bottom: 1px #333333 solid;
    margin-bottom: 1rem; }
    @media print, screen and (min-width: 28.125em) {
      .three-columns__center {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .three-columns__center:last-child:not(:first-child) {
      float: right; }
  .three-columns__right {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
    @media print, screen and (min-width: 28.125em) {
      .three-columns__right {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .three-columns__right:last-child:not(:first-child) {
      float: right; }
  @media print, screen and (min-width: 28.125em) {
    .three-columns__left {
      width: 50%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      border-bottom-width: 0;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 28.125em) and (min-width: 28.125em) {
    .three-columns__left {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 28.125em) {
      .three-columns__left:last-child:not(:first-child) {
        float: right; }
    .three-columns__center {
      width: 50%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      border-bottom-width: 0;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 28.125em) and (min-width: 28.125em) {
    .three-columns__center {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 28.125em) {
      .three-columns__center:last-child:not(:first-child) {
        float: right; }
    .three-columns__right {
      width: 100%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      border-top: 1px #333333 solid;
      padding-top: 1rem;
      clear: left;
      border-bottom-width: 0;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 28.125em) and (min-width: 28.125em) {
    .three-columns__right {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 28.125em) {
      .three-columns__right:last-child:not(:first-child) {
        float: right; } }
  @media print, screen and (min-width: 53.125em) {
    .three-columns__left {
      width: 33.33333%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 53.125em) and (min-width: 28.125em) {
    .three-columns__left {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 53.125em) {
      .three-columns__left:last-child:not(:first-child) {
        float: right; }
    .three-columns__center {
      width: 33.33333%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 53.125em) and (min-width: 28.125em) {
    .three-columns__center {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 53.125em) {
      .three-columns__center:last-child:not(:first-child) {
        float: right; }
    .three-columns__right {
      width: 33.33333%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      border-top-width: 0;
      padding-top: 0;
      clear: none; } }
  @media print, screen and (min-width: 53.125em) and (min-width: 28.125em) {
    .three-columns__right {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 53.125em) {
      .three-columns__right:last-child:not(:first-child) {
        float: right; } }

.four-columns {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .four-columns::before, .four-columns::after {
    display: table;
    content: ' '; }
  .four-columns::after {
    clear: both; }
  .four-columns__left {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    border-bottom: 1px #333333 solid;
    margin-bottom: 1rem; }
    @media print, screen and (min-width: 28.125em) {
      .four-columns__left {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .four-columns__left:last-child:not(:first-child) {
      float: right; }
  .four-columns__center-left {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    border-bottom: 1px #333333 solid;
    margin-bottom: 1rem; }
    @media print, screen and (min-width: 28.125em) {
      .four-columns__center-left {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .four-columns__center-left:last-child:not(:first-child) {
      float: right; }
  .four-columns__center-right {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    border-bottom: 1px #333333 solid;
    margin-bottom: 1rem; }
    @media print, screen and (min-width: 28.125em) {
      .four-columns__center-right {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .four-columns__center-right:last-child:not(:first-child) {
      float: right; }
  .four-columns__right {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
    @media print, screen and (min-width: 28.125em) {
      .four-columns__right {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .four-columns__right:last-child:not(:first-child) {
      float: right; }
  @media print, screen and (min-width: 28.125em) {
    .four-columns__left {
      width: 50%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      clear: left;
      border-bottom-width: 0;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 28.125em) and (min-width: 28.125em) {
    .four-columns__left {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 28.125em) {
      .four-columns__left:last-child:not(:first-child) {
        float: right; }
    .four-columns__center-left {
      width: 50%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      border-bottom-width: 0;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 28.125em) and (min-width: 28.125em) {
    .four-columns__center-left {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 28.125em) {
      .four-columns__center-left:last-child:not(:first-child) {
        float: right; }
    .four-columns__center-right {
      width: 50%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      clear: left;
      border-top: 1px #333333 solid;
      margin-top: 1rem;
      padding-top: 1rem;
      border-bottom-width: 0;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 28.125em) and (min-width: 28.125em) {
    .four-columns__center-right {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 28.125em) {
      .four-columns__center-right:last-child:not(:first-child) {
        float: right; }
    .four-columns__right {
      width: 50%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      border-top: 1px #333333 solid;
      margin-top: 1rem;
      padding-top: 1rem;
      border-bottom-width: 0;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 28.125em) and (min-width: 28.125em) {
    .four-columns__right {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 28.125em) {
      .four-columns__right:last-child:not(:first-child) {
        float: right; } }
  @media print, screen and (min-width: 53.125em) {
    .four-columns__left {
      width: 25%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      clear: none; } }
  @media print, screen and (min-width: 53.125em) and (min-width: 28.125em) {
    .four-columns__left {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 53.125em) {
      .four-columns__left:last-child:not(:first-child) {
        float: right; }
    .four-columns__center-left {
      width: 25%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 53.125em) and (min-width: 28.125em) {
    .four-columns__center-left {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 53.125em) {
      .four-columns__center-left:last-child:not(:first-child) {
        float: right; }
    .four-columns__center-right {
      width: 25%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      clear: none;
      border-top-width: 0;
      margin-top: 0;
      padding-top: 0; } }
  @media print, screen and (min-width: 53.125em) and (min-width: 28.125em) {
    .four-columns__center-right {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 53.125em) {
      .four-columns__center-right:last-child:not(:first-child) {
        float: right; }
    .four-columns__right {
      width: 25%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      border-top-width: 0;
      margin-top: 0;
      padding-top: 0; } }
  @media print, screen and (min-width: 53.125em) and (min-width: 28.125em) {
    .four-columns__right {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 53.125em) {
      .four-columns__right:last-child:not(:first-child) {
        float: right; } }

.with-sidebar {
  padding: 3.125vw;
  padding-top: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; }
  @media screen and (max-width: 53.0625em) {
    .with-sidebar {
      flex-flow: row wrap; } }
  .with-sidebar__main {
    flex: 0 1 64%;
    padding-right: 3.125vw; }
    @media screen and (max-width: 53.0625em) {
      .with-sidebar__main {
        flex: 0 1 100%; } }
  .with-sidebar__sidebar {
    flex: 0 1 34%; }
    @media screen and (max-width: 53.0625em) {
      .with-sidebar__sidebar {
        flex: 0 1 100%; } }
  .with-sidebar--shift-down {
    margin-top: 3.125rem; }

.sidebar .generic__title {
  color: rgba(51, 51, 51, 0.75);
  font-family: "Open Sans", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.875rem; }

.sidebar .teaser-aside {
  padding-top: 4.375rem; }

.one-column.expanded,
.two-columns.expanded,
.with-sidebar.expanded,
.three-columns.expanded,
.four-columns.expanded {
  max-width: none; }

.one-column.collapse,
.two-columns.collapse,
.with-sidebar.collapse,
.three-columns.collapse,
.four-columns.collapse {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .one-column.collapse::before, .one-column.collapse::after,
  .two-columns.collapse::before,
  .two-columns.collapse::after,
  .with-sidebar.collapse::before,
  .with-sidebar.collapse::after,
  .three-columns.collapse::before,
  .three-columns.collapse::after,
  .four-columns.collapse::before,
  .four-columns.collapse::after {
    display: table;
    content: ' '; }
  .one-column.collapse::after,
  .two-columns.collapse::after,
  .with-sidebar.collapse::after,
  .three-columns.collapse::after,
  .four-columns.collapse::after {
    clear: both; }

.one-column.nest,
.two-columns.nest,
.with-sidebar.nest,
.three-columns.nest,
.four-columns.nest {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 28.125em) {
    .one-column.nest,
    .two-columns.nest,
    .with-sidebar.nest,
    .three-columns.nest,
    .four-columns.nest {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  @media print, screen and (min-width: 53.125em) {
    .one-column.nest,
    .two-columns.nest,
    .with-sidebar.nest,
    .three-columns.nest,
    .four-columns.nest {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  .one-column.nest::before, .one-column.nest::after,
  .two-columns.nest::before,
  .two-columns.nest::after,
  .with-sidebar.nest::before,
  .with-sidebar.nest::after,
  .three-columns.nest::before,
  .three-columns.nest::after,
  .four-columns.nest::before,
  .four-columns.nest::after {
    display: table;
    content: ' '; }
  .one-column.nest::after,
  .two-columns.nest::after,
  .with-sidebar.nest::after,
  .three-columns.nest::after,
  .four-columns.nest::after {
    clear: both; }

.one-column.collapse.nest,
.two-columns.collapse.nest,
.with-sidebar.collapse.nest,
.three-columns.collapse.nest,
.four-columns.collapse.nest {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .one-column.collapse.nest::before, .one-column.collapse.nest::after,
  .two-columns.collapse.nest::before,
  .two-columns.collapse.nest::after,
  .with-sidebar.collapse.nest::before,
  .with-sidebar.collapse.nest::after,
  .three-columns.collapse.nest::before,
  .three-columns.collapse.nest::after,
  .four-columns.collapse.nest::before,
  .four-columns.collapse.nest::after {
    display: table;
    content: ' '; }
  .one-column.collapse.nest::after,
  .two-columns.collapse.nest::after,
  .with-sidebar.collapse.nest::after,
  .three-columns.collapse.nest::after,
  .four-columns.collapse.nest::after {
    clear: both; }

.page-section .row {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.page-section__container {
  padding-left: 1em;
  padding-right: 1em;
  width: 100%; }
  @media print, screen and (min-width: 53.125em) {
    .page-section__container {
      max-width: 75rem;
      width: 100%;
      margin: 0 auto; } }
  @media screen and (min-width: 74em) {
    .page-section__container {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }

.page-section--dark {
  color: #fff;
  background-color: #262626; }
  .page-section--dark abbr {
    color: #fff; }
  .page-section--dark a:link, .page-section--dark a:visited {
    color: #fff; }
  .page-section--dark a:hover, .page-section--dark a:active {
    color: #fdb813; }

.page-section--light {
  background-color: #f7f7f7; }

.page-section--dark {
  color: #fff;
  background-color: #262626; }
  .page-section--dark abbr {
    color: #fff; }
  .page-section--dark a:link, .page-section--dark a:visited {
    color: #fff; }
  .page-section--dark a:hover, .page-section--dark a:active {
    color: #fdb813; }

.page-section--light {
  background-color: #f7f7f7; }

.fullpage-section {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .fullpage-section .two-columns {
    padding: 0;
    align-items: center; }
    @media screen and (max-width: 74.9375em) {
      .fullpage-section .two-columns {
        align-content: flex-start; } }
    @media screen and (max-width: 41.5em) {
      .fullpage-section .two-columns {
        flex-flow: row wrap; } }
    @media screen and (max-width: 41.5em) {
      .fullpage-section .two-columns__left {
        flex: 0 1 100%;
        min-width: 0; } }
    .fullpage-section .two-columns__right {
      margin-right: 3.75vw; }
      @media screen and (max-width: 74.9375em) {
        .fullpage-section .two-columns__right {
          margin-right: 1.875vw;
          margin-left: 1.875vw; } }
      @media screen and (max-width: 41.5em) {
        .fullpage-section .two-columns__right {
          flex: 0 1 100%;
          min-width: 0; } }
  .fullpage-section__container {
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 53.0625em) {
      .fullpage-section__container {
        padding: 6.25vw 0; } }
    @media screen and (max-width: 28.0625em) {
      .fullpage-section__container {
        padding: 3.125rem 0 5rem 0; } }

.fullpage-section .teaser__headline:after {
  background-color: #fdb813; }

@media screen and (max-width: 28.0625em) {
  .fullpage-section .fast-facts {
    flex-flow: row wrap; }
    .fullpage-section .fast-facts .fast-fact {
      margin-right: 0;
      flex: 0 1 100%; } }

@media screen and (max-width: 28.0625em) {
  .fullpage-section .pullquote {
    min-width: 0;
    padding: 1.25rem;
    margin-right: 1.25rem; }
    .fullpage-section .pullquote .pullquote__icon {
      top: -30px; }
    .fullpage-section .pullquote .pullquote__quote {
      font-size: 1.25rem; } }

.call-to-action {
  background-color: #fff;
  padding: 2.25vw 1.5vw 2.5vw 2.1875vw;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .call-to-action__headline {
    font-size: 1.875vw;
    font-weight: 700;
    line-height: 2.5vw;
    color: #333333;
    padding-bottom: 0.4375vw; }

@media screen and (max-width: 53.0625em) {
  .call-to-action__headline {
    font-size: 1.25rem;
    line-height: initial; } }

.duo-block {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;
  grid-template-areas: "small1 small2" "small3 small4"; }
  .duo-block .grid .item:nth-child(1) {
    grid-area: small1; }
  .duo-block .grid .item:nth-child(2) {
    grid-area: small2; }
  .duo-block .grid .item:nth-child(3) {
    grid-area: small3; }
  .duo-block .grid .item:nth-child(4) {
    grid-area: small4; }

.quad-block {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "small1 small2" "small3 small4"; }

.block--overlay {
  background: #6d8d24; }
  .block--overlay > * {
    opacity: 0.3; }

@media screen and (max-width: 28.0625em) {
  .duo-block,
  .quad-block {
    display: block; } }

.duo-block .stat-block,
.quad-block .stat-block {
  position: relative; }
  @media screen and (min-width: 28.125em) {
    .duo-block .stat-block,
    .quad-block .stat-block {
      height: 100%;
      max-height: 100vw;
      min-height: 5rem;
      overflow: hidden; } }

.duo-block .video-block,
.quad-block .video-block {
  width: 100%;
  height: 100%;
  max-height: 100vw; }

.link-group--collapsed {
  display: none; }

@media print, screen and (min-width: 28.125em) {
  .link-group--collapsed {
    display: block; } }

.nav-main {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  background: #fff; }
  .nav-main__menu-toggle {
    color: #6d8d24;
    padding: 1rem 1.875rem;
    padding-top: 1.5rem;
    font-size: 1.0625rem;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Open Sans Condensed", sans-serif;
    cursor: pointer;
    display: none; }
    .nav-main__menu-toggle:after {
      content: "";
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(247, 247, 247, 0.5);
      z-index: -1; }
  .nav-main .link-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .nav-main .link-group a {
    color: #6d8d24;
    display: block;
    padding: 0.875rem 2rem;
    width: 100%;
    font-size: 1.0625rem;
    line-height: 1.25rem;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 700;
    text-align: left; }
    .nav-main .link-group a:hover {
      color: #fff;
      background: #6d8d24;
      transition: 0.2s background ease, 0.2s color ease; }
  .nav-main a {
    line-height: 2;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none; }
  .nav-main .input-group {
    margin-bottom: 0; }
  @media screen and (max-width: 53.0625em) {
    .nav-main {
      background: #f0f0f0;
      display: block; } }

.breadcrumbs ul {
  margin: 0;
  padding: 0; }
  .breadcrumbs ul li {
    display: inline-block; }
    .breadcrumbs ul li span.icon {
      display: inline-block; }
    .breadcrumbs ul li:last-child span.icon {
      display: none; }

.skip-nav {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: -5rem; }
  .skip-nav:focus {
    top: 0;
    transition: top 0.5s; }

.sitenav-utility {
  width: 100%;
  margin-bottom: 1.875rem;
  position: absolute;
  bottom: 0;
  left: 0; }
  @media screen and (max-width: 53.0625em) {
    .sitenav-utility {
      position: static;
      margin-bottom: 0; } }
  .sitenav-utility .link-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .sitenav-utility .link-group a {
      text-transform: none;
      font-family: "Open Sans", sans-serif;
      font-size: 0.875rem; }
      .sitenav-utility .link-group a svg {
        fill: #6d8d24; }
      .sitenav-utility .link-group a:hover svg, .sitenav-utility .link-group a:active svg {
        fill: #fff; }

.nav-secondary {
  height: 100%;
  width: 15rem;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #6d8d24;
  z-index: 1; }
  .nav-secondary:after {
    content: "";
    display: block;
    background-image: url("../images/map-background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.08;
    z-index: -1; }
    .nav-secondary:after:after {
      content: "";
      display: block;
      position: absolute;
      background: "";
      top: 0;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      z-index: -1; }
  .nav-secondary__menu-toggle {
    display: none; }
  .nav-secondary__back-level {
    margin-bottom: 1.625rem; }
    .nav-secondary__back-level a {
      position: relative;
      display: block;
      padding: 1.25rem;
      background: rgba(0, 0, 0, 0.3); }
      .nav-secondary__back-level a:link, .nav-secondary__back-level a:visited {
        color: #fff; }
      .nav-secondary__back-level a:hover, .nav-secondary__back-level a:active {
        background: rgba(0, 0, 0, 0.4); }
      .nav-secondary__back-level a svg {
        transform: none; }
  .nav-secondary__title {
    padding: 0 0 1.25rem 0;
    border-bottom: #DBF0AD 2px solid;
    margin: 0 2rem 1.25rem 2rem;
    font-size: 1.5625rem;
    line-height: 1.8125rem;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: bold;
    color: #DBF0AD; }
  .nav-secondary .link-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .nav-secondary .link-group a {
    display: block;
    padding: 0.875rem 2rem;
    width: 100%;
    text-align: left;
    text-decoration: none;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.0625rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: #fff; }
    .nav-secondary .link-group a:hover {
      color: #6d8d24;
      background: #fff;
      transition: 0.2s background ease, 0.2s color ease; }
  @media screen and (max-width: 53.0625em) {
    .nav-secondary {
      display: none;
      height: auto;
      padding-top: 0; }
      .nav-secondary:after {
        display: none; }
      .nav-secondary__back-level {
        display: none; }
      .nav-secondary__title {
        display: none; }
      .nav-secondary__menu-toggle {
        display: block;
        color: #fff;
        cursor: pointer; } }

.sitenav-mobile {
  width: 100%; }
  .sitenav-mobile__logo a {
    width: 100%; }
    .sitenav-mobile__logo a img {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 18.75rem;
      padding: 1.375rem 1.875rem; }
      @media screen and (max-width: 18.75em) {
        .sitenav-mobile__logo a img {
          max-width: 100%; } }
  .sitenav-mobile__toggles {
    display: flex; }
    .sitenav-mobile__toggles .sitenav-mobile__main-toggle, .sitenav-mobile__toggles .sitenav-mobile__secondary-toggle {
      width: 50%;
      cursor: pointer;
      padding: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Open Sans Condensed", sans-serif;
      font-weight: bold;
      font-size: 1.0625rem; }
      .sitenav-mobile__toggles .sitenav-mobile__main-toggle .close-menu, .sitenav-mobile__toggles .sitenav-mobile__secondary-toggle .close-menu {
        display: none; }
      .sitenav-mobile__toggles .sitenav-mobile__main-toggle.active .open-menu, .sitenav-mobile__toggles .sitenav-mobile__secondary-toggle.active .open-menu {
        display: none; }
      .sitenav-mobile__toggles .sitenav-mobile__main-toggle.active .close-menu, .sitenav-mobile__toggles .sitenav-mobile__secondary-toggle.active .close-menu {
        display: block; }
    .sitenav-mobile__toggles .sitenav-mobile__main-toggle {
      background: #f0f0f0;
      color: #6d8d24;
      text-transform: uppercase; }
      .sitenav-mobile__toggles .sitenav-mobile__main-toggle .icon-label svg {
        fill: #6d8d24; }
    .sitenav-mobile__toggles .sitenav-mobile__secondary-toggle {
      background: #6d8d24;
      color: #fff; }
      .sitenav-mobile__toggles .sitenav-mobile__secondary-toggle .icon-label svg {
        transform: rotate(90deg);
        fill: #fff; }

.top-nav-only .sitenav-mobile__secondary-toggle,
.title-navbar .sitenav-mobile__secondary-toggle {
  display: none; }

.top-nav-only .sitenav-mobile__main-toggle,
.title-navbar .sitenav-mobile__main-toggle {
  width: 100%; }

.sitenav-titlebar {
  width: 26.875rem;
  background: #333333;
  background: transparent linear-gradient(to bottom, rgba(51, 51, 51, 0.3) 0%, #333333 100%);
  position: relative;
  color: #fff;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center; }
  @media screen and (max-width: 74.9375em) {
    .sitenav-titlebar {
      width: 15rem;
      align-items: flex-start;
      padding: 3.125vw; } }
  @media screen and (max-width: 53.0625em) {
    .sitenav-titlebar {
      width: 100%;
      flex-flow: column wrap; } }
  .sitenav-titlebar h1, .sitenav-titlebar h2, .sitenav-titlebar h3, .sitenav-titlebar h4, .sitenav-titlebar h5, .sitenav-titlebar h6 {
    color: #fff; }
  .sitenav-titlebar a.link {
    color: #fff; }
    .sitenav-titlebar a.link:link, .sitenav-titlebar a.link:visited, .sitenav-titlebar a.link:hover, .sitenav-titlebar a.link:active {
      color: #fff; }
  .sitenav-titlebar:after {
    display: block;
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("../images/sample/sitenav-titlebar-sample.jpg") no-repeat top center;
    background-size: cover; }
  .sitenav-titlebar .sitenav-titlebar__back-level {
    padding: 0.625rem;
    position: absolute;
    top: 0;
    left: 0; }
    @media screen and (max-width: 74.9375em) {
      .sitenav-titlebar .sitenav-titlebar__back-level {
        position: static;
        padding: 0;
        margin-bottom: 1.25rem; } }
    .sitenav-titlebar .sitenav-titlebar__back-level a.link--arrow svg {
      transform: none; }
  .sitenav-titlebar .sitenav-titlebar__title {
    margin: 0 3.125vw;
    flex: 0 1 auto;
    overflow: auto; }
    @media screen and (max-width: 74.9375em) {
      .sitenav-titlebar .sitenav-titlebar__title {
        margin: 0; } }
    .sitenav-titlebar .sitenav-titlebar__title .button-group {
      justify-content: flex-start;
      margin-bottom: 0; }
      @media screen and (max-width: 74.9375em) {
        .sitenav-titlebar .sitenav-titlebar__title .button-group {
          flex-flow: row wrap; } }
      .sitenav-titlebar .sitenav-titlebar__title .button-group .button, .sitenav-titlebar .sitenav-titlebar__title .button-group .pagination span a, .pagination span .sitenav-titlebar .sitenav-titlebar__title .button-group a {
        margin-bottom: 0.9375rem; }

.button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .button-group .button, .button-group .pagination span a, .pagination span .button-group a {
    margin-right: 1.25rem; }

.link-collection {
  padding-left: 2.5vw;
  border-left: 0.0625rem solid #e3e3e3; }
  .link-collection__title {
    color: rgba(51, 51, 51, 0.75);
    font-family: "Open Sans", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.875rem;
    padding-bottom: 0.9375rem; }
  .link-collection .strip-list {
    list-style-type: none;
    margin-left: 0; }

.button-collection .button, .button-collection .pagination span a, .pagination span .button-collection a {
  margin-right: 1.25rem; }

.link-list {
  margin: 1rem 0; }
  .link-list__title {
    color: #333333;
    font-family: "Open Sans", sans-serif;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.5rem;
    text-transform: uppercase;
    padding-bottom: 1.25rem; }
  .link-list__description {
    padding-bottom: 1.25rem; }

.gallery-header {
  height: auto;
  min-height: 7.9375rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between; }
  .gallery-header__description {
    flex: 3;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    padding: 1.625rem 1.625rem 2.3125rem 4rem; }
  .gallery-header__gallery-title {
    display: block;
    font-size: 1.25rem;
    font-weight: 700;
    padding-top: 0.625rem;
    padding-left: 0.3125rem; }
    .gallery-header__gallery-title svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem; }
  .gallery-header__gallery-description {
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.5rem; }
  .gallery-header__controls {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 3.875rem;
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    color: #fdb813; }
    .gallery-header__controls a {
      text-decoration: none;
      position: relative; }
      .gallery-header__controls a:before {
        content: "";
        position: absolute;
        top: -1rem;
        left: 0;
        width: 100%;
        height: 1rem; }
      .gallery-header__controls a:after {
        content: "";
        position: absolute;
        bottom: -1rem;
        left: 0;
        width: 100%;
        height: 1rem; }
    .gallery-header__controls svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem; }
  .gallery-header__gallery-view {
    margin-right: 2.5625rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  .gallery-header__gallery-open {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }

@media screen and (max-width: 53.0625em) {
  .gallery-header {
    flex-direction: column;
    justify-content: space-around; }
    .gallery-header__description {
      padding: 1rem;
      padding-bottom: 0; }
    .gallery-header__controls {
      padding: 1rem; } }

.gallery-footer {
  height: auto;
  min-height: 7.9375rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between; }
  .gallery-footer__description {
    flex: 3;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    padding: 1.625rem 1.625rem 2.3125rem 4rem;
    display: flex;
    flex-direction: column; }
  .gallery-footer__gallery-title {
    display: block;
    font-size: 1.25rem;
    font-weight: 700;
    padding-top: 0.625rem;
    padding-left: 0.3125rem; }
    .gallery-footer__gallery-title svg {
      margin-right: 0.625rem;
      height: 1rem;
      width: 1rem; }
    .gallery-footer__gallery-title span {
      font-weight: 200;
      font-style: italic; }
  .gallery-footer__gallery-description {
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.5rem;
    min-width: 18.75rem; }
  .gallery-footer__controls {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 12.5rem;
    padding-right: 3.875rem;
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    color: #fdb813; }
    .gallery-footer__controls a {
      text-decoration: none;
      position: relative; }
      .gallery-footer__controls a:before {
        content: "";
        position: absolute;
        top: -1rem;
        left: 0;
        width: 100%;
        height: 1rem; }
      .gallery-footer__controls a:after {
        content: "";
        position: absolute;
        bottom: -1rem;
        left: 0;
        width: 100%;
        height: 1rem; }
      .gallery-footer__controls a:hover {
        background: #fdb813;
        color: #333333;
        border-color: #333333; }
        .gallery-footer__controls a:hover svg {
          color: #333333; }
    .gallery-footer__controls svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem; }
  .gallery-footer__gallery-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  .gallery-footer__gallery-open {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }

@media screen and (max-width: 53.0625em) {
  .gallery-footer {
    flex-direction: column;
    justify-content: space-around; }
    .gallery-footer__description {
      padding: 1rem;
      padding-bottom: 0; }
    .gallery-footer__controls {
      padding: 1rem; } }

.social-icon-links {
  margin-bottom: 1.875rem; }
  .social-icon-links ul {
    display: flex;
    list-style: none;
    margin: 0; }
    @media screen and (max-width: 53.0625em) {
      .social-icon-links ul {
        flex-flow: row wrap; } }
    .social-icon-links ul li {
      margin-right: 0.625rem; }
      @media screen and (max-width: 53.0625em) {
        .social-icon-links ul li {
          margin: 0 0.625rem 0.625rem 0; } }
      .social-icon-links ul li a svg {
        width: 2.5rem;
        height: 2.5rem;
        fill: #007eae;
        color: #007eae; }
      .social-icon-links ul li a:hover svg {
        fill: #fdb813;
        color: #fdb813; }

.pagination span {
  display: inline-block;
  margin-right: 0.625rem; }
  .pagination span:last-child {
    margin-right: 0; }
  .pagination span a {
    display: inline-block;
    margin-bottom: 0;
    min-height: 0; }
  .pagination span.active a {
    background: #4e641a; }
  .pagination span.disabled {
    padding: 0; }
    .pagination span.disabled a {
      background: #ededed;
      cursor: not-allowed;
      border: 0;
      color: #262626; }

.nice-video {
  position: relative;
  background-color: #000; }
  .nice-video .embedded-video,
  .nice-video .embedded-widescreen-video {
    margin-bottom: 0; }
  .nice-video button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms; }

.nice-video__preview {
  background-color: #000;
  background-image: linear-gradient(180deg, #262626 0%, #000 100%);
  background-position: 50% 50%;
  background-size: cover;
  opacity: 0;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 300ms;
  overflow: hidden; }
  .nice-video__preview img {
    width: 100%;
    height: auto; }

.nice-video__transcript {
  padding: 0.2rem 1rem;
  text-align: center; }
  .nice-video__transcript a {
    color: #fff; }

.nice-video--ready .nice-video__preview {
  z-index: 10;
  opacity: 1;
  pointer-events: auto; }

.nice-video--ready button {
  opacity: 1;
  z-index: 10;
  pointer-events: auto; }

.nice-video--playing .nice-video__preview {
  opacity: 0;
  pointer-events: none; }

.nice-video--playing button {
  opacity: 0;
  pointer-events: none; }

.search-bar {
  width: 100%; }
  .search-bar .search-bar__toggle {
    display: none;
    width: 100%; }
  .search-bar__form {
    border: 0.125rem solid #f7f4ef;
    margin: 2rem 0;
    color: #333333; }
    .search-bar__form .input-group {
      position: relative;
      margin: 0; }
    .search-bar__form input {
      border: #8f8f8f 1px solid; }
    .search-bar__form svg {
      height: 1rem;
      position: absolute;
      right: 0;
      top: 0;
      width: 1rem;
      margin: 0.8125rem;
      margin-right: 0.5rem;
      color: #6d8d24; }
  .search-bar__toggle {
    transition: width 3s; }
    .search-bar__toggle a {
      display: block;
      text-decoration: none;
      color: #6d8d24;
      font-size: 1.0625rem;
      font-family: "Open Sans Condensed", sans-serif;
      font-weight: 700;
      text-align: left;
      padding: 0.5rem 2rem; }
      .search-bar__toggle a:hover {
        color: #fff;
        background: #6d8d24;
        transition: 0.2s background ease, 0.2s color ease; }
      .search-bar__toggle a svg {
        height: 1rem;
        width: 1rem;
        margin-left: 0.3125rem;
        position: relative;
        top: 0.0625rem; }

.search-bar__toggle {
  display: none; }

.search-bar__form {
  transition: width 3s; }

.search-bar--initialized {
  display: flex;
  align-items: center; }
  .search-bar--initialized .search-bar__form {
    width: 100%; }
    .search-bar--initialized .search-bar__form .input-group {
      margin-bottom: 0; }

.search-bar--exposed .search-bar__toggle {
  width: 0; }

.search-bar--exposed .search-bar__form {
  width: 100%; }

.carousel__nav-count {
  position: absolute;
  top: 0;
  left: auto;
  right: auto;
  z-index: 999;
  display: flex;
  width: 100%;
  justify-content: center; }
  .carousel__nav-count button.slick-prev,
  .carousel__nav-count button.slick-next {
    position: static;
    transform: none;
    width: 3.75rem; }
    .carousel__nav-count button.slick-prev.slick-disabled,
    .carousel__nav-count button.slick-next.slick-disabled {
      visibility: visible; }
    .carousel__nav-count button.slick-prev:focus,
    .carousel__nav-count button.slick-next:focus {
      background: #fdb813; }
    .carousel__nav-count button.slick-prev:hover,
    .carousel__nav-count button.slick-next:hover {
      background: #fdc02c; }
    .carousel__nav-count button.slick-prev svg,
    .carousel__nav-count button.slick-next svg {
      color: #000; }
  .carousel__nav-count .carousel__slide-count {
    background: #fdb813;
    padding: 0 3.125rem 0 3.125rem;
    line-height: 3.75rem; }

.carousel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden; }
  .carousel__item {
    background-position: center center;
    background-size: cover;
    height: 100%; }
  .carousel__slide-count {
    display: block; }
  .carousel .slick-disabled {
    display: block;
    visibility: visible;
    cursor: default; }
    .carousel .slick-disabled svg {
      visibility: hidden; }
    .carousel .slick-disabled:hover {
      background: initial; }
  .carousel .slick-list {
    height: 100%; }
    .carousel .slick-list .slick-track {
      height: 100%; }

.accordion {
  background: #fff;
  padding: 2.8125rem 0;
  /* Accordion styles */
  /* :checked */ }
  .accordion__tab {
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    color: #fff;
    overflow: hidden; }
  .accordion__tab-content {
    max-height: 0;
    overflow: hidden;
    background: #fff;
    transition: max-height .35s;
    color: #333333; }
  .accordion__tab-content-inner {
    padding: 1.875vw; }
  .accordion input {
    position: absolute;
    opacity: 0;
    z-index: -1; }
  .accordion label {
    width: 20rem;
    position: relative;
    display: block;
    padding-left: 1em;
    font-weight: bold;
    line-height: 3;
    cursor: pointer;
    color: #fff;
    width: 100%;
    color: #007eae;
    background: rgba(0, 126, 174, 0.2);
    margin-left: 0 !important; }
    .accordion label span {
      margin-right: 1rem; }
      .accordion label span svg {
        display: inline-block;
        width: 1rem;
        height: 1rem; }
    .accordion label .close {
      display: none; }
      .accordion label .close svg {
        transform: rotate(180deg); }
  .accordion .tab-content p {
    margin: 1em; }
  .accordion input:checked ~ .accordion__tab-content {
    max-height: 50em;
    overflow: scroll; }
  .accordion input:checked ~ label {
    color: #fff;
    background: #007eae; }
  .accordion input:checked ~ label > .open {
    display: none; }
  .accordion input:checked ~ label > .close {
    display: inline; }

.gallery-item {
  flex: 1;
  min-width: 20rem;
  font-family: "Open Sans", sans-serif;
  margin: 0.8125vw;
  margin-bottom: 2.75rem;
  cursor: pointer; }
  .gallery-item__link {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #fdb813;
    font-style: italic;
    padding-top: 0.625rem; }
    .gallery-item__link .link {
      color: #fdb813;
      font-weight: 300; }
  .gallery-item img {
    border: 0.125rem solid transparent; }
  .gallery-item:hover img {
    border: 0.125rem solid #fdb813; }
  .gallery-item:hover a {
    text-decoration: underline; }

.active-slide img {
  border: 0.125rem solid #fdb813; }

.active-slide a {
  text-decoration: underline; }

.ambient-video {
  position: relative;
  background: #757575;
  overflow: hidden; }
  .ambient-video video,
  .ambient-video .ambient-video__cover-image {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1; }
    @media (min-aspect-ratio: 16 / 9) {
      .ambient-video video,
      .ambient-video .ambient-video__cover-image {
        height: 300%;
        top: -100%; } }
    @media (max-aspect-ratio: 16 / 9) {
      .ambient-video video,
      .ambient-video .ambient-video__cover-image {
        width: 300%;
        left: -100%; } }
    @supports (object-fit: cover) {
      .ambient-video video,
      .ambient-video .ambient-video__cover-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .ambient-video .ambient-video__cover-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  .ambient-video__content-overlay {
    position: relative;
    z-index: 2;
    background: rgba(0, 0, 0, 0.6);
    padding: 6.25rem 6.25rem 3.125rem 6.25rem; }
    @media screen and (max-width: 74.9375em) {
      .ambient-video__content-overlay {
        padding: 1.25rem 2.5rem; } }
    @media screen and (max-width: 21.875em) {
      .ambient-video__content-overlay {
        padding: 1.25rem; } }
    .ambient-video__content-overlay:after {
      content: "";
      display: block;
      border-bottom: 31.25rem solid transparent;
      border-left: 31.25rem solid rgba(109, 141, 36, 0.5);
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }
  .ambient-video__headline {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.125rem; }
    @media screen and (max-width: 74.9375em) {
      .ambient-video__headline {
        flex-flow: row wrap; } }
    .ambient-video__headline .ambient-video__launch {
      order: 2;
      width: 30%; }
      @media screen and (max-width: 74.9375em) {
        .ambient-video__headline .ambient-video__launch {
          width: 100%;
          order: 1;
          text-align: center; } }
      .ambient-video__headline .ambient-video__launch button {
        border: 0;
        font-family: "Open Sans", sans-serif;
        font-weight: normal;
        padding: 0;
        cursor: pointer;
        display: block;
        text-decoration: none;
        text-align: center;
        color: #fff;
        font-size: 1.875rem;
        line-height: 2.125rem; }
        .ambient-video__headline .ambient-video__launch button:hover, .ambient-video__headline .ambient-video__launch button:active, .ambient-video__headline .ambient-video__launch button:focus {
          background: transparent;
          outline: 0; }
        @media screen and (max-width: 74.9375em) {
          .ambient-video__headline .ambient-video__launch button {
            display: inline-flex;
            flex-flow: row wrap;
            font-size: 1.25rem;
            justify-content: center;
            align-items: center;
            padding: 1.25rem 0 2.5rem 0; } }
        @media screen and (max-width: 74.9375em) {
          .ambient-video__headline .ambient-video__launch button em,
          .ambient-video__headline .ambient-video__launch button .watch {
            margin-left: 0.25rem; } }
        .ambient-video__headline .ambient-video__launch button em {
          font-style: normal;
          font-weight: bold;
          color: #fdb813; }
        .ambient-video__headline .ambient-video__launch button .watch {
          color: #fdb813;
          font-family: "Open Sans Condensed", sans-serif;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1.125rem;
          display: block;
          margin-top: 0.625rem; }
          @media screen and (max-width: 74.9375em) {
            .ambient-video__headline .ambient-video__launch button .watch {
              display: inline-flex;
              margin-top: 0;
              align-items: center; } }
          .ambient-video__headline .ambient-video__launch button .watch svg {
            display: block;
            fill: #fdb813;
            margin: 0.3125rem auto;
            width: 3.125rem;
            height: 3.125rem; }
            @media screen and (max-width: 74.9375em) {
              .ambient-video__headline .ambient-video__launch button .watch svg {
                width: 1.25rem;
                height: 1.25rem;
                display: inline-block;
                margin: 0 0.25rem 0 0; } }
    .ambient-video__headline h1 {
      order: 1;
      width: 70%;
      font-size: 2.5rem;
      line-height: 2.75rem;
      color: #fff;
      font-weight: bold;
      padding-right: 2.5rem; }
      @media screen and (max-width: 74.9375em) {
        .ambient-video__headline h1 {
          width: 100%;
          order: 2; } }
      @media screen and (max-width: 53.0625em) {
        .ambient-video__headline h1 {
          text-align: center;
          padding-right: 0; } }
      @media screen and (max-width: 28.0625em) {
        .ambient-video__headline h1 {
          font-size: 1.875rem;
          line-height: 2.125rem; } }
      .ambient-video__headline h1 em {
        font-style: italic;
        color: #fdb813; }
  .ambient-video .rich-link-list ul li a {
    color: #fdb813; }
    .ambient-video .rich-link-list ul li a .superhead {
      color: #fff; }
    .ambient-video .rich-link-list ul li a span.heading {
      background: transparent linear-gradient(to bottom, transparent 50%, #fdb813 50%);
      text-decoration: none;
      background-size: 100% 200%;
      background-position: 0 4%;
      transition: all 0.1s ease-in-out; }
      .ambient-video .rich-link-list ul li a span.heading:hover {
        background-position: 0 12%; }
  .ambient-video .grid--feature {
    margin-right: -6.25rem; }
    @media screen and (max-width: 74.9375em) {
      .ambient-video .grid--feature {
        margin-right: 0; } }

.fast-fact {
  background: #6d8d24;
  color: #fff;
  padding: 7.0625vw 1.6875vw 1.9375vw 1.75vw; }
  .fast-fact__stat {
    font-family: "Open Sans", sans-serif;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.5rem;
    color: #fff; }
  .fast-fact__description {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.625rem; }

table {
  border-collapse: collapse;
  border: 0; }
  table thead {
    background: #6d8d24;
    color: #fff; }
  table tbody tr,
  table tbody tr:nth-child(even) {
    background: #fff;
    border-bottom: #ededed 3px solid; }
  table tbody th {
    text-align: left; }
  table thead,
  table tbody,
  table tfoot {
    border: 0; }
    table thead th,
    table thead td,
    table tbody th,
    table tbody td,
    table tfoot th,
    table tfoot td {
      padding: 0.9375vw 1.875vw; }
  table.table--zebra tbody tr:nth-child(even) {
    background: #f7f4ef; }

@media screen and (max-width: 74.9375em) {
  .table-responsive-wrap {
    overflow-x: auto; }
    .table-responsive-wrap table {
      display: block; }
      .table-responsive-wrap table thead,
      .table-responsive-wrap table tbody,
      .table-responsive-wrap table tfoot {
        display: block; }
        .table-responsive-wrap table thead tr,
        .table-responsive-wrap table thead td,
        .table-responsive-wrap table thead th,
        .table-responsive-wrap table tbody tr,
        .table-responsive-wrap table tbody td,
        .table-responsive-wrap table tbody th,
        .table-responsive-wrap table tfoot tr,
        .table-responsive-wrap table tfoot td,
        .table-responsive-wrap table tfoot th {
          display: block;
          padding-left: 0;
          padding-right: 0; }
      .table-responsive-wrap table thead {
        display: none; } }

.featured-article {
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between; }
  .featured-article__image {
    position: relative;
    margin: 0 4.375rem; }
    .featured-article__image:before {
      content: "";
      display: block;
      border-left: 17.8125rem solid transparent;
      border-bottom: 17.8125rem solid #ededed;
      position: absolute;
      bottom: -2.5625rem;
      right: -5rem;
      z-index: -1; }
  .featured-article .featured-article__image {
    flex: 0 1 48%; }
  .featured-article .generic {
    flex: 0 1 48%; }
  .featured-article .generic__title {
    text-transform: uppercase; }
  .featured-article-secondary .article-teaser__image {
    margin-right: 3.75vw;
    min-width: 15rem; }
  .featured-article-secondary .article-teaser__container {
    padding-top: 2.5rem !important;
    padding-right: 5vw !important; }
  .featured-article .featured-article__image {
    margin: 1rem 4.375rem; }

.article-teaser {
  padding: 0;
  color: #333333;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .article-teaser:first-child {
    padding-top: 1.875rem; }
  .article-teaser__container {
    flex: 1;
    padding-top: 3.125rem;
    min-width: 20rem;
    position: relative; }
    .article-teaser__container:before {
      visibility: hidden; }
  .article-teaser__image {
    flex: 1;
    height: 20.75rem;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: initial; }
    .article-teaser__image--triangle:after {
      content: "";
      display: block;
      border-left: 36.875rem solid transparent;
      border-top: 20.75rem solid #ededed;
      position: absolute;
      bottom: -6.375rem;
      right: -1.9375rem;
      z-index: -1; }
      @media screen and (max-width: 28.0625em) {
        .article-teaser__image--triangle:after {
          right: -1.5625rem; } }
  .article-teaser__secondary-image {
    position: relative;
    width: 14.6875vw;
    height: 100%;
    min-width: 12.5rem;
    margin-top: 1rem;
    overflow: hidden; }
    .article-teaser__secondary-image--no-image {
      display: none; }
    .article-teaser__secondary-image--circle {
      border-radius: 50%; }
  .article-teaser__tag {
    color: #333333;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-transform: uppercase;
    padding: 0.625rem 0; }
  .article-teaser__title {
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.5625rem;
    font-weight: 700;
    line-height: 2.1875rem;
    position: relative;
    padding-bottom: 0.625rem; }
    .article-teaser__title:before {
      content: "";
      display: block;
      height: 0.125rem;
      background: #fdb813;
      position: absolute;
      left: -3vw;
      right: initial;
      bottom: initial;
      top: 45%;
      transform: initial;
      width: 5vw; }
  .article-teaser__description {
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    line-height: 1.625rem; }
  .article-teaser__date {
    font-weight: 700;
    color: #757575; }
    .article-teaser__date:after {
      content: " | "; }
  .article-teaser__button {
    margin-top: 1.25rem; }
  @media screen and (max-width: 28.0625em) {
    .article-teaser {
      flex-direction: column; }
      .article-teaser__container:before {
        content: "";
        display: block;
        height: 0.125rem;
        background: #fdb813;
        position: absolute;
        left: 1vw;
        right: initial;
        bottom: initial;
        top: -0.25rem;
        transform: rotate(90deg);
        width: 5rem; }
      .article-teaser__image:before {
        content: "";
        display: block;
        height: 0.125rem;
        background: #fdb813;
        position: absolute;
        left: -1.375rem;
        right: initial;
        bottom: 0;
        top: initial;
        transform: rotate(90deg);
        width: 5rem; }
      .article-teaser__title:before {
        display: none; } }

.article-teaser--secondary {
  flex-direction: row-reverse;
  overflow: hidden; }
  .article-teaser--secondary .article-teaser__image {
    margin-left: 0;
    margin-right: 0; }
  .article-teaser--secondary .article-teaser__title:before {
    content: "";
    display: block;
    height: 0.125rem;
    background: #fdb813;
    position: absolute;
    left: initial;
    right: -2rem;
    bottom: initial;
    top: 45%;
    transform: initial;
    width: 5vw; }
  @media screen and (max-width: 28.0625em) {
    .article-teaser--secondary {
      flex-direction: column; }
      .article-teaser--secondary .article-teaser__title:before {
        visibility: hidden;
        display: none; } }

.article-teaser--featured .article-teaser__image {
  height: 36.875rem; }

.article-teaser--featured .article-teaser__title {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.8125rem; }

.article-teaser--has-image .article-teaser__container {
  padding: 3.125rem 0 1rem 0; }

.article-teaser--has-image .article-teaser__tag {
  padding: 0 3.4375vw; }

.article-teaser--has-image .article-teaser__title {
  padding: 0 3.4375vw; }

.article-teaser--has-image .article-teaser__description {
  padding: 0 3.4375vw; }

.article-teaser--has-image .article-teaser__button {
  padding: 0 3.4375vw; }

.article-teaser--invert-cols .article-teaser__container {
  order: 1; }
  .article-teaser--invert-cols .article-teaser__container .article-teaser__title:before {
    right: -3vw;
    left: initial; }

.article-teaser--invert-cols .article-teaser__image {
  order: 2; }

.article-teaser--airy {
  padding-top: 0; }
  .article-teaser--airy:first-child {
    padding-top: 0; }
  .article-teaser--airy .article-teaser__container {
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-content: center; }
    .article-teaser--airy .article-teaser__container > * {
      width: 100%; }
    @media screen and (max-width: 53.0625em) {
      .article-teaser--airy .article-teaser__container {
        padding: 1.875rem 0; } }
  @media screen and (min-width: 53.125em) {
    .article-teaser--airy .article-teaser__image {
      min-height: 25rem; } }
  @media screen and (max-width: 53.0625em) {
    .article-teaser--airy .article-teaser__image:before {
      display: none; } }

.article-teaser--stack {
  flex-flow: row wrap; }
  .article-teaser--stack .article-teaser__container,
  .article-teaser--stack .article-teaser__image {
    width: 100%;
    flex: 0 1 100%; }
  @media screen and (min-width: 53.125em) {
    .article-teaser--stack .article-teaser__image {
      margin-left: -3.125vw; } }
  .article-teaser--stack .article-teaser__container .article-teaser__title,
  .article-teaser--stack .article-teaser__container .article-teaser__description,
  .article-teaser--stack .article-teaser__container .article-teaser__button,
  .article-teaser--stack .article-teaser__container .article-teaser__tag {
    padding-left: 0; }
  .article-teaser--stack .article-teaser__container .article-teaser__title:before {
    width: 0.125rem;
    height: 5vw;
    top: -8vw;
    left: 0;
    right: auto;
    bottom: auto; }
  @media screen and (max-width: 74.9375em) {
    .article-teaser--stack.article-teaser--triangle .article-teaser__image:after {
      display: none; } }

.profile-teaser {
  display: flex;
  flex-direction: row; }
  .profile-teaser__image {
    max-width: 14.375vw; }
    .profile-teaser__image img {
      border-radius: 50%; }
  .profile-teaser__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 2.5rem;
    padding-top: 1.5625rem;
    padding-right: 0.5rem;
    min-width: 18.75rem;
    min-width: 14.375rem; }
  .profile-teaser__tag {
    color: #333333;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-transform: uppercase;
    padding-bottom: 0.1875rem; }
  .profile-teaser__link {
    font-size: 1.5625rem;
    font-family: "Open Sans Condensed", sans-serif;
    position: relative;
    line-height: 1.875rem;
    padding-bottom: 1.25rem; }
    .profile-teaser__link:before {
      content: "";
      display: block;
      height: 0.125rem;
      background: #fdb813;
      position: absolute;
      left: -3.6875rem;
      right: initial;
      bottom: initial;
      top: 47%;
      transform: initial;
      width: 5rem;
      width: 2.5rem;
      height: 0.125rem; }
  @media screen and (max-width: 28.0625em) {
    .profile-teaser {
      flex-direction: column; } }

.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  background: #c41230;
  min-height: 4.625rem;
  padding-left: 1.875vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  line-height: 1.25rem;
  color: #fff; }
  .alert__icon {
    margin-top: 0.0625rem; }
    .alert__icon svg {
      display: inline-flex;
      height: 1rem;
      width: 1rem; }
  .alert__info {
    padding-left: 0.9375rem; }
  .alert__link {
    text-decoration: underline;
    padding-left: 0.25rem; }

.alert--secondary {
  background: #fdb813;
  color: #333333; }

.alert--tertiary {
  background: #007eae;
  color: #fff; }

.grid-block {
  padding: 2.5rem;
  position: relative;
  background: #fff;
  align-items: flex-end;
  align-content: flex-end; }
  .grid-block a {
    color: #007eae; }
    .grid-block a.link--arrow {
      display: inline-block;
      margin-left: 1.5625rem;
      text-indent: -1.5625rem; }
      .grid-block a.link--arrow svg {
        display: inline-block;
        margin-right: 3px;
        position: relative;
        top: 3px; }
      .grid-block a.link--arrow span {
        background: transparent linear-gradient(to bottom, transparent 50%, #007eae 50%);
        text-decoration: none;
        background-size: 100% 200%;
        background-position: 0 4%;
        transition: all 0.1s ease-in-out; }
        .grid-block a.link--arrow span:hover {
          background-position: 0 12%; }
  .grid-block--dark {
    background: #333333;
    color: #fff; }
    .grid-block--dark a {
      color: #fdb813; }
      .grid-block--dark a.link--arrow span {
        background: transparent linear-gradient(to bottom, transparent 50%, #fdb813 50%);
        text-decoration: none;
        background-size: 100% 200%;
        background-position: 0 4%;
        transition: all 0.1s ease-in-out; }
        .grid-block--dark a.link--arrow span:hover {
          background-position: 0 12%; }
    .grid-block--dark h1, .grid-block--dark h2, .grid-block--dark h3, .grid-block--dark h4, .grid-block--dark h5, .grid-block--dark h6 {
      color: #fff; }
  .grid-block--highlight {
    background: #fdb813; }
    .grid-block--highlight.grid-block--dark {
      color: #262626;
      background: #F3901D; }
    .grid-block--highlight a {
      color: #262626; }
      .grid-block--highlight a.link--arrow span {
        background: transparent linear-gradient(to bottom, transparent 50%, #262626 50%);
        text-decoration: none;
        background-size: 100% 200%;
        background-position: 0 4%;
        transition: all 0.1s ease-in-out; }
        .grid-block--highlight a.link--arrow span:hover {
          background-position: 0 12%; }
  .grid-block--overlay:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(109, 141, 36, 0.7);
    z-index: 1; }
  .grid-block--overlay.grid-block--dark:after {
    background: rgba(51, 51, 51, 0.7); }
  .grid-block--overlay:not(.grid-block--dark) .grid-block__image, .grid-block--overlay:not(.grid-block--dark) .grid-block__video-cover {
    filter: gray;
    filter: grayscale(100%);
    filter: url(/images/desaturate.svg#greyscale); }
  .grid-block .grid-block__image,
  .grid-block .grid-block__video-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }
  .grid-block--vertical-center {
    align-content: center; }
  .grid-block .profile {
    margin-bottom: 0;
    font-size: 1.125rem; }
    .grid-block .profile .tag {
      display: block;
      text-transform: uppercase;
      font-size: 1rem;
      font-family: "Open Sans Condensed", sans-serif;
      font-weight: bold; }

.grid-block--video > *,
.grid-block--image > *,
.grid-block--overlay > * {
  position: relative;
  z-index: 2; }

a.grid-block {
  display: block;
  color: #262626;
  text-decoration: none;
  transition: background-color 0.3s; }
  a.grid-block:hover {
    background: rgba(0, 0, 0, 0.1); }
    a.grid-block:hover--highlight {
      background: #db9b02; }
  a.grid-block--dark {
    color: #fff; }
    a.grid-block--dark:hover {
      background: #000; }
  a.grid-block--highlight:hover {
    background: #db9b02; }
  a.grid-block--highlight.grid-block--dark:hover {
    background: #d2760b; }

.grid-block .headline-group .superhead,
.grid-block .headline-group .subhead {
  font-size: 1rem;
  line-height: 1.5rem; }
  .grid-block .headline-group .superhead:after,
  .grid-block .headline-group .subhead:after {
    display: none; }

.grid-block .headline-group .head {
  font-size: 1.75rem;
  line-height: 2.125rem; }

.grid-block .headline-group .superhead {
  margin-bottom: 0.3125rem; }

.grid-block .headline-group .subhead {
  margin-top: 0.3125rem; }

.grid-block--video {
  padding: 0;
  align-items: stretch;
  justify-content: stretch; }
  .grid-block--video .grid-block__play-button {
    width: 100%;
    height: 100%; }
    .grid-block--video .grid-block__play-button button.button {
      background: rgba(109, 141, 36, 0.7);
      margin: 0;
      width: 100%;
      height: 100%; }
      .grid-block--video .grid-block__play-button button.button svg {
        max-width: 3.125rem;
        max-height: 3.125rem; }
      .grid-block--video .grid-block__play-button button.button:hover, .grid-block--video .grid-block__play-button button.button:active, .grid-block--video .grid-block__play-button button.button:focus {
        background: rgba(78, 100, 26, 0.7); }
        .grid-block--video .grid-block__play-button button.button:hover svg, .grid-block--video .grid-block__play-button button.button:active svg, .grid-block--video .grid-block__play-button button.button:focus svg {
          color: #fff; }

.main-header {
  height: 100%;
  display: flex; }
  .main-header__sitenav-main {
    display: block;
    width: 11.25rem;
    background: #fff;
    position: relative;
    z-index: 999; }
    .main-header__sitenav-main .main-header__sitenav-logo {
      text-align: center; }
      .main-header__sitenav-main .main-header__sitenav-logo a {
        display: inline-block; }
        .main-header__sitenav-main .main-header__sitenav-logo a img {
          width: 7.5rem;
          margin: 0 auto;
          display: block;
          padding: 2rem 0 3rem 0; }
  .main-header__sitenav-mobile {
    width: 100%;
    display: none; }

.main-header__sitenav-util {
  color: #fff;
  background-color: #fdb813;
  text-align: right;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .main-header__sitenav-util abbr {
    color: #fff; }
  .main-header__sitenav-util a:link, .main-header__sitenav-util a:visited {
    color: #fff; }
  .main-header__sitenav-util a:hover, .main-header__sitenav-util a:active {
    color: #fdb813; }

.main-header__brand {
  padding: 1rem 0; }
  .main-header__brand img {
    max-width: 100%;
    height: auto; }

@media screen and (max-width: 53.0625em) {
  .main-header {
    flex-flow: row wrap; }
    .main-header__sitenav-mobile {
      display: block;
      background: #fff;
      width: 100%; }
    .main-header__sitenav-main .main-header__sitenav-logo {
      display: none; } }

@media screen and (max-width: 53.0625em) {
  .main__nav .main-header__sitenav-main,
  .main__nav .nav-secondary {
    width: 100%;
    display: none; } }

.main-header__sitenav-main.short-viewport {
  overflow: auto; }
  .main-header__sitenav-main.short-viewport .sitenav-utility {
    position: static; }

.main-footer {
  color: #fff;
  background-color: #262626; }
  .main-footer abbr {
    color: #fff; }
  .main-footer a:link, .main-footer a:visited {
    color: #fff; }
  .main-footer a:hover, .main-footer a:active {
    color: #fdb813; }
  .main-footer .row__col {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.main-footer__ribbon {
  background-color: #000;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.page-header {
  background-color: #fff; }
  .page-header__page-title {
    color: #333333;
    padding: 3.9375rem 3.125rem 2.5vw 3.125vw; }
    .page-header__page-title a {
      font-size: 0.875rem; }
    .page-header__page-title .one-column {
      max-width: none; }
    .page-header__page-title--secondary {
      padding: 3.5vw 18.75vw 0 3.4375vw; }
      .page-header__page-title--secondary .introductory {
        padding: 0; }
  .page-header__hero {
    color: #333333;
    margin-bottom: 2.5rem; }

.cta-bar {
  background: #333333;
  color: #fff;
  padding: 3.125rem;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 74.9375em) {
    .cta-bar {
      flex-flow: row wrap; } }
  @media screen and (max-width: 53.0625em) {
    .cta-bar {
      padding: 1.875rem; } }
  .cta-bar h1, .cta-bar h2, .cta-bar h3, .cta-bar h4, .cta-bar h5, .cta-bar h6 {
    color: #fff; }
  .cta-bar a:link, .cta-bar a:visited {
    color: #fdb813;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: flex-start; }
    .cta-bar a:link svg, .cta-bar a:visited svg {
      fill: #fdb813; }
  .cta-bar a:hover, .cta-bar a:active, .cta-bar a:focus {
    color: #F3901D; }
    .cta-bar a:hover svg, .cta-bar a:active svg, .cta-bar a:focus svg {
      fill: #F3901D; }
  .cta-bar h3 {
    font-size: 1.25rem;
    margin-bottom: 1.25rem; }
    @media screen and (max-width: 74.9375em) {
      .cta-bar h3 {
        margin-bottom: 1.25rem; } }
  .cta-bar h4 {
    color: #f7f7f7;
    font-size: 1rem;
    line-height: 1.625rem;
    display: block;
    font-style: normal; }
    .cta-bar h4 span {
      display: block; }
  .cta-bar ul {
    list-style: none;
    margin: 0 0 1.25rem 0;
    padding: 0;
    display: flex; }
    .cta-bar ul li {
      margin: 0 2.5rem 0 0;
      padding: 0; }
  .cta-bar .col {
    width: 48%; }
    @media screen and (max-width: 74.9375em) {
      .cta-bar .col {
        width: 100%; } }
  @media screen and (max-width: 53.0625em) {
    .cta-bar .social ul {
      flex-flow: row wrap; } }
  @media screen and (max-width: 53.0625em) {
    .cta-bar .social ul li {
      margin: 0 0.625rem 0.625rem 0; } }
  .cta-bar .social ul li a svg {
    width: 2.5rem;
    height: 2.5rem; }
  @media screen and (max-width: 74.9375em) {
    .cta-bar .learn-more, .cta-bar .locate {
      margin-bottom: 2.5rem; } }
  .cta-bar .learn-more ul, .cta-bar .locate ul {
    flex-direction: column; }
    @media screen and (max-width: 53.0625em) {
      .cta-bar .learn-more ul, .cta-bar .locate ul {
        flex-flow: column wrap; } }
    @media screen and (max-width: 53.0625em) {
      .cta-bar .learn-more ul li, .cta-bar .locate ul li {
        margin: 0 0 1.25rem 0; } }
    .cta-bar .learn-more ul li a, .cta-bar .locate ul li a {
      text-decoration: underline;
      display: inline; }
      .cta-bar .learn-more ul li a svg, .cta-bar .locate ul li a svg {
        margin-top: 0.1875rem;
        margin-right: 0.625rem;
        width: 1.25rem;
        height: 1.25rem; }
  .cta-bar .locate h3 {
    margin-bottom: 0; }

.profile-headshot-stats {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  .profile-headshot-stats .col {
    width: 48%; }
    @media screen and (max-width: 68.75em) {
      .profile-headshot-stats .col {
        width: 100%; } }
    @media screen and (max-width: 53.0625em) {
      .profile-headshot-stats .col {
        width: 48%; } }
    @media screen and (max-width: 31.25em) {
      .profile-headshot-stats .col {
        width: 100%; } }
    .profile-headshot-stats .col img {
      margin-bottom: 1.875rem; }
  .profile-headshot-stats .pullquote-secondary:before {
    display: none; }

@media screen and (max-width: 93.75em) {
  .with-sidebar .profile-headshot-stats .col {
    width: 100%; } }

.gallery {
  position: relative;
  height: 100%;
  z-index: 0; }
  .gallery__items {
    display: flex;
    flex-wrap: wrap; }
  .gallery__button {
    position: absolute;
    top: 0;
    display: block;
    margin: 0 auto;
    z-index: 9;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  .gallery__item {
    flex: 1;
    min-width: 250px; }
  .gallery .gallery--view-all {
    display: none;
    z-index: 999; }

.gallery--view-all {
  flex-wrap: wrap;
  background-size: cover;
  background-position: center center;
  height: auto;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .gallery--view-all .gallery-header__gallery-view {
    display: none; }
  .gallery--view-all .gallery-header__gallery-open {
    display: none; }
  .gallery--view-all .gallery-header__description {
    padding-left: 2.6875vw; }
  .gallery--view-all .gallery__items {
    display: flex;
    background: rgba(0, 0, 0, 0.7);
    padding: 0 1.875vw; }

.callout {
  background-image: url("https://images.unsplash.com/photo-1465447142348-e9952c393450?dpr=2&auto=format&fit=crop&w=1500&h=1124&q=80&cs=tinysrgb&crop=&bg=");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 0;
  border: 0;
  padding: 3.75vw 0;
  margin: 1rem 0; }
  .callout:after {
    content: "";
    display: block;
    position: absolute;
    background: rgba(0, 126, 174, 0.8);
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    z-index: -1; }
  .callout--secondary {
    background-image: url("https://images.unsplash.com/photo-1465447142348-e9952c393450?dpr=2&auto=format&fit=crop&w=1500&h=1124&q=80&cs=tinysrgb&crop=&bg=");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 0; }
    .callout--secondary:after {
      content: "";
      display: block;
      position: absolute;
      background: rgba(109, 141, 36, 0.8);
      top: 0;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      z-index: -1; }
  .callout__container {
    padding: 4.25vw; }
  .callout .link {
    color: #fdb813; }
  .callout .two-columns__left {
    padding-right: 4.375vw; }
  .callout .profile-teaser {
    margin-bottom: 1.875rem; }
  .callout__social-row {
    display: flex; }
    .callout__social-row a {
      margin-right: 1.5625rem;
      color: #fff; }
    .callout__social-row svg {
      display: block;
      height: 2.5rem;
      width: 2.5rem; }
  .callout .with-sidebar {
    justify-content: space-between; }
    .callout .with-sidebar .with-sidebar__main {
      flex: 0 1 40%;
      padding: 0; }
      @media screen and (max-width: 82.8125em) {
        .callout .with-sidebar .with-sidebar__main {
          flex: 0 1 100%; } }
    .callout .with-sidebar .with-sidebar__sidebar {
      flex: 0 1 58%; }
      @media screen and (max-width: 82.8125em) {
        .callout .with-sidebar .with-sidebar__sidebar {
          flex: 0 1 100%; } }

.callout-dark, .callout .with-sidebar {
  color: #fff; }
  .callout-dark a:not(.button):not(.pagination span a), .callout .with-sidebar a:not(.button):not(.pagination span a) {
    color: #fdb813; }
    .callout-dark a:not(.button):not(.pagination span a):link, .callout .with-sidebar a:not(.button):not(.pagination span a):link, .callout-dark a:not(.button):not(.pagination span a):visited, .callout .with-sidebar a:not(.button):not(.pagination span a):visited {
      color: #fdb813; }
    .callout-dark a:not(.button):not(.pagination span a):hover, .callout .with-sidebar a:not(.button):not(.pagination span a):hover, .callout-dark a:not(.button):not(.pagination span a):active, .callout .with-sidebar a:not(.button):not(.pagination span a):active {
      color: #db9b02; }
  .callout-dark h1, .callout .with-sidebar h1, .callout-dark h2, .callout .with-sidebar h2, .callout-dark h3, .callout .with-sidebar h3, .callout-dark h4, .callout .with-sidebar h4, .callout-dark h5, .callout .with-sidebar h5, .callout-dark h6, .callout .with-sidebar h6 {
    color: #fff; }
  .callout-dark .profile-teaser__tag, .callout .with-sidebar .profile-teaser__tag {
    color: #fff; }

.captioned-media--triangle {
  padding: 0 0 2rem 3.125vw;
  position: relative; }
  .captioned-media--triangle:before {
    content: "";
    display: block;
    border-bottom: 17.8125rem solid transparent;
    border-left: 17.8125rem solid #ededed;
    position: absolute;
    left: 0;
    top: -2.375vw;
    z-index: -1; }

.teaser-aside--dark {
  color: #fff;
  background-color: #000;
  padding: 1rem; }
  .teaser-aside--dark abbr {
    color: #fff; }
  .teaser-aside--dark a:link, .teaser-aside--dark a:visited {
    color: #fff; }
  .teaser-aside--dark a:hover, .teaser-aside--dark a:active {
    color: #fdb813; }

.teaser {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #333333;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3.75vw; }
  @media screen and (min-width: 28.125em) {
    .teaser {
      min-width: 20rem; } }
  .teaser__headline {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.125rem;
    color: #fff;
    padding-bottom: 1.5625rem;
    margin-bottom: 1.5625rem; }
    @media screen and (max-width: 28.0625em) {
      .teaser__headline {
        font-size: 2.125rem; } }
    .teaser__headline:after {
      content: "";
      display: block;
      height: 0.125rem;
      width: 6.25rem;
      background: #6d8d24;
      margin: 0 auto;
      position: relative;
      bottom: -1.5625rem; }
  .teaser__body {
    max-width: 33.125rem;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    line-height: 1.625rem; }
  .teaser__links {
    margin-bottom: 1.25rem; }
  .teaser__buttons {
    margin-top: 3.75rem; }
    @media screen and (max-width: 28.0625em) {
      .teaser__buttons {
        margin-top: 1.875rem; }
        .teaser__buttons .button-group {
          flex-flow: column wrap; }
          .teaser__buttons .button-group .button, .teaser__buttons .button-group .pagination span a, .pagination span .teaser__buttons .button-group a {
            margin-bottom: 1.25rem;
            margin-right: 0; } }

.teaser__image img {
  width: 100%;
  height: auto; }

@media print, screen and (min-width: 28.125em) {
  .teaser-full-row .teaser {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .teaser-full-row .teaser .teaser__image {
      width: calc(50% - 0.5rem);
      flex: 0 0 auto; }
    .teaser-full-row .teaser .teaser__body {
      width: calc(50% - 0.5rem);
      flex: 0 0 auto; }
  .teaser-full-row.teaser-full-row--image-right .teaser {
    flex-direction: row-reverse; } }

.fast-facts {
  display: flex;
  flex-direction: row; }
  .fast-facts .fast-fact {
    margin-top: 0.625rem;
    margin-right: 0.625rem;
    flex: 1; }
    .fast-facts .fast-fact:last-child {
      margin-right: 0; }
  .fast-facts .stat-block {
    margin-top: 0.625rem;
    margin-right: 0.625rem;
    flex: 1; }
    .fast-facts .stat-block:last-child {
      margin-right: 0; }

.article-teasers {
  padding-top: 0; }
  .article-teasers__item {
    border-top: 0.125rem solid #ededed;
    padding-top: 2.1875rem;
    margin-top: 2.1875rem; }
    .article-teasers__item:first-child {
      border: 0;
      margin-top: 0;
      padding-top: 0; }
  .article-teasers .article-teaser {
    padding: 0; }
  .article-teasers .article-teaser__tag {
    padding-left: 0; }
  .article-teasers .article-teaser__title {
    padding-left: 0; }
    .article-teasers .article-teaser__title:before {
      display: none; }
  .article-teasers .article-teaser__description {
    padding-left: 0; }
  .article-teasers .article-teaser__container {
    padding-top: 0; }
  .article-teasers .article-teasers__secondary {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 74.9375em) {
      .article-teasers .article-teasers__secondary {
        flex-flow: row wrap; } }
    .article-teasers .article-teasers__secondary .secondary-item {
      flex: 0 1 48%; }
      @media screen and (max-width: 74.9375em) {
        .article-teasers .article-teasers__secondary .secondary-item {
          flex: 0 1 100%; } }

.article-teasers--standalone .article-teasers__item {
  padding: 3.125rem 3.75vw; }

.article-teasers--standalone .article-teaser__container {
  padding-right: 2rem; }

.profile-teasers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .profile-teasers > * {
    padding: 3.75rem 0;
    flex: 1; }

.editorial {
  clear: both; }
  .editorial__asides {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px #000 solid; }
    .editorial__asides aside {
      border-bottom: 1px #000 solid;
      margin-bottom: 1em;
      padding-bottom: 1em; }
      .editorial__asides aside:last-child {
        border-bottom-width: 0;
        margin-bottom: 0;
        padding-bottom: 0; }

.editorial--aside-only .editorial__asides {
  border: 1px #000 solid;
  border-width: 1px 0;
  margin: 1rem 0;
  padding: 1rem 0; }

@media print, screen and (min-width: 28.125em) {
  .editorial.editorial--aside-left .editorial__asides {
    float: left;
    margin: 0 1rem 1rem 0; }
  .editorial__asides {
    float: right;
    margin: 0 0 1rem 1rem;
    border-top-width: 0;
    padding: 0;
    max-width: 34%; }
  .editorial--aside-only .editorial__asides {
    float: none;
    max-width: 100%;
    border: 1px #000 solid;
    border-width: 1px 0;
    margin: 1rem 0;
    padding: 1rem 0; } }

.featured-articles .article-teasers {
  padding: 0; }

.grid {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  overflow: hidden; }
  .grid .square {
    position: relative;
    height: 50%;
    width: 50%; }
    .grid .square:after {
      content: "";
      display: block;
      padding-bottom: 100%; }
    .grid .square .grid-block,
    .grid .square .square__content {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: row wrap; }
    .grid .square.square--half {
      width: 25%; }
    .grid .square.square--half-height:after {
      padding-bottom: 50%; }

.grid--feature {
  flex-flow: row nowrap; }
  @media screen and (max-width: 74.9375em) {
    .grid--feature {
      flex-flow: row wrap;
      margin-bottom: 2.5rem; } }
  .grid--feature .square {
    width: 25%; }
    @media screen and (max-width: 74.9375em) {
      .grid--feature .square {
        width: 33.3333%; } }
    @media screen and (max-width: 53.0625em) {
      .grid--feature .square {
        position: static;
        width: 100%;
        height: auto; }
        .grid--feature .square:after {
          display: none; }
        .grid--feature .square .grid-block,
        .grid--feature .square .square__content {
          position: static; } }
    .grid--feature .square:nth-child(even) {
      margin-top: 3.125rem; }
      @media screen and (max-width: 53.0625em) {
        .grid--feature .square:nth-child(even) {
          margin-top: 0; } }
    .grid--feature .square:last-child {
      width: 35%; }
      @media screen and (min-width: 75em) and (max-width: 93.6875em) {
        .grid--feature .square:last-child {
          width: 40%; } }
      @media screen and (max-width: 74.9375em) {
        .grid--feature .square:last-child {
          position: static;
          width: 100%;
          height: auto; }
          .grid--feature .square:last-child:after {
            display: none; }
          .grid--feature .square:last-child .grid-block,
          .grid--feature .square:last-child .square__content {
            position: static; } }
      .grid--feature .square:last-child .grid-block {
        height: 72%; }
        @media screen and (min-width: 75em) and (max-width: 93.6875em) {
          .grid--feature .square:last-child .grid-block {
            height: 68%; } }
        @media screen and (min-width: 53.125em) {
          .grid--feature .square:last-child .grid-block {
            align-items: center; } }
  .grid--feature .square .grid-block {
    font-size: 1.375rem;
    font-weight: bold; }
    @media screen and (min-width: 93.75em) {
      .grid--feature .square .grid-block {
        font-size: 1.75rem; } }
    @media screen and (min-width: 53.125em) and (max-width: 74.9375em) {
      .grid--feature .square .grid-block {
        font-size: 1.75rem; } }
    @media screen and (max-width: 21.875em) {
      .grid--feature .square .grid-block {
        padding: 1.25rem; } }
    .grid--feature .square .grid-block .icon-label {
      white-space: normal; }
      @media screen and (min-width: 93.75em) {
        .grid--feature .square .grid-block .icon-label {
          text-indent: -2.25rem;
          padding-left: 2.25rem; } }
      @media screen and (min-width: 93.75em) {
        .grid--feature .square .grid-block .icon-label svg {
          width: 1.875rem;
          height: 1.875rem; } }
  .grid--feature .square:last-child .grid-block p {
    margin: 0;
    font-weight: normal;
    font-size: 1.125rem; }
    @media screen and (min-width: 93.75em) {
      .grid--feature .square:last-child .grid-block p {
        font-size: 1.375rem; } }
    .grid--feature .square:last-child .grid-block p a {
      font-weight: bold;
      background: transparent linear-gradient(to bottom, transparent 50%, #007eae 50%);
      text-decoration: none;
      background-size: 100% 200%;
      background-position: 0 4%;
      transition: all 0.1s ease-in-out; }
      .grid--feature .square:last-child .grid-block p a:hover {
        background-position: 0 12%; }

.grid--feature .grid-block .icon-label svg {
  transform: rotate(180deg); }

.grid--profiles {
  position: relative;
  padding: 6.25rem 0 0 6.25rem; }
  @media screen and (max-width: 87.5em) {
    .grid--profiles {
      padding: 3.125rem; } }
  @media screen and (max-width: 53.0625em) {
    .grid--profiles {
      padding: 1.25rem; } }
  .grid--profiles:before {
    content: "";
    display: block;
    border-bottom: 31.25rem solid transparent;
    border-left: 31.25rem solid #ededed;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }
  .grid--profiles .square {
    width: 20%; }
    .grid--profiles .square.offset, .grid--profiles .square:first-child, .grid--profiles .square:nth-child(5) {
      margin-top: 6.875rem; }
    .grid--profiles .square:nth-child(n+6) {
      margin-top: -6.875rem; }
    @media screen and (max-width: 93.6875em) {
      .grid--profiles .square:nth-child(5) {
        order: 99;
        margin-top: -6.875rem; } }
    .grid--profiles .square:nth-child(6) {
      margin-left: 20%; }
    @media screen and (max-width: 87.5em) {
      .grid--profiles .square:first-child {
        position: static;
        width: 100%;
        height: auto; }
        .grid--profiles .square:first-child:after {
          display: none; }
        .grid--profiles .square:first-child .grid-block,
        .grid--profiles .square:first-child .square__content {
          position: static; } }
    .grid--profiles .square:first-child .headline-group {
      width: 100%; }
      @media screen and (max-width: 62.5em) {
        .grid--profiles .square:first-child .headline-group {
          width: 100%; } }
      @media screen and (max-width: 87.5em) {
        .grid--profiles .square:first-child .headline-group {
          margin-bottom: 0; }
          .grid--profiles .square:first-child .headline-group .subhead {
            margin-top: 0; } }
    .grid--profiles .square:first-child a {
      display: block;
      width: 100%; }
      @media screen and (max-width: 87.5em) {
        .grid--profiles .square:first-child a {
          margin-right: 1.25rem;
          margin-top: 1.25rem;
          display: inline-block;
          width: auto; } }
      @media screen and (max-width: 40em) {
        .grid--profiles .square:first-child a {
          margin-right: 0;
          width: 100%; } }
    @media screen and (max-width: 87.5em) {
      .grid--profiles .square:not(:first-child) {
        margin: 0;
        width: 33.333%; } }
    @media screen and (max-width: 40em) {
      .grid--profiles .square:not(:first-child) {
        width: 100%; } }

.grid.grid--fact-grid .square.grid__intro .grid-block {
  padding: 0; }

@media screen and (max-width: 93.75em) {
  .grid.grid--fact-grid .square.grid__intro {
    position: static;
    width: 100%;
    height: auto; }
    .grid.grid--fact-grid .square.grid__intro:after {
      display: none; }
    .grid.grid--fact-grid .square.grid__intro .grid-block,
    .grid.grid--fact-grid .square.grid__intro .square__content {
      position: static; }
  .grid.grid--fact-grid > .square {
    width: 100%; }
  .grid.grid--fact-grid .square--half {
    width: 50%; } }

@media screen and (max-width: 28.75em) {
  .grid.grid--fact-grid > .square, .grid.grid--fact-grid.square--half,
  .grid.grid--fact-grid .square {
    width: 100%; }
    .grid.grid--fact-grid > .square .square__content, .grid.grid--fact-grid.square--half .square__content,
    .grid.grid--fact-grid .square .square__content {
      width: auto;
      height: auto;
      display: block;
      position: static; }
    .grid.grid--fact-grid > .square.square--nested:after, .grid.grid--fact-grid.square--half.square--nested:after,
    .grid.grid--fact-grid .square.square--nested:after {
      display: none; }
    .grid.grid--fact-grid > .square.square--half-height, .grid.grid--fact-grid.square--half.square--half-height,
    .grid.grid--fact-grid .square.square--half-height {
      position: static;
      width: 100%;
      height: auto;
      position: relative; }
      .grid.grid--fact-grid > .square.square--half-height:after, .grid.grid--fact-grid.square--half.square--half-height:after,
      .grid.grid--fact-grid .square.square--half-height:after {
        display: none; }
      .grid.grid--fact-grid > .square.square--half-height .grid-block,
      .grid.grid--fact-grid > .square.square--half-height .square__content, .grid.grid--fact-grid.square--half.square--half-height .grid-block,
      .grid.grid--fact-grid.square--half.square--half-height .square__content,
      .grid.grid--fact-grid .square.square--half-height .grid-block,
      .grid.grid--fact-grid .square.square--half-height .square__content {
        position: static; }
      .grid.grid--fact-grid > .square.square--half-height .grid-block--image, .grid.grid--fact-grid.square--half.square--half-height .grid-block--image,
      .grid.grid--fact-grid .square.square--half-height .grid-block--image {
        min-height: 18.75rem; } }
