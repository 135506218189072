.icon-label {
	white-space: nowrap;

	svg {
		height: 1rem;
		width: 1rem;
		margin-right: 3px;
		position: relative;
  		top: rem-calc(2);
	}

	.arrow,
	.icon-wrapper,
	.label {
		display: inline-block;
		vertical-align: top;
		max-height: 1em;
	}

	.icon-wrapper:first-child {
		margin-right: 0.5em;
	}

	icon-wrapper:last-child {
		margin-left: 0.5em;
	}

	.arrow {
		font-family: arial, helvetica, sans-serif;
	}

	.label {
		white-space: normal;
	}
}
