.generic {
    font-family: $font-primary;
    color: $color-shade-darker;

    @at-root #{&}--inverted {
        color: $color-shade-lighter;
    }

    @at-root #{&}__title {
        font-size: rem-calc(30);
        font-weight: 700;
        line-height: rem-calc(40);
        padding-bottom: rem-calc(20);
        padding-top: rem-calc(20);
    }

    @at-root #{&}__content {
        
    }
}