.align-left {
	text-align: left;
}

.align-right {
	text-align: right !important;
}

.align-center {
	text-align: center;
}

.align-justify {
	text-align: justify;
}

.align-justified-and-ancient {
	text-align: justify;
	font-family: fantasy;
}
