@mixin show_color($color) {
	background-color: $color;
	&::before {
		content: '#{$color}';
		display: inline-block;
		padding: .5em;
		border-radius: 0 0 4px 0;
		font-size: 12px;
		font-weight: bold;
		@if (brightness($color) > 50) {
			color: #000;
			background-color: rgba(255,255,255,.5);
		}
		@else {
			color: #fff;
			background-color: rgba(0,0,0,.5);
		}
	}
}

// primaries
.bg-primary {
	@include show_color($color-primary);
}

.bg-secondary {
	@include show_color($color-secondary);
}

.bg-tertiary {
	@include show_color($color-tertiary);
}

.bg-quaternary {
	@include show_color($color-quaternary);
}

.bg-quinary {
	@include show_color($color-quinary);
}

.bg-senary {
	@include show_color($color-senary);
}

.bg-septenary {
	@include show_color($color-septenary);
}

.bg-octonary {
	@include show_color($color-octonary);
}

.bg-nonary {
	@include show_color($color-nonary);
}

.bg-denary {
	@include show_color($color-denary);
}


// accents

.bg-accent-primary {
	@include show_color($color-accent-primary);
}

.bg-accent-secondary {
	@include show_color($color-accent-secondary);
}

.bg-accent-tertiary {
	@include show_color($color-accent-tertiary);
}

.bg-accent-quaternary {
	@include show_color($color-accent-quaternary);
}

.bg-accent-quinary {
	@include show_color($color-accent-quinary);
}

.bg-accent-senary {
	@include show_color($color-accent-senary);
}

.bg-accent-septenary {
	@include show_color($color-accent-septenary);
}

.bg-accent-octonary {
	@include show_color($color-accent-octonary);
}

.bg-accent-nonary {
	@include show_color($color-accent-nonary);
}

.bg-accent-denary {
	@include show_color($color-accent-denary);
}


// grays & shades
.bg-shade-lightest {
	@include show_color($color-shade-lightest);
}

.bg-shade-lighter {
	@include show_color($color-shade-lighter);
}

.bg-shade-light {
	@include show_color($color-shade-light);
}

.bg-shade {
	@include show_color($color-shade);
}

.bg-shade-dark {
	@include show_color($color-shade-dark);
}

.bg-shade-darker {
	@include show_color($color-shade-darker);
}

.bg-shade-darkest {
	@include show_color($color-shade-darkest);
}
