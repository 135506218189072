.icon-wrapper {
	display: inline-block;
	padding-right: 0.5rem;
	display: none !important;
}

.icon {
	height: 1em;
	width: 1em;
	display: inline-block;
}
