.profile-headshot-stats {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	.col {
		width: 48%;
		@include breakpoint(1100px down) {
			width: 100%;
		}
		@include breakpoint(medium down) {
			width: 48%;
		}
		@include breakpoint(500px down) {
			width: 100%;
		}
		img {
			margin-bottom: rem-calc(30);
		}
	}
	.pullquote-secondary:before {
		display: none;
	}
}

.with-sidebar {
	.profile-headshot-stats {
		.col {
			@include breakpoint(1500px down) {
				width: 100%;
			}
		}
	}
}