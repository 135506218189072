
table {
    border-collapse: collapse;
    border: 0;
    thead {
        background: $color-primary;
        color: #fff;
        tr {
            // 
        }
    }
    tbody {
        tr,
        tr:nth-child(even) {
            background: #fff;
            border-bottom: $color-shade-light 3px solid;
        }
        th,
        td {
            // 
        }
        th {
            text-align: left;
        }
    }

    thead,
    tbody,
    tfoot {
        border: 0;
        th,
        td {
            padding: vw-calc(15) vw-calc(30);
        }
    }


    &.table--zebra {
        tbody {
            tr:nth-child(even) {
                background: $color-shade-lighter;
            }
        }
    }

}

.table-responsive-wrap {
    @include breakpoint(large down) {
        overflow-x: auto;
        table {
            display: block;
            thead,
            tbody,
            tfoot {
                display: block;
                tr,
                td,
                th {
                    display: block;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            thead {
                display: none;
            }
        }
    }
}