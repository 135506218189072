
// helper classes for migrated images

img[align="right"],
.image-align-right {
    margin-left: rem-calc(20);
    margin-bottom: rem-calc(20);
}

img[align="left"],
.image-align-left {
    margin-right: rem-calc(20);
    margin-bottom: rem-calc(20);
}