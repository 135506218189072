.profile-teaser {
    display: flex;
    flex-direction: row;

    @at-root #{&}__image {
        // height: vw-calc(230);
        max-width: vw-calc(230);
        // border-radius: 10rem;
        // background-size: cover;
        // background-position: center center;
        // margin-left: rem-calc(30);
        // min-width: rem-calc(100);
        // min-height: rem-calc(100);
        img {
            border-radius: 50%;
        }
    }

    @at-root #{&}__description {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: rem-calc(40);
        padding-top: rem-calc(25);
        padding-right: 0.5rem;
        min-width: rem-calc(300);
        min-width: rem-calc(230);
    }

    @at-root #{&}__tag {
        color: $color-shade-darker;
        font-family: $font-secondary;
        font-size: rem-calc(18);
        font-weight: 700;
        line-height: rem-calc(20);
        text-transform: uppercase;
        padding-bottom: rem-calc(3);
    }

    @at-root #{&}__link {
        font-size: rem-calc(25);
        font-family: $font-secondary;
        position: relative;
        line-height: rem-calc(30);
        padding-bottom: rem-calc(20);
        
        &:before {
            @include line_pointer(
                $top: 47%, 
                $left: rem-calc(-59)
            );
            width: rem-calc(40);
            height: rem-calc(2);
        }
    }

    @at-root #{&}__caption {
        
    }

    @include breakpoint (small only) {
        flex-direction: column;
    }
}