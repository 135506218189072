@charset "UTF-8";

// These settings override the defaults found in
// ../bower_components/slick-carousel/slick/slick-theme.scss

$slick-font-path: "//cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/fonts/";
//$slick-font-family: "slick" !default;
//$slick-loader-path: "./" !default;
$slick-arrow-color: black;
//$slick-dot-color: black !default;
//$slick-dot-color-active: $slick-dot-color !default;
//$slick-prev-character: "←" !default;
//$slick-next-character: "→" !default;
$slick-button-distance: -10px;
//$slick-dot-character: "•" !default;
$slick-dot-size: 0.5rem !default;
//$slick-opacity-default: 0.75 !default;
//$slick-opacity-on-hover: 1 !default;
//$slick-opacity-not-active: 0.25 !default;


.slick-prev {
    left: 0;
    z-index: 9;

    svg {
        transform: rotateY(180deg);
    }
}

.slick-next {
    right: 0;
}

.slick-prev, .slick-next {
    width: rem-calc(120);
    height: rem-calc(60);
    background: $color-secondary;

    ::before {
        content: "" !important;
    }

    svg {
        height: 2rem;
        width: 2rem;
    }
}

.slick-disabled {
    visibility: hidden;
}

.slick-slider {
    margin-bottom: 0;
}