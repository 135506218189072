.page-section {

	.row {
		padding: {
			top: 0.5rem;
			bottom: 0.5rem;
		}
	}

	@at-root #{&}__container {
		@include content-container;
	}

	@at-root #{&}--dark {
		@include ondark;
		background-color: $color-shade-darkest;
	}

	@at-root #{&}--light {
		background-color: $color-shade-lightest;
	}
}

.page-section--dark {
	@include ondark;
	background-color: $color-shade-darkest;
}

.page-section--light {
	background-color: $color-shade-lightest;
}
