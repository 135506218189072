// @import 'content-layouts/alert';
@import 'content-layouts/call-to-action';
@import 'content-layouts/duo-block';
@import 'content-layouts/quad-block';


.duo-block,
.quad-block {

	@include breakpoint(small down) {
		// grid-template-rows: auto;
		display: block;
	}


	.stat-block {
		// height: 100%;
		// max-height: 100vw;
		// min-height: 5rem;
		position: relative;
		// overflow: hidden;
		@include breakpoint(medium up) {
			height: 100%;
			max-height: 100vw;
			min-height: 5rem;
			overflow: hidden;
		}
		.stat-block__container {
			// move to grid:
			// position: absolute;
		}
	}
	.video-block {
		width: 100%;
		height: 100%;
		max-height: 100vw;
		// min-height: 5rem;
	}
}