.editorial {
	clear: both;

	@at-root #{&}__asides {
		margin-top: 1rem;
		padding-top: 1rem;
		border-top: 1px $black solid;

		aside {
			border-bottom: 1px $black solid;
			margin-bottom: 1em;
			padding-bottom: 1em;

			&:last-child {
				border-bottom-width: 0;
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
	}
}

.editorial--aside-only {
	.editorial__asides {
		border: 1px $black solid;
		border-width: 1px 0;
		margin: 1rem 0;
		padding: 1rem 0;
	}
}


@include breakpoint(medium) {
	.editorial {
		&.editorial--aside-left {

			.editorial__asides {
				float: left;
				margin: rem-calc(0 16 16 0);
			}
		}

		@at-root #{&}__asides {
			float: right;
			margin: rem-calc(0 0 16 16);
			border-top-width: 0;
			padding: 0;
			max-width: 34%;
		}
	}

	.editorial--aside-only {
		.editorial__asides {
			float: none;
			max-width: 100%;
			border: 1px $black solid;
			border-width: 1px 0;
			margin: 1rem 0;
			padding: 1rem 0;
		}
	}
}
