
// ul defaults can go here
// but please don't make any
// it causes much rage
ul, ol {
	li {
		line-height: rem-calc(35);
	}
}


// this should be moved somewhere else probably?
@mixin no-decoration {
	margin: 0 0 0 0;
	list-style: none;
	padding: 0 0 0 0;
	&> li {
		text-indent: 0;
		margin-left: 0;
		&:before {
			display: none;
		}
	}
}

ul.no-decoration {
	@include no-decoration;
}

ol.no-decoration {
	@include no-decoration;
}


.list--unordered {
	list-style-image: url('../images/list-plus.svg');
	margin-top: 2rem;
    margin-bottom: 2rem;
}

.list--ordered {
	// color: $color-quinary;
	font-size: rem-calc(16);
	// font-weight: 600;
	line-height: rem-calc(26);
	margin-top: 2rem;
    margin-bottom: 2rem;
	
	span {
		// color: $color-shade-darker;
	}
}

dl {
	margin-bottom: rem-calc(30);
	dt {
		margin-bottom: 0;
	}
	dd {
		margin-bottom: rem-calc(15);
	}
}