.grid {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    overflow: hidden;

    .square {
        position: relative;
        height: 50%;
        width: 50%;
        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
        .grid-block,
        .square__content {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            flex-flow: row wrap;
        }

        &.square--half {
            width: 25%;
        }

        &.square--half-height {
            &:after {
                padding-bottom: 50%;
            }
        }

        // include mixin unsquare() to turn the forced squaring off
        // when scaling grids responsively

    }
}