.gallery-item {
    flex: 1;
    min-width: 20rem;
    font-family: $font-primary;
    margin: vw-calc(13);
    margin-bottom: rem-calc(44);
    cursor: pointer;

    @at-root #{&}__image {
        
    }

    @at-root #{&}__link {
        font-size: rem-calc(14);
        line-height: rem-calc(22);
        color: $color-secondary;
        font-style: italic;
        // padding-bottom: rem-calc(44);
        padding-top: rem-calc(10);

        .link {
            color: $color-secondary;
            font-weight: 300;
        }
    }

    img { 
        border: rem-calc(2) solid transparent;
    }

    &:hover {

        img {
            border: rem-calc(2) solid $color-secondary;
        }

        a {
            text-decoration: underline;
        }
    }
}

.active-slide {

    img {
        border: rem-calc(2) solid $color-secondary;
    }

    a {
        text-decoration: underline;
    }
}