.pullquote-secondary {
    font-family: $font-primary;
    padding: 0 0 rem-calc(83) rem-calc(67);
    margin: 2rem 0 2rem rem-calc(-67);
    position: relative;

    &:before {
        content: "";
        display: block;
        width: 0; 
        height: 0; 
        border-top: rem-calc(453) solid transparent;
        border-bottom: rem-calc(453) solid $color-shade-light; 
        border-right: rem-calc(453) solid transparent; 
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    @at-root #{&}__quote {
        color: $color-shade-darker;
        font-size: rem-calc(30);
        line-height: rem-calc(40);
    }
    @at-root #{&}__attribution {
        color: $color-primary;
        font-size: rem-calc(16);
        font-weight: 600;
        line-height: rem-calc(26);
        font-style: normal;
    }

    @include breakpoint(small only) {
        overflow: hidden;
    }
}

.with-sidebar__sidebar {
    .pullquote-secondary {
        padding: 0;
        margin: rem-calc(10) 0 rem-calc(30) 0;
        .pullquote-secondary__quote {
            font-size: rem-calc(18);
            line-height: rem-calc(28);
        }
        &:before {
            display: none;
        }
    }
}