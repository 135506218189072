.headline-group {

	display: flex;
    flex-direction: column;
	
	.superhead,
	.subhead {
		color: inherit;
		display: block;
		font-family: $font-primary;
	}

	.superhead {
		font-weight: 700;
		font-size: rem-calc(30);
		line-height: rem-calc(50);

		&:after {
			content: "";
			width: rem-calc(100);
			height: rem-calc(2);
			background: $color-secondary;
			display: block;
			margin: 1rem 0;
		}
	}

	.head {
		font-weight: 700;
		font-size: rem-calc(20);
		line-height: rem-calc(30);
	}

	& h1 {
		.superhead {
			font-size: rem-calc(40);
			font-weight: 700;
			line-height: rem-calc(50);
		}

		.subhead {
			font-size: rem-calc(20);
		}
	}

}
