@mixin decoration($where:'left',$content:'\272a',$rotation:0) {
	display: inline-block;
	@if ($where == 'left' or $where == 'both') {
		&::before {
			content: $content;
			margin-right: 0.5em;
			font-family: sans-serif;
			@if ($rotation != 0) {
				transform: rotate($rotation);
				display: inline-block;
			}
		}
	}
	@if ($where == 'right' or $where == 'both')  {
		&::after {
			content: $content;
			margin-left: 0.5em;
			font-family: sans-serif;
			@if ($rotation != 0) {
				transform: rotate($rotation);
				display: inline-block;
			}
		}
	}
}

.decoration {
	@include decoration;
}

.decoration-on-right {
	@include decoration('right');
}

.decoration-both {
	@include decoration('both');
}

.arrow-right {
	@include decoration('left','\2192',0);
}

.arrow-down {
	@include decoration('left','\2193',0);
}

.arrow-left {
	@include decoration('left','\2190',0);
}

.arrow-up {
	@include decoration('left','\2191',0);
}

.triangle-right {
	@include decoration('right','\25b6',0);
}

.triangle-down {
	@include decoration('right','\25b6',90deg);
}

.triangle-left {
	@include decoration('right','\25c0',0);
}

.triangle-up {
	@include decoration('right','\25c0',90deg);
}
