@mixin fontstyle($stylename: "default") {
	@if $stylename == "h1" {
		font: normal 700 rem-calc(40) $font-primary;
		line-height: rem-calc(50);
		text-transform: none;
		font-family: inherit;
	}
	@elseif $stylename == "h2" {
		font: normal 700 rem-calc(30) $font-primary;
		line-height: rem-calc(40);
		text-transform: none;
		font-family: inherit;
	}
	@elseif $stylename == "h3" {
		font: normal 700 rem-calc(25) $font-primary;
		line-height: rem-calc(30);
		text-transform: none;
		font-family: inherit;
	}
	@elseif $stylename == "h4" {
		font: normal 700 rem-calc(18.7) $font-tertiary;
		line-height: 1.5;
		text-transform: none;
		font-family: inherit;
	}
	@elseif $stylename == "h5" {
		font: normal bold rem-calc(16) $font-primary;
		line-height: 1.5;
		text-transform: none;
		font-family: inherit;
	}
	@elseif $stylename == "h6" {
		font: italic normal rem-calc(16) $font-primary;
		line-height: 1.5;
		text-transform: none;
		font-family: inherit;
	}
	@elseif $stylename == "button" {
		font: normal bold 1rem $font-primary;
		line-height: 1;
		text-transform: none;
		font-family: inherit;
	}
	@else {
		font: normal normal $base-font-size $font-primary;
		text-transform: none;
		line-height: 1.5;
		font-family: inherit;
	}
}

/* This is an example of setting color themes for text
   with a mixin. You can/should make several of these. */
@mixin ondark {
	color: $white;

	abbr {
		color: $white;
	}

	a {
		&:link,
		&:visited {
			color: $white;
		}
		&:hover,
		&:active {
			color: $color-brand-alt;
		}
	}
}

.ondark {
	@include ondark;
}
