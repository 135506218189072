.accordion {
    background: $white;
    padding: rem-calc(45) 0;

    @at-root #{&}__tab {
        position: relative;
        margin-bottom: 1px;
        width: 100%;
        color: $white;
        overflow: hidden;
    }

    @at-root #{&}__tab-content {
        max-height: 0;
        overflow: hidden;
        background: $white;
        transition: max-height .35s;
		color: $color-shade-darker;
	}
	
	@at-root #{&}__tab-content-inner {
		padding: vw-calc(30);
	}

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    /* Accordion styles */


    label {
        width: 20rem;
        position: relative;
        display: block;
        padding-left: 1em;
        font-weight: bold;
        line-height: 3;
        cursor: pointer;
        color: $white;
        width: 100%;
        color: $color-quaternary;
        background: rgba($color-quaternary, 0.2);
        margin-left: 0 !important;

        span {
            margin-right: 1rem;

            svg {
                display: inline-block;
                width: 1rem;
                height: 1rem;
            }
        }

        .close {
			display: none;
			svg {
				transform: rotate(180deg);
			}
        }
    }

    .tab-content p {
    margin: 1em;
    }
    /* :checked */
    input:checked ~ .accordion__tab-content {
 		max-height: 50em;
		overflow: scroll;
    }

    input:checked ~ label {
    color: $white;
    background: $color-quaternary;
    }

    input:checked ~ label > .open {
    display: none;
    }

    input:checked ~ label > .close {
    display: inline;
    }
}




