.radio-buttons {
    padding-bottom: 1rem;
    margin: 0;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        list-style: none;
        padding-right: 2rem;
        min-width: 7rem;
    }

    label {
        color: $color-shade-lightest;
        font-size: rem-calc(14);
        font-weight: 700;
        line-height: rem-calc(26);
    }
    &__options {

    }

    &__radio {

    }
}