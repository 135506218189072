.nav-main {
	display: flex;
	// flex-direction: column;
	flex-flow: row wrap;
	align-items: flex-start;
	background: $white;
	// height: 100%;

	@at-root #{&}__menu-toggle {
		color: $color-primary;
		padding: 1rem rem-calc(30);
		padding-top: 1.5rem;
		font-size: rem-calc(17);
		font-weight: 700;
		text-transform: uppercase;
		font-family: $font-secondary;
		cursor: pointer;
		display: none;

		&:after {
			content: "";
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba($color-shade-lightest,.5);
			z-index: -1;
		}
	}

	.link-group {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.link-group a {
		color: $color-primary;
		display: block;
		padding: rem-calc(14) 2rem;
		width: 100%;
		font-size: rem-calc(17);
		line-height: rem-calc(20);
		font-family: $font-secondary;
		font-weight: 700;
    	text-align: left;

		&:hover {
			color: $white;
			background: $color-primary;
			@include fade(background, color);
		}
	}

	a {
		line-height: 2;
		text-transform: uppercase;
		font-weight: bold;
		text-decoration: none;
	}

	.input-group {
		margin-bottom: 0;
	}

	// @include breakpoint(medium) {

	// 	.link-group {

	// 		> * {
	// 			text-align: center;
	// 		}
	// 	}

	// 	a {
	// 		line-height: 1.3;
	// 	}

	// 	.nav-main.search-bar--active {
	// 		.link-group {
	// 			width: 0;
	// 			overflow: hidden;
	// 		}
	// 	}
	// }

	// .search-bar {
	// 	padding: 0 2rem;
	// }

	@include breakpoint(medium down) {

		// display: none;

		background: $color-menu-mobile;
		display: block;
		// .nav-main__menu-toggle {
		// 	display: block;
		// }
	}

}



