.featured-article {
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;

    @at-root #{&}__image {
        position: relative;
        margin: 0 rem-calc(70);

        &:before {
            content: "";
            display: block;
            border-left: rem-calc(285) solid transparent;
            border-bottom: rem-calc(285) solid $color-shade-light;
            position: absolute;
            bottom: rem-calc(-41);
            right: rem-calc(-80);
            z-index: -1;
        }
    }
    .featured-article__image {
        flex: 0 1 48%;
    }
    .generic {
        flex: 0 1 48%;
    }
    
    .generic__title {
        text-transform: uppercase;
    }

    @at-root #{&}-secondary {

        .article-teaser__image {
            margin-right: vw-calc(60);
            min-width: 15rem;
        }

        .article-teaser__container {
            padding-top: rem-calc(40) !important;
            padding-right: vw-calc(80) !important;
        }
        
    }

    @include breakpoint(small) {
        .featured-article__image {
            margin: 1rem rem-calc(70);
        }
    }
}