.captioned-media {

    @at-root #{&}--triangle {
        // padding: vw-calc(38) 0 2rem 3.125vw;
        padding: 0 0 2rem 3.125vw;
        position: relative;
        &:before {
            content: "";
            display: block;
            border-bottom: rem-calc(285) solid transparent;
            border-left: rem-calc(285) solid $color-shade-light;
            position: absolute;
            left: 0;
            top: vw-calc(-38);
            z-index: -1;
        }
    }
}