@for $i from 1 through 6 {
	h#{$i} {
		@include fontstyle("h#{$i}");
		color: $color-shade-darker;
		// font-weight: inherit;
		// font-size: inherit;
		&.heading--caps {
			text-transform: uppercase;
		}
	}
}

.heading--condensed {
	font-family: $font-secondary;
}

