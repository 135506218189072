.grid--feature {
    flex-flow: row nowrap;
    @include breakpoint(large down) {
        flex-flow: row wrap;
        margin-bottom: rem-calc(40);
    }
    .square {
        width: 25%;
        @include breakpoint(large down) {
            width: 33.3333%;
        }
        @include breakpoint(medium down) {
            @include unsquare;
        }
        &:nth-child(even) {
            // margin-top: 5%;
            margin-top: rem-calc(50);
            @include breakpoint(medium down) {
                margin-top: 0;
            }
        }
        &:last-child {
            width: 35%;
            @include breakpoint(xlarge only) {
                width: 40%;
            }
            @include breakpoint(large down) {
                @include unsquare;
            }
            &:after {
                @include breakpoint(xlarge only) {
                }
                @include breakpoint(large down) {
                }
            }
            .grid-block {
                height: 72%;
                @include breakpoint(xlarge only) {
                    height: 68%;
                }
                @include breakpoint(large down) {
                    // 
                }
                @include breakpoint(large up) {
                    align-items: center;
                }
            }
        }
    }

    .square {
        .grid-block {
            font-size: rem-calc(22);
            font-weight: bold;
            @include breakpoint(xxlarge up) {
                font-size: rem-calc(28);
            }
            @include breakpoint(large only) {
                font-size: rem-calc(28);
            }
            @include breakpoint(350px down) {
                padding: rem-calc(20);
            }
            .icon-label {
                white-space: normal;
                @include breakpoint(xxlarge up) {
                    text-indent: rem-calc(-36);
                    padding-left: rem-calc(36);
                }
                svg {
                    @include breakpoint(xxlarge up) {
                        width: rem-calc(30);
                        height: rem-calc(30);
                    }
                }
            }
        }
        &:last-child {
            .grid-block {
                p {
                    margin: 0;
                    font-weight: normal;
                    font-size: rem-calc(18);
                    @include breakpoint(xxlarge up) {
                        font-size: rem-calc(22);
                    }
                    @include breakpoint(xlarge only) {
                        // font-size: rem-calc(18);
                    }
                    @include breakpoint(large down) {
                        // font-size: rem-calc(18);
                    }
                    a {
                        font-weight: bold;
                        @include fauxunderline($color-quaternary);
                    }
                }
                &:before {
                    // content: "";
                    // display: block;
                    // border-bottom: rem-calc(300) solid transparent;
                    // border-left: rem-calc(300) solid rgba($color-secondary, 0.5);
                    // position: absolute;
                    // left: rem-calc(-40);
                    // top: rem-calc(-40);
                    // z-index: -1;
                }
            }
        }
    }
}


.grid--feature {
    .grid-block {
        .icon-label {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}