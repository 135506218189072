.page-header {
	background-color: $white;
	// padding-bottom: rem-calc(20);

	@at-root #{&}__page-title {
		color: $color-shade-darker;
		padding: rem-calc(63) rem-calc(50) vw-calc(40) vw-calc(50);

		a {
			font-size: rem-calc(14);
		}

		.one-column {
			max-width: none;
		}

		@at-root #{&}--secondary {
			padding: vw-calc(56) vw-calc(300) 0 vw-calc(55);
			.introductory {
				padding: 0;
			}
		}

		// .superhead {
		// 	font-size: rem-calc(40);
		// }

		// .subhead {
		// 	font-size: rem-calc(20);
		// }
	}

	@at-root #{&}__hero {
		color: $color-shade-darker;
		margin-bottom: 2.5rem;
	}

}
