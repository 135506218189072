.article-teasers {
    // padding: vw-calc(60);
    padding-top: 0;

    @at-root #{&}__item {
        border-top: rem-calc(2) solid $color-shade-light;
        padding-top: rem-calc(35);
        margin-top: rem-calc(35);

        &:first-child {
            border: 0;
            margin-top: 0;
            padding-top: 0;
        }
    }

    // 

    .article-teaser {
        padding: 0;
    }

    .article-teaser__tag {
        padding-left: 0;
    }

    .article-teaser__title {
        padding-left: 0;

        &:before {
            display: none;
        }
    }

    .article-teaser__description {
        padding-left: 0;
    }

    .article-teaser__container {
        padding-top: 0;
    }

    .article-teasers__secondary {
        display: flex;
        justify-content: space-between;
        @include breakpoint (large down) {
            flex-flow: row wrap;
        }
        .secondary-item {
            flex: 0 1 48%;
            @include breakpoint (large down) {
                flex: 0 1 100%;
            }
        }
    }
}

.article-teasers--standalone {

    .article-teasers__item {
        padding: rem-calc(50) vw-calc(60);
    }

    .article-teaser {
        @at-root #{&}__container {
            padding-right: 2rem;
        }
    }
}