a {
    color: $color-quaternary;
}

.link {
    font-weight: 700;
    color: $color-link-secondary;
    text-decoration: none;
    display: inline-flex;
    align-items: baseline;

    svg {
        display: inline-flex;
        height: 1rem;
        width: 1rem;
        margin-right: rem-calc(7);
    }
}

.link--arrow {
    vertical-align: top;

    svg {
        height: 1rem;
        width: 1rem;
        // margin-left: rem-calc(4);
        transform: rotate(180deg);
    }
}

.link--basic {
    color: inherit;
    text-decoration: underline;
}

.link--inverted {
    color: $white;
}

p .link {
    @include fauxunderline($color-link-secondary);
    &:hover {
        color: $color-link-secondary;
    }
}