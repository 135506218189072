.rich-link-list {
    ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        @include breakpoint(large down) {
            flex-flow: row wrap;
        }

        li {
            margin: 0;
            @include breakpoint(large down) {
                width: 48%;
                margin-bottom: rem-calc(20);
            }
            @include breakpoint(small down) {
                width: 100%;
                margin-bottom: rem-calc(30);
            }
            a {
                display: block;
                text-decoration: none;
                font-weight: bold;
                font-size: rem-calc(30);
                line-height: rem-calc(30);
                @include breakpoint(small down) {
                    font-size: rem-calc(24);
                    line-height: rem-calc(28);
                }
                .superhead {
                    display: block;
                    font-size: rem-calc(20);
                    color: $color-text;
                    @include breakpoint(small down) {
                        font-size: rem-calc(16);
                    }
                }
            }
        }
    }

    @at-root #{&}--stack {
        ul {
            flex-flow: row wrap;
            li {
                width: 100%;
                padding-bottom: rem-calc(30);
                border-bottom: $color-shade 2px solid;
                margin-bottom: rem-calc(30);
                a {
                    font-family: $font-secondary;
                    font-size: rem-calc(26);
                }
            }
        }
    }
}