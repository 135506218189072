.sitenav-titlebar {
    width: rem-calc(430);
    background: $color-shade-darker;
    background: transparent linear-gradient(to bottom, rgba($color-shade-darker, 0.3) 0%, $color-shade-darker 100%);
    position: relative;
    color: #fff;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
	// align-content: flex-start;
	align-content: center;
    @include breakpoint (large down) {
    	width: rem-calc(240);
    	align-items: flex-start;
    	padding: vw-calc(50);
    }
    @include breakpoint (medium down) {
    	width: 100%;
    	flex-flow: column wrap;
    }
    h1, h2, h3, h4, h5, h6 {
        color: #fff;
    }
	a.link {
		// font-size: rem-calc(100);
		color: #fff;
    	&:link,
    	&:visited,
    	&:hover,
    	&:active {
    		color: #fff;
    	}
    }
    &:after {
    	display: block;
    	content: '';
    	position: absolute;
    	z-index: -1;
    	top: 0;
    	left: 0;
    	bottom: 0;
    	right: 0;
    	background: image_url('sample/sitenav-titlebar-sample.jpg') no-repeat top center;
    	background-size: cover;
    }

    .sitenav-titlebar__back-level {
    	padding: rem-calc(10);
    	position: absolute;
    	top: 0;
    	left: 0;
    	@include breakpoint (large down) {
    		position: static;
    		padding: 0;
    		margin-bottom: rem-calc(20);
		}
		a.link--arrow {
			svg {
				transform: none;
			}
		}
    }

    .sitenav-titlebar__title {
		margin: 0 vw-calc(50);
		flex: 0 1 auto;
		overflow: auto; //ie11
    	@include breakpoint (large down) {
    		margin: 0;
    	}
    	.button-group {
    		justify-content: flex-start;
    		margin-bottom: 0;
    		@include breakpoint (large down) {
    			flex-flow: row wrap;
    		}
    		.button {
    			margin-bottom: rem-calc(15);
    		}
    	}
    }
}