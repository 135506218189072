.skip-nav {
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	top: -5rem;

	&:focus {
		top: 0;
		transition: top 0.5s;
	}
}
