.search-bar {
	width: 100%;

	.search-bar__toggle {
		display: none;
		width: 100%;
	}

	@at-root #{&}__form {
		border: rem-calc(2) solid $color-shade-lighter;
		margin: 2rem 0;
		color: $color-shade-darker;

		.input-group {
			position: relative;
			margin: 0;
		}

		input {
			// border: 0;
			border: lighten($color-shade-dark, 10%) 1px solid;
		}

		svg {
			height: 1rem;
			position: absolute;
			right: 0;
			top: 0;
			width: 1rem;
			margin: rem-calc(13);
			margin-right: rem-calc(8);
			color: $color-primary;
		}
	}

	@at-root #{&}__toggle {
		transition: width 3s;

		a {
			display: block;
			text-decoration: none;
			color: $color-primary;
			font-size: rem-calc(17);
			font-family: $font-secondary;
			font-weight: 700;
			text-align: left;
			padding: 0.5rem 2rem;

			&:hover {
				color: $white;
				background: $color-primary;
				@include fade(background, color);
			}

			svg {
				height: 1rem;
				width: 1rem;
				margin-left: rem-calc(5);
				position: relative;
    			top: rem-calc(1);
			}
		}
	}
}

.search-bar__toggle {
	display: none;
}

.search-bar__form {
	transition: width 3s;
}

.search-bar--initialized {
	display: flex;
	align-items: center;

	.search-bar__toggle {
		// display: block;
	}

	.search-bar__form {
		// width: 0;
		// overflow: hidden;
		width: 100%;

		.input-group {
			margin-bottom: 0;
		}
	}
}

.search-bar--exposed {
	.search-bar__toggle {
		width: 0;
	}
	.search-bar__form {
		width: 100%;
	}
}
