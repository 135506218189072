.sitenav-mobile {

    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    width: 100%;


    @at-root #{&}__logo {
        // flex: 1;

        a {
            width: 100%;
            img {
                display: block;
                margin: 0 auto;
                // height: 4rem;
                // width: auto;
                width: 100%;
                max-width: rem-calc(300);
                padding: rem-calc(22 30);
                @include breakpoint(300px down) {
                    max-width: 100%;
                }
            }
        }
    }  


    @at-root #{&}__toggles {
        display: flex;

        .sitenav-mobile__main-toggle,
        .sitenav-mobile__secondary-toggle {
            width: 50%;
            cursor: pointer;
            padding: rem-calc(14);
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $font-secondary;
            font-weight: bold;
            font-size: rem-calc(17);
            .close-menu {
                display: none;
            }
            &.active {
                .open-menu {
                    display: none;
                }
                .close-menu {
                    display: block;
                }
            }
        }

        .sitenav-mobile__main-toggle {
            background: $color-menu-mobile;
            color: $color-primary;
            text-transform: uppercase;
            .icon-label {
                svg {
                    fill: $color-primary;
                }
            }
        }

        .sitenav-mobile__secondary-toggle {
            background: $color-primary;
            color: #fff;
            .icon-label {
                svg {
                    transform: rotate(90deg);
                    fill: #fff;
                }
            }
        }
    }

}

.top-nav-only,
.title-navbar {
    .sitenav-mobile__secondary-toggle {
        display: none;
    }
    .sitenav-mobile__main-toggle {
        width: 100%;
    }
}