@import 'grids/grid';
@import 'grids/feature-grid';
@import 'grids/profiles-grid';


.grid.grid--fact-grid {
	.square.grid__intro{
		.grid-block {
			padding: 0;
			.introductory {

			}
		}
	}

	@include breakpoint(1500px down) {
		.square.grid__intro {
			@include unsquare;
		}

		& > .square {
			width: 100%;
		}
		.square--half {
			width: 50%;
		}
	}

	@include breakpoint(460px down) {
		& > .square,
		&.square--half,
		.square {
			width: 100%;
			.square__content {
				width: auto;
				height: auto;
				display: block;
				position: static;
			}
			&.square--nested:after {
				display: none;
			}
			&.square--half-height {
				@include unsquare;
				position: relative;
				.grid-block--image {
					min-height: rem-calc(300);
				}
			}
		}
	}
}