.call-to-action {
    background-color: $white;
    padding: vw-calc(36) vw-calc(24) vw-calc(40) vw-calc(35);
    display: flex;
    flex-direction: column;
    justify-content: center;


    // height: 100%;
    // width: 100%;


    

    @at-root #{&}__headline {
        font-size: vw-calc(30);
        font-weight: 700;
        line-height: vw-calc(40);
        color: $color-shade-darker;
        padding-bottom: vw-calc(7);
    }
}

@include breakpoint(medium down) {
    .call-to-action {

        @at-root #{&}__headline {
            font-size: rem-calc(20);
            line-height: initial;
        }

        @at-root #{&}__link {
            // font-size: rem-calc(10);
        }
    }
}