.fullpage-section {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .two-columns {
        padding: 0;
        align-items: center;
        @include breakpoint(large down) {
            align-content: flex-start;
        }
        @include breakpoint(664px down) {
            flex-flow: row wrap;
        }

        @at-root #{&}__left {
            // min-width: 25rem;
            @include breakpoint(664px down) {
                flex: 0 1 100%;
                min-width: 0;
            }

        }

        @at-root #{&}__right {
            // min-width: 25rem;
            // padding: 2rem;
            margin-right: vw-calc(60);
            @include breakpoint(large down) {
                margin-right: vw-calc(30);
                margin-left: vw-calc(30);
            }
            @include breakpoint(664px down) {
                flex: 0 1 100%;
                min-width: 0;
            }
        }
    }


    @at-root #{&}__container {
        background: rgba(0,0,0,.6);
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        @include breakpoint(medium down) {
            padding: vw-calc(100) 0;
        }
        @include breakpoint(small down) {
            padding: rem-calc(50) 0 rem-calc(80) 0;
        }
    }
}

.fullpage-section {
    .teaser__headline:after {
        background-color: $color-secondary;
    }

    .fast-facts {
        @include breakpoint(small down) {
            flex-flow: row wrap;
            .fast-fact {
                margin-right: 0;
                flex: 0 1 100%;
            }
        }
    }

    .pullquote {
        @include breakpoint(small down) {
            min-width: 0;
            padding: rem-calc(20);
            margin-right: rem-calc(20);
            .pullquote__icon {
                top: -30px;
            }
            .pullquote__quote {
                font-size: rem-calc(20);
            }
        }
    }
}