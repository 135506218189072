.fast-facts {
	display: flex;
	flex-direction: row;

    .fast-fact {
        margin-top: rem-calc(10);
		margin-right: rem-calc(10);
		flex: 1;

		&:last-child {
			margin-right: 0;
		}
	}
	
	.stat-block {
        margin-top: rem-calc(10);
		margin-right: rem-calc(10);
		flex: 1;	
		
		&:last-child {
			margin-right: 0;
		}
	}
}