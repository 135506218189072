// Initial styles for .nice-video assume no javascript

.nice-video {
	position: relative;
	background-color: $black;

	.embedded-video,
	.embedded-widescreen-video {
		margin-bottom: 0;
	}

	button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		opacity: 0;
		pointer-events: none;
		transition: opacity 300ms;
	}
}

.nice-video__preview {
	background: {
		color: $black;
		image: linear-gradient(180deg,$color-shade-darkest 0%, $black 100%);
		position: 50% 50%;
		size: cover;
	}

	opacity: 0;
	position: absolute;
	z-index: 0;
	pointer-events: none;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transition: opacity 300ms;
	overflow: hidden;

	img {
		width: 100%;
		height: auto;
	}
}

.nice-video__transcript {
	padding: 0.2rem 1rem;
	text-align: center;

	a {
		color: $white;
	}
}

.nice-video--ready {
	.nice-video__preview {
		z-index: 10;
		opacity: 1;
		pointer-events: auto;
	}

	button {
		opacity: 1;
		z-index: 10;
		pointer-events: auto;
	}
}

.nice-video--playing {
	.nice-video__preview {
		opacity: 0;
		pointer-events: none;
	}
	button {
		opacity: 0;
		pointer-events: none;
	}
}
