.fast-fact {

	background: $color-primary;
	color: $white;
	padding: vw-calc(113) vw-calc(27) vw-calc(31) vw-calc(28);

	@at-root #{&}__stat {
		font-family: $font-primary;
		font-size: rem-calc(30);
		font-weight: 700;
		line-height: rem-calc(40);
		color: $white;
	}

	@at-root #{&}__description {
        font-size: rem-calc(16);
        font-weight: 700;
        line-height: rem-calc(26);
	}

}
