.duo-block {

    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100%;
    grid-template-areas:
      "small1 small2"
      "small3 small4";

    .grid .item:nth-child(1)  { grid-area: small1; }
    .grid .item:nth-child(2)  { grid-area: small2; }
    .grid .item:nth-child(3)  { grid-area: small3; }
    .grid .item:nth-child(4)  { grid-area: small4; }

    @at-root #{&}__item {

    }

}