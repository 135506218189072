
.breadcrumbs {
	ul {
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			span.icon {
				display: inline-block;
			}
			&:last-child {
				span.icon {
					display: none;
				}
			}
		}
	}
}