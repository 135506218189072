// .carousel-wrapper {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     overflow: hidden;
// }

.carousel__nav-count {
    position: absolute;
    top: 0;
    left: auto;
    right: auto;
    z-index: 999;
    display: flex;
    width: 100%;
    justify-content: center;
    button.slick-prev,
    button.slick-next {
        position: static;
        transform: none;
        width: 3.75rem;

        &.slick-disabled {
            visibility: visible;
        }
        &:focus {
            background: $color-secondary;
        }
        &:hover {
            background: lighten($color-secondary, 5%);
        }
        svg {
            color: #000;
        }
    }

    .carousel__slide-count {
        background: $color-secondary;
        padding: rem-calc(0 50 0 50);
        line-height: 3.75rem;
    }
}

.carousel {
    position: absolute;
    top:0;bottom:0;left:0;right:0;
    overflow: hidden;
    // @include background_image();

    &__item {
        background-position: center center;
        background-size: cover;
        height: 100%;
    }

    @at-root #{&}__slide-count {
        display: block;
        // position: absolute;
        // display: flex;
        // justify-content: center;
        // width: 100%;
        // pointer-events: none;
        // z-index: 99;

        span {
            // height: rem-calc(60);
            // width: rem-calc(60);
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // font-weight: 500;
            // font-style: italic;
            // color: $color-shade-darker;
        }
    }

    // button {
    //     color: $color-shade-darker;
    //     position: absolute;
    //     top: 2rem;
    //     color: $color-shade-darker;
    //     background: $color-secondary;

    //     &:focus {
    //         background: $color-secondary;
    //         color: $color-shade-darker;
    //     }

    //     &:hover {
    //         opacity: 0.9;
    //         background: $color-secondary;
    //         color: $color-shade-darker;
    //     }

    //     &:active {
    //         opacity: 0.8;
    //         color: $color-shade-darker;
    //     }
    // }

    // .slick-prev {
    //     right: 50%;
    //     left: initial;
    // }

    // .slick-next {
    //     left: 50%;

    //     svg {
    //         transform: scaleX(1);
    //     }
    // }

    .slick-disabled {
        display: block;
        visibility: visible;
        cursor: default;

        svg {
            visibility: hidden;
        }

        &:hover {
            background: initial;
        }
    }

    .slick-list {
        height: 100%;

        .slick-track {
            height: 100%;
        }
    }
}