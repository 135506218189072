.gallery {
    position: relative;
    height: 100%;
    z-index: 0;

    @at-root #{&}__items {
        display: flex;
        flex-wrap: wrap;
    }

    @at-root #{&}__button {
        position: absolute;
        top: 0;
        display: block;
        margin: 0 auto;
        z-index: 9;
        width: 100%;
        @include flex-center();
    }

    @at-root #{&}__item {
        flex: 1;
        min-width: 250px;
    }

    .gallery--view-all {
        display: none;
        z-index: 999;
    }
}

.gallery--view-all {
    flex-wrap: wrap;
    background-size: cover;
    background-position: center center;
    height: auto;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .gallery-header {

        @at-root #{&}__gallery-view {
            display: none;
        }

        @at-root #{&}__gallery-open {
            display: none;
        }

        @at-root #{&}__description {
            padding-left: vw-calc(43);
        }
    }

    .gallery__items {
        display: flex;
        background: rgba(0,0,0,.7);
        padding: 0 vw-calc(30);
    }
}