.teaser {
	@include flex-center();
	color: $color-shade-darker;
	text-align: center;
	flex-wrap: wrap;
	justify-content: center;
	padding: vw-calc(60);
	@include breakpoint(medium up) {
		min-width: 20rem;
	}

	@at-root #{&}__headline {
		font-family: $font-primary;
		font-size: rem-calc(40);
		font-weight: 700;
		line-height: rem-calc(50);
		color: $white;
		padding-bottom: rem-calc(25);
		margin-bottom: rem-calc(25);
		@include breakpoint(small down) {
			font-size: rem-calc(34);
		}

		&:after {
			content: "";
			display: block;
			height: rem-calc(2);
			width: rem-calc(100);
			background: $color-primary;
			margin: 0 auto;
			position: relative;
			bottom: rem-calc(-25);
		}
	}

	@at-root #{&}__body {
		max-width: rem-calc(530);
		color: $white;
		font-family: $font-primary;
		font-size: rem-calc(16);;
		line-height: rem-calc(26);
	}

	@at-root #{&}__editorial {
	}

	@at-root #{&}__links {
		margin-bottom: rem-calc(20);
	}

	@at-root #{&}__buttons {
		margin-top: rem-calc(60);
		@include breakpoint(small down) {
			margin-top: rem-calc(30);
			.button-group {
				flex-flow: column wrap;
				.button {
					margin-bottom: rem-calc(20);
					margin-right: 0;
				}
			}
		}
	}
}

.teaser__image {
	img {
		width: 100%;
		height: auto;
	}
}
