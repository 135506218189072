.main-header {

	height: 100%;
	// display: flex;
	// flex-direction: row;
	// flex-flow: row wrap;
	// display: block;
	display: flex;

	@at-root #{&}__sitenav-main {
		// display: flex;
		// flex-direction: column;
		// flex-flow: row wrap;
		display: block;
		width: rem-calc(180);
		background: $white;
		position: relative;
		z-index: 999;

		.main-header__sitenav-logo {
			text-align: center;
			a {
				display: inline-block;
				img {
					width: rem-calc(120);
					margin: 0 auto;
					display: block;
					padding: 2rem 0 3rem 0;
				}
			}
		}
	}

	@at-root #{&}__sitenav-mobile {
		width: 100%;
		display: none;
	}
}

.main-header__sitenav-util {
	@include ondark;
	background-color: $color-secondary;
	text-align: right;
	padding: {
		top: 0.5rem;
		bottom: 0.5rem;
	}
}

.main-header__brand {
	padding: 1rem 0;

	img {
		max-width: 100%;
		height: auto;
	}
}

// @include breakpoint(medium down) {
// 	.main-header__brand {

// 		img {
// 			max-width: rem-calc(300);
// 		}
// 	}
// }


@include breakpoint (medium down) {
    .main-header {
    	flex-flow: row wrap;



		@at-root #{&}__sitenav-mobile {
			display: block;
			// display: flex;
			// position: fixed;
    		background: $white;
    		width: 100%;
		}

		@at-root #{&}__sitenav-main {
// 			position: fixed;
// 			left: -15rem;
// 			z-index: 9;
// 			height: 100%;
// 			@include fade(left);

// 			.nav-main {
// 				height: 100%;
// 			}

// 			img {
// 				display: none;
// 			}

// 			&--open {
// 				left: 0;
// 				@include fade(left);

// 				.nav-main__menu-toggle:after {
// 					top: 0;
// 				}
// 			}
			.main-header__sitenav-logo {
				display: none;
			}

		}

// 		.nav-secondary {
// 			width: 100%;
// 			padding-top: 0;
// 			position: fixed;
//     		margin-top: 9rem;

// 			.link-group a {
// 				padding-left: rem-calc(73);
// 			}
// 		}
    }
}

@include breakpoint (medium down) {
	.main__nav {

		.main-header__sitenav-main,
		.nav-secondary {
			width: 100%;
			display: none;
		}

		// &.top-nav-only {
		// 	.main-header__sitenav-main {
		// 		width: 100%;
		// 	}
		// }
	}
}


.main-header__sitenav-main.short-viewport {
	overflow: auto;
	.sitenav-utility {
		position: static;
	}
}