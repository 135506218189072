@import "mixins/m_layout";
@import "mixins/m_typography";

/*------------------------------------*\
    $MIXINS
\*------------------------------------*/

// generate the image url by prefixing the layout image path
@function image-url($url) {
  @return url("#{$image-url-path}#{$url}");
}

// style input placeholders
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder           {@content}
  &:-ms-input-placeholder      {@content}
}

@mixin ornament-above {
	margin-top: $ornament-size / 2;
	padding-top: $ornament-size / 2;
	position: relative;

	.ornament {
		left: 50%;
		position: absolute;
		top: 0;
		transform: translateX(-50%) translateY(-50%);
	}
}

@mixin ornament-below {
	margin-bottom: $ornament-size / 2;
	padding-bottom: $ornament-size / 2;
	position: relative;

	.ornament {
		bottom: 0;
		left: 50%;
		position: absolute;
		transform: translateX(-50%) translateY(50%);
	}
}

// Stretch an element (usually a <figure>) and its child image to fill the
// element's parent container
//
// Options:
//		$beneath[true|false] default: false
//				If true, places the stretched element underneath its siblings. Useful
//				for image backgrounds.
//
//		$fill['width'|'height'|'both']
//			'width'		:	Image stretches to fill parent width, height scales proportionally
//			'height'	:	Image stretches to fill parent height, width scales proportionally
//			'both'		:	Image stretches to fill the shorter of width or height, cropping the
//									longer dimension
//
//		$pin['left'|'right'|'center' 'top'|'bottom'|'center'] default: 'top' 'center'
//			When the image must be cropped to fit, this determines which vertical and
//			horizontal side will remain unchanged. If 'center', then both sides are cropped
//			equally.


@mixin pseudo-cover ($beneath:false, $fill: 'both', $pin:'center' 'top') {
	width: 100%;
	max-width: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	content: '';
	display: block;

	margin: 0;

	@if $beneath {
		z-index: 0;
	}

	> img {
		position: absolute;

		max-width: none;
		max-height: none;

		@if ($fill == 'width') {
			width: 100%;
			height: auto;
			min-height: none;
		} @else if ($fill == 'height') {
			width: auto;
			min-width: none;
			height: 100%;
		} @else {
			width: auto;
			min-width: 100%;
			height: auto;
			min-height: 100%;
		}


		@if( nth($pin, 1) == 'center' ) {
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		} @else if( nth($pin, 1) == 'right' ) {
			right: 0;
			left: auto;
		} @else {
			right: auto;
			left: 0;
		}


		@if( nth($pin, 2) == 'center' ) {
			top: 50%;
			@if( nth($pin, 1) == 'center' ) {
				transform: translate(-50%, -50%);
			} @else {
				transform: translateY(-50%);
			}
		} @else {
			top: 0;
			bottom: 0;
		}
	}
}


// Use in conjunction with pseudo-cover. Applies a color to an image container.
// If mix-blend-mode is supported by the browser, a color layer will be applied
// above the image with the 'multiply' blend mode (blend mode can be changed
// using $mode argument). If the browser does not support mix-blend-mode
// (e.g. IE and Edge), the image will be made partially transparent
// on top of a colored layer. The effect is similar, but not identical.
// A modernizr function to check for mix-blend-mode is required for this
// mixin to function fully.
//
// Arguments:
//	$color[color hex value] default: primary theme color
//	$opacity[decimal value between 0 and 1] default: $opacity-overlay variable
//	$mode['multiply'|'overlay'|'screen'] default: 'multiply'

@mixin color-overlay($color:$color-primary, $opacity:$opacity-overlay, $mode:multiply) {
	&::after {
		@include pseudo-cover;
		background-color: rgba($color, $opacity);
	}

	.mix-blend-mode & {
		> img {
			opacity: $opacity * 0.5;
		}

		&::after {
			background-color: $color;
			mix-blend-mode: $mode;
		}
	}
}


// Simple variant of color overlay. Darkens the contents of an element
// by placing a semi-transparent black (or specified color) layer on
// top of it.
@mixin dark-bg-image($color:#000, $opacity:$opacity-overlay) {
	&::after {
		@include pseudo-cover;
		background-color: rgba($color, $opacity);
	}
}

// Similar to dark-bg-image, uses a gradient from black (or specified color) to
// transparent instead of a solid color layer.
@mixin gradient-overlay($color:#000, $direction:to right, $distance:50%, $opacity:0) {
	&::after {
		@include pseudo-cover;
		background-image: linear-gradient($direction, $color, $color $distance, rgba($color, $opacity));
	}
}


// Background image of a horizontal line, made up of dots.
// Arguments:
//		$size[pixel value] default: set in variables file
//			Size of a single dot in pixels
//
//		$spacing[float]	default: set in variables file
//			Size ratio of space between dots to dot size. Larger value means bigger space between dots.
//
//		$color[color hex value] default: set in variables file
//			Color of dots
@mixin line-dotted($size:$border-dot-size, $spacing:$border-dot-spacing, $color:$border-dot-color) {

	$radius: $size / 2;
	$space-width: $spacing * $size;

	$image-horizontal: "data:image/svg+xml;utf8,<svg width='#{$size + $space-width}' height='#{$size}' viewBox='0 0 #{$size + $space-width} #{$size}' xmlns='http://www.w3.org/2000/svg'><ellipse fill='#{$color}' cx='#{$radius}' cy='#{$radius}' rx='#{$radius}' ry='#{$radius}'/></svg>";

	background-image: url($image-horizontal);
	background-repeat: repeat-x;
	background-position: center center;
	background-size: #{$size + $space-width}px auto;

}

// Similar to line-dotted, this mixin puts a dotted border around an element on any or all sides.
// Takes same arguments as line-dotted, and also:
// 	$sides[up to 4 strings] default: 'top' 'right' 'bottom' 'left'
//		Border is applied only to the sides listed in this argument.

$dotted-border-sides-default: 'top' 'right' 'bottom' 'left';

@mixin border-dotted($size:$border-dot-size, $spacing:$border-dot-spacing, $color:$border-dot-color, $sides:$dotted-border-sides-default) {
	$radius: $size / 2;
	$space-width: $spacing * $size;

	$image-size: $size * 3 + $space-width * 2;

	$image-border: "data:image/svg+xml;utf8,<svg width='#{$image-size}' height='#{$image-size}' viewBox='0 0 #{$image-size} #{$image-size}' xmlns='http://www.w3.org/2000/svg'><g fill='#{$color}'><ellipse cx='#{$radius}' cy='#{$radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{$size + $space-width + $radius}' cy='#{$radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{($size * 2) + $space-width * 2 + $radius}' cy='#{$radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{$radius}' cy='#{$size + $space-width + $radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{($size * 2) + $space-width * 2 + $radius}' cy='#{$size + $space-width + $radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{$radius}' cy='#{($size * 2) + ($spacing * $size * 2) + $radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{$size + $space-width + $radius}' cy='#{($size * 2) + $space-width * 2 + $radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{($size * 2) + $space-width * 2 + $radius}' cy='#{($size * 2) + $space-width * 2 + $radius}' rx='#{$radius}' ry='#{$radius}'/></g></svg>";

	border-width: 0;
	border-style: solid;
	border-image: url($image-border) 33% round;

	@each $side in $sides {
		border-#{$side}-width: #{$radius + $space-width}px;
	}

}


@mixin fade($first-property, $second-property:"") {
	@if ($second-property == "") {
		transition: $fade-transition-time $first-property $fade-transition;
	} @else {
		transition: $fade-transition-time $first-property $fade-transition, $fade-transition-time $second-property $fade-transition;
	}
}

@mixin background_map($color: $color-primary) {
	position: relative;
	background: $color;
	z-index: 1;

	&:after {
		content: "";
		display: block;
		@include background_image('','../images/map-background.svg');
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0.08;
		z-index: -1;
	}
}

@mixin background_image($overlay: false, $image: 'https://images.unsplash.com/photo-1465447142348-e9952c393450?dpr=2&auto=format&fit=crop&w=1500&h=1124&q=80&cs=tinysrgb&crop=&bg=') {
	background-image: url($image);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	@if $overlay != false {
		z-index: 0;
		&:after {
			content: "";
			display: block;
			position: absolute;
			background: $overlay;
			top: 0;
			bottom: 0;
			width: 100%;
			left: 0;
			right: 0;
			z-index: -1;
		}
	}
}

@mixin background_overlay() {
	position: relative;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;bottom:0;left:0;right:0;
		background: rgba($color-primary, 0.3);
		z-index: 0;
	}
}

@mixin line_pointer($top: initial, $right: initial, $left: initial, $bottom: initial, $transform: initial, $width: rem-calc(80)) {
	content: "";
	display: block;
	height: rem-calc(2);
	background: $color-secondary;
	position: absolute;
	left: $left;
    right: $right;
    bottom: $bottom;
    top: $top;
    transform: $transform;
    width: $width;
}

@mixin flex-center() {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

// Calculates the vw value of some given pixels 
// in comparison to the pixel width of the comp. 
// (i.e. The comp pixel width is 1600px, and we  
// want to calculate the vw value of an area 40px 
// wide so it will scale at other widths. 
@function vw-calc($px) {  
 $comp-px-width: 1600; // width of the comp in px

 @return 100/($comp-px-width/$px) * 1vw;  

 // Todo? 
 // -- accept multiple values so `padding: vw-calc(20) vw-calc(25) vw-calc(30) vw-calc(40)` becomes `vw-calc(20, 25, 30, 40)`
}



// extend unsquare to turn a forced-square off,
// usually inside a responsive media query
@mixin unsquare($overlay: false) {
    position: static;
    width: 100%;
    height: auto;
    &:after {
        display: none;
    }
    .grid-block,
    .square__content {
    	@if $overlay == false {
        	position: static;
        }
    }
}

// fancy underlines for links!
@mixin fauxunderline($underline-color: $color-link) {
	background: transparent linear-gradient(to bottom, transparent 50%, $underline-color 50%);
	text-decoration: none;
	background-size: 100% 200%;
	background-position: 0 4%;
	transition: all 0.1s ease-in-out;
	&:hover {
		// background: transparent linear-gradient(to bottom, transparent 1.25em, $underline-color 1.25em);
		background-position: 0 12%;
	}
}

.fauxunderline {
	@include fauxunderline();
}