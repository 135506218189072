.pagination {
    span {
        display: inline-block;
        margin-right: rem-calc(10);
        &:last-child {
            margin-right: 0;
        }
        a {
            display: inline-block;
            @extend .button;
            margin-bottom: 0;
            min-height: 0;
            // padding: rem-calc(7 10);
            // background: #fff;
            // border: rem-calc(5) $color-primary solid;
            // text-decoration: none;
            // font-weight: bold;
            // color: $color-primary;
        }
        &.active {
            a {
                // border: 0;
                background: darken($color-primary, 10%);
                // color: #fff;
            }
        }
        &.disabled {
            padding: 0;
            a {
                background: $color-shade-light;
                cursor: not-allowed;
                border: 0;
                color: $color-text;
            }
        }
    }
}