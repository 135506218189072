.pullquote-double {
    display: flex;
    justify-content: space-between;
    @include breakpoint(xlarge down) {
        flex-flow: row wrap;
    }
    .pullquote,
    .pullquote-secondary {
        width: 48%;
        @include breakpoint(xlarge down) {
            width: 100%;
            padding-bottom: rem-calc(0);
            &:before {
                display: none;
            }
        }
        .pullquote-secondary__quote {
            font-size: rem-calc(20);
            line-height: rem-calc(30);
        }

        &:nth-child(2) {
            &:before {
                display: none;
            }
        }
    }
}