$two-column-qualifiers: 'left','right';

.two-columns {

	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	padding: 0 3.125vw;

	@at-root #{&}__left {
		flex: 1;
		min-width: 20rem;
	}

	@at-root #{&}__right {
		flex: 1;
		min-width: 20rem;
	}

	@include breakpoint(small only) {
		.two-columns__left, .two-columns__right {
			min-width: 15rem;
		}
	}
}
	// @include grid-row;

	// @each $i in $two-column-qualifiers {
	// 	@at-root #{&}__#{$i} {
	// 		@include grid-column;
	// 	}
	// }

	// @at-root #{&}__left {
	// 	border-bottom: 1px $color-shade-darker solid;
	// 	margin-bottom: 1rem;
	// }

// @include breakpoint(medium down) {
// 	.two-columns {
// 		flex-direction: column;
// 		@at-root #{&}__left {

// 		}

// 		@at-root #{&}__right {
			
// 		}
// 	}
// }