// $with-sidebar-qualifiers: 'main','sidebar';

.with-sidebar {
	// @include grid-row;
	padding: vw-calc(50);
	padding-top: 0;
	display: flex;
	// flex-wrap: wrap;
	flex-flow: row nowrap;
	justify-content: space-between;
	@include breakpoint (medium down) {
		flex-flow: row wrap;
	}


	// @each $i in $with-sidebar-qualifiers {
	// 	@at-root #{&}__#{$i} {
	// 		@include grid-column;
	// 	}
	// }

	@at-root #{&}__main {
		flex: 0 1 64%;
		padding-right: vw-calc(50);
		// padding-top: rem-calc(40);
		@include breakpoint (medium down) {
			flex: 0 1 100%;
		}
		// 	min-width: 20rem;
		// }
	}

	@at-root #{&}__sidebar {
		flex: 0 1 34%;
		@include breakpoint (medium down) {
			flex: 0 1 100%;
		}
		// 	min-width: 20rem;
		// }
		// padding-top: rem-calc(40);
	}


	@at-root #{&}--shift-down {
		margin-top: rem-calc(50);
	}

}

// @include breakpoint(medium) {
// 	.with-sidebar {
// 		@each $i in $with-sidebar-qualifiers {
// 			@at-root #{&}__#{$i} {
// 				@if $i == 'main' {
// 					@include grid-column(7);
// 				} @else {
// 					@include grid-column(5);
// 				}
// 				border-bottom-width: 0;
// 				margin-bottom: 0;
// 			}
// 		}
// 	}
// }
