$three-columns-qualifiers: 'left','center','right';

.three-columns {
	@include grid-row;

	@each $i in $three-columns-qualifiers {
		@at-root #{&}__#{$i} {
			@include grid-column;

			@if $i != 'right' {
				border-bottom: 1px $color-shade-darker solid;
				margin-bottom: 1rem;
			}
		}
	}

	@include breakpoint(medium) {
		@each $i in $three-columns-qualifiers {
			@at-root #{&}__#{$i} {
				@if $i == 'right' {
					@include grid-column(12);
					border-top: 1px $color-shade-darker solid;
					padding-top: 1rem;
					clear: left;
				} @else {
					@include grid-column(6);
				}

				border-bottom-width: 0;
				margin-bottom: 0;
			}
		}
	}

	@include breakpoint(large) {
		@each $i in $three-columns-qualifiers {
			@at-root #{&}__#{$i} {
				@include grid-column(4);
				@if $i == 'right' {
					border-top-width: 0;
					padding-top: 0;
					clear: none;
				}
			}
		}
	}

}
