// font embed from google fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:400,600,700');

// Setup font families
$font-primary : 'Open Sans', sans-serif;
$font-secondary : 'Open Sans Condensed', sans-serif;
$font-tertiary: Georgia, sans-serif;
$font-quaternary: 'Open Sans';

// Set font variables, if any
$font-accent: $font-tertiary;

// Font sizing
$base-font-size: 16px;
