.back-to-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem-calc(60) 0;
    text-decoration: none;
    width: 10rem;
    margin: 0 auto;

    @at-root #{&}__icon {

        svg {
            height: rem-calc(40);
            width: rem-calc(40);
            transform: rotate(90deg);
        }
    }

    @at-root #{&}__text {
        color: $color-quaternary;
        font-family: $font-secondary;
        font-size: rem-calc(18);
        font-weight: 700;
        line-height: rem-calc(20);
        text-transform: uppercase;
        text-decoration: none;
    }
}