// @import "buttons/basic";
// @import "buttons/secondary";
@import "buttons/back-to-top";
@import "buttons/radio-buttons";

button,
.button {
    background: $color-primary;
    color: $white;
    @include fontstyle('button');
    border-radius: 0;
    border-width: 0;
    padding: rem-calc(12 20);
    text-decoration: none;
    min-height: rem-calc(50);
    @include flex-center();
    display: inline-flex;
    &:hover,
    &:active,
    &:focus {
        background: darken($color-primary, 10%);
    }

    .arrow {
        display: inline-block;
        margin-right: 0.3em;
        font-family: arial, helvetica, sans-serif;
    }

    @at-root #{&}--secondary {
        background: $color-secondary;
        color: $color-shade-darker;
        &:hover,
        &:active,
        &:focus {
            background: $color-octonary;
            color: $color-shade-darker;
        }
    }

    @at-root #{&}--tertiary {
        background: $color-link-secondary;
        &:hover,
        &:active,
        &:focus {
            background: darken($color-link-secondary, 10%);
        }
    }

    @at-root #{&}--quaternary {
        background: $color-shade-darker;
        &:hover,
        &:active,
        &:focus {
            background: #000;
        }
    }

    @at-root #{&}--ghost {
        background: transparent;
        border: rem-calc(2) solid $color-secondary;
        // color: $color-secondary;
        font-family: $font-secondary;
        font-size: rem-calc(18);
        font-weight: 700;
        line-height: rem-calc(30);
        &:hover,
        &:active,
        &:focus {
            color: $color-shade-darker;
            background: $color-secondary;
            svg {
                color: $color-shade-darker;
                fill: $color-shade-darker;
                path {
                    fill: $color-shade-darker;
                }
            }
        }
    }
}