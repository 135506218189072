@import "includes/math";
@import "includes/colors";
@import "includes/fonts";
@import "includes/variables";
@import "includes/mixins";

/*
* Foundation
*/
@import "includes/foundation-settings";
@import "../bower_components/foundation-sites/scss/foundation";

$flex: false;

@if $flex {
	$global-flexbox: true !global;
}

@include foundation-global-styles;
@if not $flex {
	@include foundation-grid;
}
@else {
	@include foundation-flex-grid;
}

// @include foundation-grid; // mutually exclusive with foundation-flex-grid
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@if $flex {
	@include foundation-flex-classes;
}


/*
* Icons
* don't directly reference bower-supplied iconsets in your objects, instead put
* includes and mapping in the below icon settings file
*/
@import "includes/icons";
@import "includes/fonts";


/* Slick */
@import "../bower_components/slick-carousel/slick/slick.scss";
@import "../bower_components/slick-carousel/slick/slick-theme.scss";
@import "includes/slick-settings";

/*
 * Patterlab styles
 */

@import "atoms/global";
@import "atoms/icons";
@import "atoms/text";
@import "atoms/buttons";
@import "atoms/lists";
@import "atoms/media";
@import "atoms/images";

@import "molecules/text";
@import "molecules/layouts";
@import "molecules/page-layouts";
@import "molecules/content-layouts";
@import "molecules/navigation";
@import "molecules/interactive";
@import "molecules/data";
@import "molecules/editorial";
@import "molecules/alert";
@import "molecules/grid-blocks";

@import "organisms/clamp";
@import "organisms/panels";
@import "organisms/media";
@import "organisms/asides";
@import "organisms/teasers";
@import "organisms/collections";
@import "organisms/editorial";
@import "organisms/grids";


// Template, page styles could go below here
// but if you have any you might want to rethink
// your approach.
