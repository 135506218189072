.one-column {
	@include grid-row;

	@each $i in 'col' {
		@at-root #{&}__#{$i} {
			@include grid-column;
		}
	}

	&.simple {
		padding: 0 1.75vw;
		.one-column__col {
			padding: rem-calc(0 15);
			// padding: 0 1.75vw;
		}
	}

	&.standalone {
		padding: 0 vw-calc(50);
		margin: 0;
		max-width: none;
		.one-column__col {
			padding: 0;
			float: none;
		}
		&.constrain {
			.one-column__col {
				max-width: rem-calc(800);
			}
		}
	}

	&.shift-down {
		margin-top: rem-calc(50);
	}
}
