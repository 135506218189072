.callout {
    @include background_image(rgba($color-quaternary,.8));
    border: 0;
    padding: vw-calc(60) 0;
    margin: 1rem 0;

    @at-root #{&}--secondary {
        @include background_image(rgba($color-primary,.8));
    }

    @at-root #{&}__container {
        padding: vw-calc(68);
    }

    .link {
        color: $color-secondary;
    }

    .two-columns__left {
        padding-right: vw-calc(70);
    }

    .profile-teaser {
        margin-bottom: rem-calc(30);
    }

    &__social-row {
        display: flex;

        a {
            margin-right: rem-calc(25);
            color: $white;
        }
        
        svg {
                display: block;
                height: rem-calc(40);
                width: rem-calc(40);
        }
    }

    .with-sidebar {
        @extend .callout-dark;
        justify-content: space-between;
        .with-sidebar__main {
            flex: 0 1 40%;
            padding: 0;
            @include breakpoint (1325px down) {
                flex: 0 1 100%;
            }
        }
        .with-sidebar__sidebar {
            flex: 0 1 58%;
            @include breakpoint (1325px down) {
                flex: 0 1 100%;
            }
        }
    }

}

.callout-dark {
    color: #fff;
    a:not(.button) {
        color: $color-secondary;
        &:link,
        &:visited {
            color: $color-secondary;
        }
        &:hover,
        &:active {
            color: darken($color-secondary, 10%);
        }
    }
    .button,
    button {
        @extend .button--secondary;
    }
    h1, h2, h3, h4, h5, h6 {
        color: #fff;
    }
    .profile-teaser__tag {
        color: #fff;
    }
}