@import 'global/colors';
@import 'global/fonts';
@import 'global/layout';

img {
	max-width: 100%;
	height: auto;
	display: inline-block;
}

// h1, h2, h3, h4, h5, p, div {
// 	color: $color-shade-darker;
// }